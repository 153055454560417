//@flow
/* eslint-disable no-use-before-define */
import React from 'react';
import type {AccessRight, PacketDetails, PacketInfo} from "../api";
import {PacketDetailsValidation} from "../api";
import type {LangInfo} from "../lib/Language";
import msgs, {currentLang, formatString, LangContext, Languages, LanguagesOrdered} from "../lib/Language";
import {RouteChildrenProps} from "react-router";
import {Alert, Breadcrumb, Button, Col, Row} from "react-bootstrap";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {BForm, Form} from "../lib/Form";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";
import {RightCheckbox, RightForm, RightGroupData, sortRights, updateRight} from "../lib/Rights";
import {FormikProps} from "formik";

type State = {
    dataTime: number;
}

export default class PacketsScreen extends React.Component<RouteChildrenProps, State> {
    static url="/packets";

    edit: boolean;
    columns: Array<AllDataTableColumn<PacketInfo>>;

    constructor(props) {
        super(props);
        this.state = {
            dataTime: Date.now(),
        }
        this.edit=store.hasRight('PacketsWrite');
        this.columns=[
            ...LanguagesOrdered.map((lang: LangInfo) => ({
                id: lang.code,
                accessor: (row) => row.name[lang.code],
                Header: formatString(msgs.admin.labelPacketNameLang, lang.code),
                filter: 'text',
                className: "lang-part",
            })), {
                accessor: 'desc',
                Header: msgs.gui.labelDescription,
                filter: 'text',
                className: "xl",
            }, {
                id: 'actions',
                className: "delete",
                Header: '',
                Cell: ({ row }) => this.edit?<DeleteButton onClick={(e) => this.onDelete(row.original, e)}/>:null
            }
        ]
    }

    refresh() {
        this.setState({ dataTime: Date.now() });
    }

    onAddPacket(e: Event) {
        e.preventDefault();
        dialogOpen(this, PacketEditDialog.link('-'), () => this.refresh());
    }

    onSelect(row: PacketInfo) {
        dialogOpen(this, PacketEditDialog.link(row.id), () => this.refresh());
    }

    onDelete(row: PacketInfo, e: SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        store.userApi.deletePacket(row.id).then((res) =>{
            if(res===true) {
                this.refresh();
                store.flushPackets();
            }
            else {
                window.alert(msgs.admin.packetDeleteUsedMessage);
            }
        });
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuPackages}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuPackages}>
                {this.edit?<Button onClick={(e) => this.onAddPacket(e)} >{msgs.admin.actionAddPacket}</Button>:null}
            </PageHeader>
            <AllDataTable
                columns={this.columns}
                data={store.userApi.getPackets}
                onRowClick={(row) => this.onSelect(row)}
                dataTimestamp={this.state.dataTime}
                historyState="table"
            />
        </>;
    }
}
PacketsScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState = {
    data: PacketDetails|null;
}

export class PacketEditDialog extends React.Component<EditProps, EditState> {
    static url="/packet/:id";
    static link = (id: string) => "/packet/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight('PacketsWrite');

        this.state={
            data: null,
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        store.userApi.getPacket(id).then((p: PacketDetails) => {
            sortRights(p.access);
            this.setState({
                data: p
            })
        });
    }

    render() {
        if(!this.state.data) return null;
        const lang=currentLang.code;
        return <Form
            initialValues={this.state.data}
            validate={PacketDetailsValidation}
            readonly={!this.edit}
            onSubmit={async (values, helper) => {
                let res=await store.userApi.updatePacket(values);
                if(!Form.setError(helper, res)) {
                    store.flushPackets();
                    this.props.history.goBack();
                    events.emit(Events.PacketChange);
                }
            }}
        >{(formik: FormikProps<PacketDetails>) => <Dialog
            title={this.props.match.params.id === "-" ? msgs.admin.titleAddPacket : msgs.admin.titleEditPacket}
            onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
        >
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="name" controlId="name-pl">
                        <Form.Label>{formatString(msgs.admin.labelPacketNameLang, Languages.pl.code)}</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.pl.code} maxLength={256}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="name" controlId="name-en">
                        <Form.Label>{formatString(msgs.admin.labelPacketNameLang, Languages.en.code)}</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.en.code} maxLength={256}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.RowGroup name="desc">
                <Form.Label>{msgs.admin.labelDescriptionAdmin}</Form.Label>
                <Form.TextArea/>
            </Form.RowGroup>
            <Alert variant="info" className="white mb-5">{msgs.admin.hintPacketInfo}</Alert>
            <RightForm label={msgs.gui.labelService} info={msgs.gui.labelRightsLevel}>
                {formik.values.access.map((i: AccessRight, index) => <RightGroupData
                    key={index} label={i.name[lang]} cols={false}
                >
                    <Row>
                        <Col md={6}>
                            <RightCheckbox
                                id={"rb_"+index+"_access"}
                                label={msgs.gui.accessAllow}
                                value={i.write}
                                onChange={(selected) => formik.setFieldValue("access",
                                    updateRight(formik.values.access, i, { write: selected })
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <RightCheckbox
                                id={"rb_"+index+"_info"}
                                readonly={i.write}
                                label={msgs.admin.labelShowServiceMissingMessage}
                                value={i.read}
                                onChange={(selected) => formik.setFieldValue("access",
                                    updateRight(formik.values.access, i, { read: selected })
                                )}
                            />
                        </Col>
                    </Row>
                    {i.type==="Monitoring"?<>
                        <BForm.Group as={Row} className="mt-2">
                            <BForm.Label column sm={8}>{msgs.admin.labelMonitoringLimit}</BForm.Label>
                            <Col sm={4}>
                                <BForm.Control
                                    disabled={!i.write}
                                    className="max-width-xs"
                                    type="number"
                                    min={1} step={1}
                                    value={i.limit?String(i.limit):"1"}
                                    onChange={(e) => formik.setFieldValue("access",
                                        updateRight(formik.values.access, i, { limit: parseInt(e.target.value) })
                                    )}
                                />
                            </Col>
                        </BForm.Group><BForm.Group as={Row} className="mt-2">
                        <BForm.Label column sm={8}>{msgs.admin.labelMonitoringIMSIG}</BForm.Label>
                        <Col sm={4}>
                            <BForm.Check
                                custom type="checkbox"
                                disabled={!i.write}
                                className="max-width-xs"
                                checked={i.settings['imsig'] || false}
                                onChange={(e) => formik.setFieldValue("access",
                                    updateRight(formik.values.access, i, { settings: { ...i.settings, "imsig": e.target.checked } })
                                )}
                            />
                        </Col>
                    </BForm.Group></>:null}
                </RightGroupData>)}
                <RightGroupData label={msgs.admin.labelMonitoringMenu}>
                    <RightCheckbox
                        id={"rb_menu_monitoring"}
                        label={msgs.admin.labelDisplayInMenu}
                        value={formik.values.viewMenuMonitoring}
                        onChange={(selected) => formik.setFieldValue("viewMenuMonitoring",selected)}
                    />
                </RightGroupData>
                <RightGroupData label={msgs.admin.labelKnowledgeMenu}>
                    <RightCheckbox
                        id={"rb_menu_knowledge"}
                        label={msgs.admin.labelDisplayInMenu}
                        value={formik.values.viewMenuKnowledge}
                        onChange={(selected) => formik.setFieldValue("viewMenuKnowledge",selected)}
                    />
                </RightGroupData>
            </RightForm>
        </Dialog>
        }</Form>;
    }

}
PacketEditDialog.contextType=LangContext;
