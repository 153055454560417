//@flow
/* eslint-disable no-use-before-define */
import React from 'react';
import {RouteChildrenProps} from "react-router";
import type {RejectionReasonInfo} from "../api";
import {RejectionReasonInfoValidation} from "../api";
import {Breadcrumb, Button} from "react-bootstrap";
import type {LangInfo} from "../lib/Language";
import msgs, {formatString, LangContext, Languages, LanguagesOrdered} from "../lib/Language";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {Form} from "../lib/Form";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";

type State = {
    dataTime: number;
}

export default class RejectionsScreen extends React.Component<RouteChildrenProps, State> {
    static url="/rejections";

    columns: Array<AllDataTableColumn<RejectionReasonInfo>>;
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("RejectionReasonWrite");
        this.state = {
            dataTime: Date.now(),
        }
        this.columns=[
            ...LanguagesOrdered.map((lang: LangInfo)=>({
                id: lang.code,
                Header: formatString(msgs.admin.labelMessageLang, lang.code),
                accessor: (r: RejectionReasonInfo) => r.message[lang.code],
                filter: 'text',
                className: "lang-part",
            })),
            {
                id: 'actions',
                Header: '',
                className: "delete",
                Cell: ({ row }) => this.edit?<DeleteButton onClick={(e) => this.onDelete(row.original, e)}/>:null
            }
        ]
    }

    refresh() {
        this.setState({ dataTime: Date.now() });
    }

    onAdd() {
        dialogOpen(this, EditRejectionDialog.link('-'), () => this.refresh());
    }

    onSelect(row: RejectionReasonInfo) {
        dialogOpen(this, EditRejectionDialog.link(row.id), () => this.refresh());
    }

    onDelete(row: RejectionReasonInfo, e: Event) {
        e.preventDefault();
        e.stopPropagation();
        store.userApi.deleteRejectionReason(row.id).then(() => {
            this.refresh();
            store.flushRejectionReasons();
            events.emit(Events.RejectionReasonChange);
        });
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuRejectionReasons}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuRejectionReasons}>
                {this.edit?<Button onClick={(e) => this.onAdd(e)}>{msgs.admin.actionAddRejectionReason}</Button>:null}
            </PageHeader>
            <AllDataTable
                columns={this.columns}
                data={store.userApi.getAllRejectionReasons}
                onRowClick={(row: RejectionReasonInfo) => this.onSelect(row)}
                dataTimestamp={this.state.dataTime}
                historyState="table"
            />
        </>;
    }
}
RejectionsScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState = {
    value: RejectionReasonInfo;
}

export class EditRejectionDialog extends React.Component<EditProps, EditState> {
    static url="/rejection/:id";
    static link=(id: string) => "/rejection/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("RejectionReasonWrite");
        this.state={
            value: null,
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        store.userApi.getRejectionReason(id).then((r: RejectionReasonInfo) => {
            this.setState({ value: r })
        })
    }

    render() {
        if(!this.state.value) return null;
        return <Form
            initialValues={this.state.value}
            validate={RejectionReasonInfoValidation}
            readonly={!this.edit}
            onSubmit={async (values, helpers) => {
                let res=await store.userApi.updateRejectionReason(values);
                if(!Form.setError(helpers, res)) {
                    this.props.history.goBack();
                    store.flushRejectionReasons();
                    events.emit(Events.RejectionReasonChange);
                }
            }}
        >{(formik) => <Dialog
            title={this.props.match.params.id==='-'?msgs.admin.titleAddRejectionReason:msgs.admin.titleEditRejectionReason}
            onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
        >
            <Form.Group name="message">
                <Form.Label>{formatString(msgs.admin.labelMessageLang, Languages.pl.code)}</Form.Label>
                <Form.MultiLanguageTextInput lang={Languages.pl.code}/>
            </Form.Group>
            <Form.Group name="message">
                <Form.Label>{formatString(msgs.admin.labelMessageLang, Languages.en.code)}</Form.Label>
                <Form.MultiLanguageTextInput lang={Languages.en.code}/>
            </Form.Group>
        </Dialog>}</Form>;
    }
}
EditRejectionDialog.contextType=LangContext;