//@flow
// Plik z komponentami, które występują tylko w aplikacji administracyjnej
import React, {Ref, useContext} from 'react';
import {Form as BForm, InputGroup, Nav} from "react-bootstrap";
import type {LangInfo, LangString, Language} from "./lib/Language";
import msgs, {formatString, getMessage, LanguagesOrdered} from "./lib/Language";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FieldHelperProps, FieldInputProps, useField} from "formik";
import type {FormContextType, GroupContextType} from "./lib/Form";
import {FormContext, GroupContext, MultiLanguageContext} from "./lib/Form";
import cn from 'classnames';
import {countSmsMessages, isSimpleSms} from "./lib/SMSUtils";

export function emptyLangString(): LangString {
    return {
        pl: '', en: '',
    }
}

type LangPanelProps = {
    id?: string;
    value: Language;
    onChange: (lang: Language) => void;
}

/**
 * Komponent do edycji kluczy językowych
 */
export const LanguagePanel = ({ id, value, onChange, ...props}: LangPanelProps) => (
        <Nav
            id={id}
            variant="tabs"
            defaultActiveKey={value}
            onSelect={(key) => { if(onChange) onChange(key)}}
            {...props}
        >
            {LanguagesOrdered.map((l: LangInfo) => <Nav.Item key={l.code}>
                <Nav.Link eventKey={l.code}>{formatString(msgs.admin.tabLangVersion, l.code)}</Nav.Link>
            </Nav.Item>)}
        </Nav>
);

type RichEditorProps = {
    value: string;
    onChange: (content: string) => void;
    mode?: "email"|"raw";
    readonly?: boolean;
    className?: string;
}

// let Inline=Quill.import("blots/inline");
// class SpanBlot extends Inline { }
// SpanBlot.blotName = "span";
// SpanBlot.tagName = "span";
// Quill.register("formats/span", SpanBlot);

let Inline=Quill.import("blots/inline");
class SpanBlot extends Inline {}
SpanBlot.blotName = "em";
SpanBlot.tagName = "EM";
Quill.register(SpanBlot, true);

let LinkBlot=Quill.import("formats/link");
class EmailButtonBlot extends LinkBlot {}
EmailButtonBlot.blotName = "btn";
EmailButtonBlot.className="email-btn";
Quill.register(EmailButtonBlot, true);


let Icons=Quill.import("ui/icons");
Icons["em"]='em';
Icons["btn"]='btn';

/**
 * Komponent dla edytora HTML-a. Działa w oparciu CKEditor.
 */
export class RichEditor extends React.Component<RichEditorProps, void> {
    editor: Ref<ReactQuill>;
    textArea: Ref<HTMLTextAreaElement>;
    modules;
    formats;
    value: string;

    constructor(props) {
        super(props);
        this.editor=React.createRef();
        this.textArea=React.createRef();
        this.state={

        }
        if(props.mode==="email") {
            // this.formats=[ "bold", "italic", "underline", "strike", "span", "h1", "h2", "h3" ];
            this.modules= {
                toolbar: [
                    [ { "header": [ 1, 2, 3, true ] }],
                    [ 'blockquote' ],
                    [ 'bold', 'italic', 'underline', 'strike', 'em' ],
                    [ 'link', 'btn' ]
                ]
            }
        } else {
            this.formats=[ 'header', 'bold', 'italic', 'underline', 'blockquote', 'list',
                'bullet', 'ident', 'link', 'align', 'color' ];
            this.modules= {
                toolbar: [
                    [
                        { "header": [ 1, 2, 3, false ] },
                        {'color': [ '#034280', '#FF8900', '#32C924', '#E31010', '#A5C2DE', '#000000' ] }
                    ],
                    [ 'bold', 'italic', 'underline', 'link'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' } ],
                    [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right'} ],
                    ['clean']
                ],
            }
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log("Got Quill error:", error, errorInfo);
    }

    render() {
        const { value, onChange, mode, readonly, ...props } = this.props;

        if(mode==="raw") {
            return <BForm.Control
                ref={this.textArea}
                as="textarea"
                value={value}
                disabled={readonly}
                onChange={(e) => onChange(e.target.value)}
                rows={8}
                {...props}
            />
        }

        if(mode==="email") {
            return <ReactQuill
                ref={this.editor}
                value={value}
                readOnly={readonly}
                onChange={onChange}
                modules={this.modules}
                formats={this.formats}
                className={this.props.className}
                {...props}
            />;
        } else {
            return <ReactQuill
                ref={this.editor}
                readOnly={readonly}
                value={value}
                modules={this.modules}
                formats={this.formats}
                onChange={onChange}
                className={cn(this.props.className)}
                {...props}
            />;
        }
    }

    insertText(text: string) {
        if(this.textArea.current) {
            const ta:HTMLTextAreaElement=this.textArea.current;
            if(ta.selectionStart || ta.selectionStart===0) {
                const start=ta.selectionStart;
                const end=ta.selectionEnd;
                const val=this.props.value.substring(0, start)+text+this.props.value.substring(end);
                this.props.onChange(val);
            } else {
                this.props.onChange(this.props.value+text);
            }
        }
        if(!this.editor.current) return;
        const editor: Quill= this.editor.current.getEditor();
        const pos=editor.getSelection(true);
        editor.insertText(pos.index, text);
    }
}

/** Wersja komponentu do umieszczenia w kontekście formularza */
export const FormRichEditor = ({ props }) => {
    const fc=useContext<FormContextType>(FormContext);
    const context=useContext<GroupContextType>(GroupContext);
    const [ field, meta, helpers ] = useField(context.name);
    const error=meta.error && meta.touched;
    return <>
        <RichEditor
            value={field.value}
            onChange={v => helpers.setValue(v)}
            readonly={fc.readonly}
            {...props}
        />
        {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
    </>;
}

/** Wersja komponentu do umieszczenia w kontekście formularza */
export const FormMultiLanguageRichEditor = ({ lang }) => {
    const fc=useContext<FormContextType>(FormContext);
    const context=useContext<GroupContextType>(GroupContext);
    const langContext=useContext<Language>(MultiLanguageContext);
    if(lang===null || lang===undefined) lang=langContext;
    const [ , meta, ] = useField(context.name);
    const [ field: FieldInputProps, , helpers: FieldHelperProps ] = useField(context.name+'.'+lang);
    const error=meta.error && meta.touched;
    const value=field.value || "";
    return <>
        <RichEditor
            value={value}
            onChange={v => helpers.setValue(v) }
            readonly={fc.readonly}
        />
        {error?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
    </>;
}

/**
 * Komponent dla treści SMS.
 * Komponent to zwykłe pole tekstowe z licznikiem.
 */
export const SMSInput = ({ value, onChange, controlRef, ...props }: {
    value: string; onChange: (value: string) => void;
}) => {
    if(typeof(value)!=='string') value='';
    let chars=value.length;
    let simple=isSimpleSms(value);
    let smsSize=simple?160:70;
    let msgs=countSmsMessages(value);
    if(msgs===0) msgs=1;

    return <>
        <InputGroup>
            <BForm.Control as="textarea" ref={controlRef} rows={6} value={value} onChange={e => onChange(e.target.value)} {...props}/>
            <InputGroup.Append>
                <InputGroup.Text className={(msgs>1 || !simple)?"text-danger":null}>{`${chars}/${smsSize} (${msgs})`}</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>

    </>
}