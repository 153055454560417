//@flow
import React from 'react';
import {Dialog} from "../lib/Components";
import msgs, {LangContext} from "../lib/Language";
import type {AdminSelfForm, AdminSelfInfo} from "../api";
import {AdminSelfFormValidation} from "../api";
import {Form} from "../lib/Form";
import {Col} from "react-bootstrap";
import {RouteComponentProps} from "react-router";
import {store} from "../application";

type State = {
    value: AdminSelfForm|null;
}

export default class UserSettingsDialog extends React.Component<RouteComponentProps, State> {
    static url="/user_settings";

    constructor(props) {
        super(props);
        this.state={
            value: null,
        }
    }

    componentDidMount(): void {
        store.publicApi.getSelf().then((u: AdminSelfInfo) => {
            this.setState({ value: {
                    email: u.email,
                    name: u.name,
                    passwordVisible: false,
                    newPassword: '',
                    repeatPassword: '',
                }
            });
        });
    }

    render() {
        if(this.state.value===null) return null;
        return <Form
            initialValues={this.state.value}
            validate={AdminSelfFormValidation}
            customValidation={(values: AdminSelfForm) => {
                if(!values.passwordVisible) {
                    if(values.newPassword!==values.repeatPassword) {
                        return { 'repeatPassword': msgs.gui.validatePasswordDifferent }
                    }
                }
            }}
            onSubmit={async (values, helper) => {
                let res=await store.userApi.updateSelf(values);
                if(!Form.setError(helper, res)) {
                    this.props.history.goBack();
                }
            }}
        >{(formik) => <Dialog
            title={msgs.gui.titleMyAccount}
            onAccept={(e) => { formik.handleSubmit(e); return false; }}
        >
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="name">
                        <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                        <Form.Text maxLength={200}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="email">
                        <Form.Label>{msgs.gui.labelEmailLogin}</Form.Label>
                        <Form.Email readOnly />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="newPassword">
                        <Form.Label>{msgs.gui.labelNewPassword}</Form.Label>
                        <Form.Password
                            checkStrength={true}
                            onVisibilityChange={(visible) => {
                                formik.setFieldValue("passwordVisible", visible);
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="repeatPassword">
                        <Form.Label>{msgs.gui.labelRepeatPassword}</Form.Label>
                        <Form.Password
                            disabled={formik.values.passwordVisible}
                            toggleVisibility={false}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>

        </Dialog>}</Form>;
    }
}
UserSettingsDialog.contextType=LangContext;