//@flow
import React from 'react';
import {Modal} from "react-bootstrap";
import {formatString, useMsgs} from "../lib/Language";
import {useLocation} from "react-router";
import {AuthPage} from "../lib/Components";

const Error404=() => {
    const msgs=useMsgs();
    const location=useLocation();
    const msg=formatString(msgs.gui.error404, location.pathname);
    return <AuthPage title={msg}>
        <Modal.Body/>
    </AuthPage>
}

export default Error404;