//@flow
import React, {useCallback} from 'react';
import msgs, {getLangValue, LangContext, langLink} from "../lib/Language";
import {Breadcrumb, Button} from "react-bootstrap";
import {store} from "../application";
import type {DataTableColumn} from "../lib/DataTable";
import DataTable, {AllDataTable} from "../lib/DataTable";
import type {FieldFilter, OrderInfo, OrganizationInfo, UserOrganizationInfo} from "../api";
import {formatDateTime} from "../lib/DateTime";
import {BreadcrumbItem, dialogOpen, PageHeader} from "../lib/Components";
import {AcceptVindicationDialog, RejectDialog} from "./VindicationsScreen";
import {OrganizationsTable, ViewOrganizationScreen} from "./OrganizationsScreen";
import {useHistory} from "react-router-dom";

type State = {
    usersTimestamp: number;
    ordersTimestamp: number;
}

const notesFilter: Array<FieldFilter>=[{ field: "hasNotes", value: true } ];

/**
 * Tabela organizacji, które mają alert, czyli notatki.
 */
const AlertOrganizations= () => {
    const history=useHistory();
    const handleRowClick=useCallback((o: OrganizationInfo) => {
        history.push(langLink(ViewOrganizationScreen.link(o.id)));
    }, [ history ])

    return <>
        <h3 className="mt-5 mb-4">{msgs.admin.titleOrganizationSyncError}</h3>
        <OrganizationsTable
            historyState="alert-orgs"
            data={store.userApi.getOrganizations}
            onRowClick={handleRowClick}
            customFilters={notesFilter}
            initialSortBy="-added"
            showPromoCodes={false}
            showOrigin={false}
            actions={false}
        />
    </>
}

export default class HomeScreen extends React.Component<void, State> {
    static url="/";

    viewUser: boolean;
    editUser: boolean;
    viewOrder: boolean;
    editOrder: boolean;
    viewVindication: boolean;
    viewOrganization: boolean;
    editVindication: boolean;

    usersColumns: Array<DataTableColumn<UserOrganizationInfo>>;
    ordersColumns: Array<DataTableColumn<OrderInfo>>;

    constructor(props) {
        super(props);
        this.state = {
            usersTimestamp: Date.now(),
            ordersTimestamp: Date.now(),
        }

        this.usersFilter=[{ field: "status", value: "New" } ];

        this.viewUser=store.hasRight("UsersRead");
        this.editUser=store.hasRight("UsersWrite");

        this.viewVindication=store.hasRight("VindicationsRead");
        this.editVindication=store.hasRight("VindicationsWrite");

        this.viewOrder=store.hasRight("OrdersRead");
        this.editOrder=store.hasRight("OrdersWrite");

        this.viewOrganization=store.hasRight("OrganizationsRead");

        this.usersColumns= [
            {
                accessor: "userId",
                Header: msgs.gui.labelID,
                type: "number",
                className: "id"
            }, {
                accessor: "name",
                Header: <>{msgs.gui.labelFullName}<br/>{msgs.gui.labelCompany}</>,
                Cell: ({ row }) => <>
                    <h1>{row.original.name}</h1>
                    <span className="d-block">{row.original.orgTax}</span>
                    {row.original.org}
                </>,
                type: "string",
                className: "mw-lg",
            }, {
                accessor: "email",
                Header: msgs.gui.labelEmailLogin,
                type: "string",
                className: "email"
            }, {
                accessor: "lastLogged",
                Header: msgs.gui.labelLastLogin,
                Cell: ({value}) => formatDateTime(value),
                type: "date",
                className: "datetime"
            }];
        if(this.editUser) this.usersColumns.push({
            id: "actions",
            Header: msgs.gui.labelActions,
            Cell: ({row}) => <>
                <Button
                    variant="danger"
                    onClick={e => this.handleUserReject(e, row.original)}
                >{msgs.gui.actionReject}</Button> <Button
                    variant="success"
                    onClick={e => this.handleUserAccept(e, row.original)}
                >{msgs.gui.actionAccept}</Button>
            </>,
            className: "actions2",
        });

        this.ordersColumns=[
            {
                accessor: 'id',
                className: 'id',
                Header: msgs.gui.labelID,
                type: "number",
            }, {
                accessor: 'type',
                className: 'type',
                Header: msgs.gui.labelOrderType,
                Cell: ({value}) => getLangValue(value),
                type: "langString",
            }, {
                accessor: "clientName",
                className: "half",
                Header: msgs.gui.labelCustomer,
                Cell: ({ row }) => <>
                    <h1>{row.original.clientName}</h1>
                    <span className="d-block">{row.original.clientTax}</span>
                    <span>{row.original.clientFirstName} {row.original.clientLastName}</span>
                </>,
                type: "string",
            }, {
                accessor: "tax",
                className: 'half',
                Header: msgs.gui.labelOrderTarget,
                Cell: ({ row }) => <>
                    <h1>{row.original.name}</h1>
                    <span>{row.original.tax}</span>
                </>,
                type: "string",
            }, {
                accessor: 'added',
                className: 'datetime',
                Header: msgs.gui.labelOrderDate,
                Cell: ({ value }) => formatDateTime(value),
                type: "date",
            },
        ];
        if(this.editOrder) this.ordersColumns.push({
            id: "actions",
            Header: msgs.gui.labelActions,
            Cell: ({row}) => <>
                <Button
                    variant="danger"
                    onClick={e => this.handleOrderReject(e, row.original)}
                >{msgs.gui.actionReject}</Button> <Button
                    disabled={!row.clientEcId}
                    variant="success"
                    onClick={e => this.handleOrderAccept(e, row.original)}
                >{msgs.gui.actionAccept}</Button>
            </>,
            className: "actions2",
        })
    }

    handleUserReject(e: SyntheticEvent, u: UserOrganizationInfo) {
        store.userApi.changeUserInOrganizationStatus(u.id, "Rejected").then(() => {
            this.setState({ usersTimestamp: Date.now() })
        });
    }

    handleUserAccept(e: SyntheticEvent, u: UserOrganizationInfo) {
        store.userApi.changeUserInOrganizationStatus(u.id, "Active").then(() => {
            this.setState({ usersTimestamp: Date.now() })
        });
    }

    handleOrderReject(e: SyntheticEvent, o: OrderInfo) {
        if(o.internal==="Vindication" || o.internal==="GlobalVindication") {
            dialogOpen(this, RejectDialog.link(o.id), () => this.setState({ ordersTimestamp: Date.now() }));
        } else {
            store.userApi.orderReject(o.id).then(() => this.setState({ ordersTimestamp: Date.now() }))
        }
    }

    handleOrderAccept(e: SyntheticEvent, o: OrderInfo) {
        if(o.internal==="Vindication" || o.internal==="GlobalVindication") {
            dialogOpen(this, AcceptVindicationDialog.link(o.id), () => this.setState({ ordersTimestamp: Date.now() }));
        } else {
            store.userApi.orderAccept(o.id).then(() => this.setState({ ordersTimestamp: Date.now() }))
        }
    }

    rowClassName=(item: OrderInfo) => {
        if(!item.clientEcId) return "disconnected";
        return null;
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem active>{msgs.admin.menuDesktop}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuDesktop}/>
            {(this.viewOrder || this.viewVindication)?<>
                <h3 className="mt-5 mb-4">{msgs.gui.titleOrdersToAccept}</h3>
                <AllDataTable
                    columns={this.ordersColumns}
                    data={store.userApi.getUnassignedOrders}
                    dataTimestamp={this.state.ordersTimestamp}
                    rowClassName={this.rowClassName}
                    initialSortBy={"-added"}
                />
            </>:null}

            {this.viewUser?<>
                <h3 className="mt-5 mb-4">{msgs.gui.titleUsersToAccept}</h3>
                <DataTable
                    columns={this.usersColumns}
                    data={store.userApi.queryUsersInOrganizations}
                    dataTimestamp={this.state.usersTimestamp}
                    customFilters={this.usersFilter}
                />
            </>:null}
            {this.viewOrganization && <AlertOrganizations/>}
        </>;
    }
}
HomeScreen.contextType=LangContext;