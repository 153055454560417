//@flow
/* eslint-disable no-use-before-define */
import React from 'react';
import {RouteChildrenProps} from "react-router";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import type {LangInfo} from "../lib/Language";
import msgs, {currentLang, formatString, LangContext, Languages, LanguagesOrdered} from "../lib/Language";
import {FormMultiLanguageRichEditor} from "../AdminComponents";
import {Breadcrumb, Button, Col, Tab, Tabs} from "react-bootstrap";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";
import {Form} from "../lib/Form";
import type {InternalServices, OrderTypeDetails, OrderTypeInfo} from "../api";
import {OrderTypeDetailsValidation} from "../api";
// import {ReactFormBuilder} from 'react-form-builder2';
// import "react-form-builder2/dist/app.css"

type State = {
    dataTime: number;
}

export default class ServicesScreen extends React.Component<RouteChildrenProps, State> {
    static url="/services/";

    columns: Array<AllDataTableColumn<OrderTypeInfo>>;
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("ServicesWrite");
        this.state = {
            dataTime: Date.now(),
        }
        this.columns=[
            ...LanguagesOrdered.map((lang: LangInfo) => ({
                id: lang.code,
                Header: formatString(msgs.admin.labelServiceNameLang, lang.code),
                accessor: (row) => row.name[lang.code],
                className: "lang-part",
                filter: "text",
            })),
            {
                id: 'actions',
                Header: '',
                filterable: false,
                className: "delete",
                Cell: ({row}) => (this.edit && !row.original.type)?<DeleteButton onClick={(e) => this.onDelete(row.original, e)} confirm />:null
            }
        ]
    }

    refresh() {
        this.setState({ dataTime: Date.now() });
    }


    onAddService(e: Event) {
        e.preventDefault();
        dialogOpen(this, EditServiceDialog.link('-'), () => this.refresh());
    }

    onSelect(row: OrderTypeInfo) {
        dialogOpen(this, EditServiceDialog.link(row.id), () => this.refresh());
    }

    onDelete(row: OrderTypeInfo, e: SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        store.userApi.deleteOrderType(row.id).then(() => {
            this.refresh();
            store.flushOrderTypes();
            events.emit(Events.ServiceChange);
        });
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuServicesTypes}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuServicesTypes}>
                {this.edit?<Button onClick={(e) => this.onAddService(e)}>{msgs.admin.actionAddService}</Button>:null}
            </PageHeader>
            <AllDataTable
                columns={this.columns}
                data={store.userApi.getOrderTypes}
                onRowClick={(row: OrderTypeInfo) => this.onSelect(row)}
                dataTimestamp={this.state.dataTime}
                initialSortBy={currentLang.code}
            />
        </>;
    }
}
ServicesScreen.contextType=LangContext;

const hasEmail=(type: InternalServices) => {
    return !type || type==="DebtExchange";
}

export class EditServiceDialog extends React.Component<
    RouteChildrenProps<{ id: string }>,
    { data: OrderTypeDetails|null }>
{
    static url='/service/:id';
    static link=(id: string) => "/service/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("ServicesWrite");
        this.state={
            data: null
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        store.userApi.getOrderType(id).then((type: OrderTypeDetails) => {
            this.setState({ data: type })
        });
    }

    render() {
        if(!this.state.data) return null;
        const isNew=this.props.match.params.id==='-';
        return <Form
            initialValues={this.state.data}
            validate={OrderTypeDetailsValidation}
            readonly={!this.edit}
            onSubmit={async (values, helpers) => {
                let res=await store.userApi.updateOrderType(values);
                if(!Form.setError(helpers, res)) {
                    this.props.history.goBack();
                    store.flushOrderTypes();
                    events.emit(Events.ServiceChange);
                }
            }}
        >{(formik) => <Dialog
            size="xl"
            title={isNew?msgs.admin.titleAddService:msgs.admin.titleEditService}
            onAccept={this.edit?() => { formik.handleSubmit(); return false; }:null}
        >
                <Tabs id="services-tabs">
                    <Tab eventKey="main" title={msgs.admin.labelServiceMain}>
                        <Form.Group name="ident" style={{ display: this.state.data.type?"none":"block" }}>
                            <Form.Label>{msgs.admin.labelServiceIdent}</Form.Label>
                            <Form.Text disabled={!isNew}/>
                        </Form.Group>
                        {formik.values.type?null:
                        <Form.Row>
                            <Form.ColGroup name="showPl" md={6}>
                                <Form.Check>{msgs.admin.labelShowPl}</Form.Check>
                            </Form.ColGroup>
                            <Form.ColGroup name="showEn" md={6}>
                                <Form.Check>{msgs.admin.labelShowEn}</Form.Check>
                            </Form.ColGroup>
                        </Form.Row>}
                        <Form.Group name="name" controlId="name_pl">
                            <Form.Label>{formatString(msgs.admin.labelServiceNameLang, Languages.pl.code)}</Form.Label>
                            <Form.MultiLanguageTextInput lang="pl"/>
                        </Form.Group>
                        <Form.Group name="name" controlId="name_en">
                            <Form.Label>{formatString(msgs.admin.labelServiceNameLang, Languages.en.code)}</Form.Label>
                            <Form.MultiLanguageTextInput lang="en"/>
                        </Form.Group>
                        <Form.Group name="desc" controlId="desc_pl">
                            <Form.Label>{formatString(msgs.admin.labelServiceDescLang, Languages.pl.code)}</Form.Label>
                            <FormMultiLanguageRichEditor lang="pl" />
                        </Form.Group>
                        <Form.Group name="desc" children="desc_en">
                            <Form.Label>{formatString(msgs.admin.labelServiceDescLang, Languages.en.code)}</Form.Label>
                            <FormMultiLanguageRichEditor lang="en" />
                        </Form.Group>
                        {formik.values.type?null:<Form.Group name="order">
                            <Form.Label>{msgs.admin.labelViewOrder}</Form.Label>
                            <Form.Number float={false}/>
                        </Form.Group>}
                        {hasEmail(formik.values.type)?<Form.Group name="email">
                            <Form.Label>{msgs.admin.labelServiceEmail}</Form.Label>
                            <Form.Email/>
                        </Form.Group>:null}
                    </Tab>
                    {/*{LanguagesOrdered.map((l: LangInfo) => <Tab key={l.code} eventKey={l.code} title={formatString(msgs.admin.labelServiceFormLang, l.code)}>*/}
                    {/*    <ReactFormBuilder/>*/}
                    {/*</Tab>)}*/}
                    <Tab eventKey="prices" title={msgs.admin.labelServicePrices}>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="pricesPl">
                                    <Form.Label>{formatString(msgs.admin.labelPricesLang, Languages.pl.code)}</Form.Label>
                                    <Form.FileInput download remove preview={false} info/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="pricesEn">
                                    <Form.Label>{formatString(msgs.admin.labelPricesLang, Languages.en.code)}</Form.Label>
                                    <Form.FileInput download remove preview={false} info/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Tab>
                </Tabs>
        </Dialog>}</Form>;
    }

}
EditServiceDialog.contextType=LangContext;