//@flow
/* eslint-disable no-use-before-define */
import React, {useMemo} from 'react';
import msgs, {LangContext, useMsgs} from "../lib/Language";
import type {AdminInfo, AdminUser, RoleInfo} from "../api";
import {AdminUserValidation} from "../api";
import {RouteChildrenProps} from "react-router";
import {BreadcrumbItem, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import {Breadcrumb, Button, Col} from "react-bootstrap";
import {formatDateTime} from "../lib/DateTime";
import HomeScreen from "./HomeScreen";
import {Form} from "../lib/Form";
import {Events, events, store} from "../application";
import type {AllDataTableColumn, AllDataTableProps} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";

type State = {
    dataTime: number;
}

const AdminsTable = ( props: $Diff<AllDataTableProps, { columns: any }> ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<AllDataTableColumn<AdminInfo>>>(() => [
        {
            accessor: 'id',
            Header: msgs.gui.labelID,
            filter: 'text',
            className: "id",
        }, {
            accessor: 'name',
            Header: msgs.gui.labelFullName,
            filter: 'text',
            className: "name"
        }, {
            accessor: 'email',
            Header: msgs.gui.labelEmailLogin,
            filter: 'text',
            className: "email",
        }, {
            id: 'roles',
            Header: msgs.gui.labelRole,
            accessor: (row: AdminInfo) => row.roles.join(" "),
            filter: 'text',
            className: "lg",
        }, {
            accessor: 'lastLogged',
            Header: msgs.gui.labelLastLogin,
            Cell: ({value }) => formatDateTime(value),
            filter: 'date',
            className: "datetime",
        }, {
            accessor: 'status',
            Header: msgs.gui.labelStatus,
            className: "status",
            Cell: ({ value }) => msgs.gui["status"+value],
            filter: "select",
            options: [
                { label: "", value: "" },
                { label: msgs.gui.statusActive, value: "Active" },
                { label: msgs.gui.statusBlocked, value: "Blocked" },
                { label: msgs.gui.statusTemporaryBlocked, value: "TemporaryBlocked" }
            ]
        }
    ], [msgs.gui.language]);
    return <AllDataTable columns={columns} {...props}/>;
}

export default class AdminsScreen extends React.Component<RouteChildrenProps, State> {
    static url='/system_users';

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("SystemUserWrite");
        this.state= {
            dataTime: Date.now(),
        }
    }

    refresh() {
        this.setState({ dataTime: Date.now() });
    }

    onAddUser() {
        dialogOpen(this, EditAdminDialog.link('-'), () => this.refresh());
    }

    onEditUser(row: AdminInfo) {
        dialogOpen(this, EditAdminDialog.link(row.id), () => this.refresh());
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuAdministrators}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuAdministrators}>
                {this.edit?<Button onClick={(e) => this.onAddUser(e)}>{msgs.admin.buttonAddSystemUser}</Button>:null}
            </PageHeader>
            <AdminsTable
                data={store.userApi.getAdmins}
                onRowClick={(row) => this.onEditUser(row)}
                dataTimestamp={this.state.dataTime}
                historyState="table"
            />
        </>;
    }
}
AdminsScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ uid: string }> & {
}

type EditState = {
    value: AdminUser|null;
    roles: Array<RoleInfo>|null;
}

export class EditAdminDialog extends React.Component<EditProps, EditState> {
    static url="/system_user/:uid";
    static link = (id: string) => "/system_user/"+id;
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("SystemUserWrite");
        this.state={
            value: null,
            roles: null,
        }
    }

    userId(): string {
        return this.props.match.params.uid;
    }

    componentDidMount(): void {
        const uid=this.userId();
        store.flushRoles();
        store.getRoles().then(roles => this.setState({ roles }));
        store.userApi.getAdmin(uid).then(user => {
            this.setState({ value: user });
        });
    }

    render() {
        if(!this.state.value || !this.state.roles) return null;
        const isNew=this.props.match.params.uid==='-';
        return <Form
            initialValues={this.state.value}
            validate={AdminUserValidation}
            readonly={!this.edit}
            customValidation={(values: AdminUser) => {
                if(!values.passwordVisible) {
                    if(values.newPassword!==values.repeatPassword) {
                        return { 'repeatPassword': msgs.gui.validatePasswordDifferent }
                    }
                }
            }}
            onSubmit={async (values, helper) => {
                let res=await store.userApi.saveAdmin(values);
                if(!Form.setError(helper, res)) {
                    this.props.history.goBack();
                    events.emit(Events.AdministratorChange);
                }
            }}
        >{(formik) => (
            <Dialog
                title={isNew?msgs.gui.titleAddUser:msgs.gui.titleEditUser}
                onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
            >
                <Form.RowFormError/>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="name">
                            <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                            <Form.Control maxLength={200}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="status">
                            <Form.Label>{msgs.gui.labelStatus}</Form.Label>
                            <Form.Control as="select">
                                <option value="Active">{msgs.gui.statusActive}</option>
                                <option value="TemporaryBlocked">{msgs.gui.statusTemporaryBlocked}</option>
                                <option value="Blocked">{msgs.gui.statusBlocked}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="email">
                            <Form.Label>{msgs.gui.labelEmailLogin}</Form.Label>
                            <Form.Email disabled={!isNew} maxLength={120}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="roles">
                            <Form.Label>{msgs.gui.labelRole}</Form.Label>
                            <Form.MultiSelect
                                options={this.state.roles}
                                getOptionLabel={i => i.name}
                                getOptionValue={i => i.id}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="newPassword">
                            <Form.Label>{msgs.gui.labelNewPassword}</Form.Label>
                            <Form.Password
                                checkStrength={true}
                                onVisibilityChange={(visible) => {
                                    formik.setFieldValue("passwordVisible", visible);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="repeatPassword">
                            <Form.Label>{msgs.gui.labelRepeatPassword}</Form.Label>
                            <Form.Password
                                disabled={formik.values.passwordVisible}
                                toggleVisibility={false}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Dialog>)}</Form>;
    }
}
EditAdminDialog.contextType=LangContext;
