//@flow
import {remoteProxy} from "./lib/Network";
import type {LangInfo} from "./lib/Language";
import {langLink, langSort, sortLangOptions} from "./lib/Language";
import type {
    AdminSelfInfo,
    AdminStats,
    AgreementInfo, Dictionaries,
    LangOption, Option,
    OrderTypeInfo,
    PacketInfo,
    RejectionReasonInfo,
    RoleInfo,
    SysPublicAPI,
    SystemRight,
    SysUserAPI
} from "./api";
import { events} from "./lib/Events";
import {shallowEquals} from "./lib/Utils";
import type {RequestError} from "./lib/Network";
import LoginScreen from "./auth/LoginScreen";
export { events, useEvent} from "./lib/Events";

/**
 * Zdarzenia wysyłane przez aplikację
 * @type {{}}
 */
export const Events = {
    /** Zdarzenie wywoływane w przypadku zmiany stanu zalogowania użytkownika */
    Login: "Login",
    /** Czy aplikacja zainicjowana */
    Ready: "Ready",
    /** Zmiana w windykacji */
    VindicationChange: "VindicationChange",
    /** Zmiana w usłudze */
    OrderChange: "OrderChange",
    /** Zmiana w użytkowniku */
    UserOrganizationChange: "UserOrganizationChange",
    /** Zmiana w organizacji */
    OrganizationChange: "OrganizationChange",
    /** Zmiana z użytkownikach firmowych */
    AdministratorChange: "AdministratorChange",
    /** Zmiana banerów */
    BannerChange: "BannerChange",
    /** Zmiana powodów odrzuceń */
    RejectionReasonChange: "RejectionReasonChange",
    /** Zmiana w rolach */
    RoleChange: "RoleChange",
    /** Zmiana w pakietach */
    PacketChange: "PacketChange",
    /** Zmiana treści strony tekstowe */
    TextPageChange: "TextPageChange",
    /** Zmiana treści powiadomienia */
    TemplateChange: "TemplateChange",
    /** Zmiana w liście usług */
    ServiceChange: "ServiceChange",
    /** Zmiana w trudnych długach */
    DifficultDebtChange: "DifficultDebtChange",
    /** Zmiana w statystykach */
    Stats: "Stats",
    /** Zmiana w słownikach globalnych */
    DictionariesChange: "DictionariesChange",
}

export interface StoreAPI {
    +user: AdminSelfInfo;
    +isLogged: boolean;
    +publicApi: SysPublicAPI;
    +userApi: SysUserAPI;
    +stats: AdminStats;

    hasRight(...right: SystemRight): boolean;
    getOrder(orderId: string): OrderTypeInfo|void;

    refreshStats(): Promise<void>;

    flushRoles(): void;
    getRoles(): Promise<Array<RoleInfo>>;

    flushOrderTypes(): void;
    getOrderTypes(): Promise<Array<OrderTypeInfo>>;
    getOtherOrders(): Array<OrderTypeInfo>;

    getAgreements(): Promise<Array<AgreementInfo>>;

    flushPackets(): void;
    getPackets(): Promise<Array<PacketInfo>>;

    flushRejectionReasons(): void;
    getRejectionReasons(): Promise<Array<RejectionReasonInfo>>;

    getOrganizationOrigins(): Promise<Array<String>>;
    flushOrganizationOrigins(): void;

    getDictionaries(): Promise<Dictionaries>;

    init(): Promise<void>;
    updateLanguage(lang: LangInfo): void;
    processLogin(): Promise<void>;
    logout(): Promise<void>;

    setReturnPage(returnUrl: string|{}): void;
    getReturnPage(): undefined|string|{};
}

/** Klasa ze stanem aplikacji */
class Store implements StoreAPI {
    /** Informacje o zalogowanym użytkowniku; null, gdy nie ma zalogowanego użytkownika */
    user: AdminSelfInfo;
    /** Statystyki administracyjne */
    stats: AdminStats;
    /** Czy zainicjowany */
    ready: boolean;
    /** Uprawnienia użytkownika */
    rights: Set<SystemRight>;
    /** Strona powrotna po zalogowaniu */
    returnUrl:? string|{};
    /** Zbuforowane role */
    _roles: Array<RoleInfo>;
    /** Dostępne usługi w systemie */
    _orderTypes: Array<OrderTypeInfo>;
    /** Inne usługi (nie wbudowane) */
    _otherOrders: Array<OrderTypeInfo>;
    /** Zgody */
    _agreements: Array<AgreementInfo>;
    /** Dostępne pakiety */
    _packets: Array<PacketInfo>;
    /** Powody odrzucenia */
    _rejectionReasons: Array<RejectionReasonInfo>;
    /** Słowniki aplikacji */
    _dictionaries: Dictionaries;
    /** Słownik pochodzenie organizacji */
    _organizationOrigins: Array<String>;

    publicApi: SysPublicAPI;
    userApi: SysUserAPI;

    constructor() {
        this.user=null;
        this.ready=false;
        this.stats=null;
        this.publicApi= remoteProxy(process.env.REACT_APP_SERVER+"/data/public", this._handleErrorCallback);
        this.userApi= remoteProxy(process.env.REACT_APP_SERVER+"/data/user", this._handleErrorCallback);
        events.on(Events.OrderChange, this.refreshStats);
        events.on(Events.UserOrganizationChange, this.refreshStats);
        events.on(Events.VindicationChange, this.refreshStats);
        events.on(Events.OrganizationChange, this.refreshStats);
        events.on(Events.DictionariesChange, this.flushDictionaries);
        window.setInterval(this.refreshStats, 10000);
    }

    _handleErrorCallback=(error: RequestError) => {
        if(error.code===401 || error.code===403) {  // Unauthorized - nastąpiło wylogowanie (np. restart serwera, utrata ważności sesji)
            if(this.user!==null) {
                console.log("Got 401 response - moving to login page");
                window.location=langLink(LoginScreen.url)+"#session";
            }
            return true;
        }
        events.emit(Events.Error, error);
        return true;
    }

    getReturnPage() {
        const res=this.returnUrl;
        delete this.returnUrl;
        return res;
    }

    setReturnPage(returnUrl) {
        this.returnUrl=returnUrl;
    }

    hasRight(...right: SystemRight): boolean {
        if(!Array.isArray(right)) return false;
        for(let i=0;i<right.length;++i) {
            if(this.rights.has(right[i])) return true;
        }
        return false;
    }

    getOrder(orderId: string): OrderTypeInfo|void {
        if(!Array.isArray(this._orderTypes)) return null;
        return this._orderTypes.find(o => o.id===orderId);
    }

    _initOrders() {
        if(!this._orderTypes) return;
        langSort(this._orderTypes, (o) => o.name);
        this._otherOrders=this._orderTypes.filter((o: OrderTypeInfo) => !o.type);
    }

    /** Sprawdza, czy użytkownik jest zalogowany do systemu (sesja) */
    _checkLogin(cb?: function) {
        this.publicApi.getSelf().then((user: AdminSelfInfo) => {
            const wasLogged=this.isLogged;
            this.user=user;
            this.rights=new Set<SystemRight>();
            this.user.rights.forEach(r => this.rights.add(r));
            this._orderTypes=user.orderTypes;
            this._initOrders();
            this.refreshStats().finally();
            this.getDictionaries().finally(() => {});
            if(!wasLogged) {
                events.emit(Events.Login, true);
            }
        }).catch(err => {
            console.warn(err);
            const wasLogged=this.isLogged;
            this.user=null;
            this.rights=new Set();
            if(wasLogged) {
                events.emit(Events.Login, false);
            }
        }).finally(() => {
            if(!this.ready) {
                this.ready=true;
                events.emit(Events.Ready, true);
            }
            if(cb) cb();
        })
    }

    refreshStats = () => {
        return new Promise<void>((resolve, reject) => {
            if(!this.user) {
                reject("Not logged");
                return;
            }
            this.userApi.getStats().then(stats => {
                if(stats===null) return;
                if(this.stats!==null){
                    const oldKeys=Object.keys(this.stats);
                    const len=oldKeys.length;
                    if (Object.keys(stats).length === len) {
                        let equals=true;
                        for (let i = 0; i < len; i++) {
                            const key = oldKeys[i];
                            if(!shallowEquals(stats[key], this.stats[key])) {
                                equals=false;
                                break;
                            }
                        }
                        if(equals) return;  // brak zmian
                    }
                }
                this.stats = stats;
                // console.log("Updated stats: "+JSON.stringify(stats));
                events.emit(Events.Stats, stats);
            }).catch(reject);
        })
    }

    processLogin() {
        return new Promise(((resolve, reject) => {
            this._checkLogin(() => {
                if(this.user) resolve();
                else reject();
            })
        }))
    }

    get isLogged() { return this.user!=null; }

    init(): Promise<void> {
        return new Promise<void>(((resolve, reject) => {
            if(this.ready) {
                resolve();
            } else {
                this._checkLogin(resolve);
            }
        }))
    }

    logout() {
        return new Promise(((resolve, reject) => {
            this.userApi.logout().then(() => {
                const wasLogged=this.isLogged;
                this.user=null;
                this.rights=new Set<SystemRight>();
                delete this._agreements;
                delete this._orderTypes;
                delete this._roles;
                delete this._packets;
                delete this._rejectionReasons;
                delete this._otherOrders;
                if(wasLogged) {
                    events.emit(Events.Login, false);
                }
                resolve();
            }).catch(reject);
        }))
    }

    updateLanguage(lang: LangInfo): void {
        this.publicApi=remoteProxy(process.env.REACT_APP_SERVER+"/"+lang.code+"/data/public", this._handleErrorCallback);
        this.userApi=remoteProxy(process.env.REACT_APP_SERVER+"/"+lang.code+"/data/user", this._handleErrorCallback);
        this._initOrders();
        langSort(this._rejectionReasons, (r) => r.message);
    }

    flushRoles() {
        delete this._roles;
    }

    /** Metoda pobierająca role, która buforuje */
    getRoles(): Promise<Array<RoleInfo>> {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._roles)) {
                resolve(this._roles);
                return;
            }
            this.userApi.getAllRoles().then(roles => {
                this._roles=roles;
                resolve(roles);
            }).catch(reject);
        })
    }

    flushOrderTypes() {
        delete this._orderTypes;
    }

    getOrderTypes(): Promise<Array<OrderTypeInfo>> {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._orderTypes)) {
                resolve(this._orderTypes);
                return;
            }
            this.userApi.getOrderTypes().then(types => {
                this._orderTypes=types;
                this._initOrders();
                resolve(types);
            }).catch(reject);
        })
    }

    getOtherOrders(): Array<OrderTypeInfo> {
        return this._otherOrders;
    }

    getAgreements(): Promise<Array<AgreementInfo>> {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._agreements)) {
                resolve(this._agreements);
                return;
            }
            this.userApi.getAgreements().then(agreements => {
                this._agreements=agreements;
                resolve(this._agreements);
            }).catch(reject);
        })
    }

    flushPackets(): void {
        delete this._packets;
    }

    getPackets(): Promise<Array<PacketInfo>> {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._packets)) {
                resolve(this._packets);
                return ;
            }
            this.userApi.getPackets().then(packets => {
                this._packets=packets;
                resolve(this._packets);
            }).catch(reject);
        })
    }

    flushRejectionReasons(): void {
        delete this._rejectionReasons;
    }

    getRejectionReasons(): Promise<Array<RejectionReasonInfo>> {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._rejectionReasons)) {
                resolve(this._rejectionReasons);
                return;
            }
            this.userApi.getAllRejectionReasons().then(reasons => {
                langSort(reasons, (r) => r.message);
                this._rejectionReasons=reasons;
                resolve(reasons);
            }).catch(reject);
        })
    }

    flushOrganizationOrigins() {
        delete this._organizationOrigins;
    }

    getOrganizationOrigins() {
        return new Promise((resolve, reject) => {
            if(Array.isArray(this._organizationOrigins)) {
                resolve(this._organizationOrigins);
                return;
            }
            this.userApi.getOrganizationOrigins().then(origins => {
                this._organizationOrigins=origins;
                resolve(origins);
            }).catch(reject);
        })
    }

    flushDictionaries = () => {
        delete this._organizationOrigins;
    }

    getDictionaries(): Promise<Dictionaries> {
        return new Promise<Dictionaries>((resolve, reject) => {
            if(this._dictionaries) {
                resolve(this._dictionaries);
                return;
            }
            this.userApi.getDictionaries().then((dictionaries: Dictionaries) => {
                sortLangOptions(dictionaries.insurers);
                sortLangOptions(dictionaries.documentTypes);
                this._dictionaries=dictionaries;
                resolve(dictionaries);
            }).catch(reject);
        })
    }


}

export const store: StoreAPI=new Store();