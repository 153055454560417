//@flow
/* eslint-disable no-use-before-define */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RouteChildrenProps, useRouteMatch} from "react-router";
import msgs, {currentLang, formatString, getLangValue, getMessage, LangContext, langLink, useMsgs} from "../lib/Language";
import {Alert, Breadcrumb, Button, Card, Col, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {BreadcrumbItem, Dialog, dialogOpen, Hint, Icon, IconAlert, PageHeader, waitGlass} from "../lib/Components";
import type {
    Dictionaries,
    FieldFilter, InvoiceMonitoringOrganizationData, InvoiceMonitoringOrganizationSettings,
    Option,
    OrderTypeInfo,
    OrganizationData,
    OrganizationDetails,
    OrganizationInfo,
    OrganizationStatus,
    PacketInfo,
    RejectionReasonInfo,
    UserOrganizationData,
    UserOrganizationInfo,
    UserRight, VindicationDictionaries
} from "../api";
import {OrganizationDataValidation, TableQuery, UOStatus} from "../api";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {DataTableColumn, DataTableProps, DataTableRowSelection} from "../lib/DataTable";
import DataTable, {dateCell, dateTimeCell} from "../lib/DataTable";
import {BForm, Form} from "../lib/Form";
import {EditUserDialog} from "./UsersScreen";
import {UserCompanySettings} from "../lib/UserCompanySettings";
import {getDownloadLink, SingleFileInput} from "../lib/Upload";
import {arrayUpdate, getLocationState, getOptionLabel, replaceHistoryState} from "../lib/Utils";
import Autosuggest from "react-autosuggest";
import Validate from "../lib/Validation";
import ClientOrderButton from "./ClientOrder";
import {sortRights} from "../lib/Rights";
import {DateInput, formatDate, formatDateTime} from "../lib/DateTime";
import {useRpcQuery} from "../lib/QueryUtils";
import {useHistory, useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import type {FilterFieldConfig, FilterInputProps} from "../lib/Filter";
import FilterInput from "../lib/Filter";


function renderSuggestion(suggestion) {
    return <span>{suggestion.label}</span>
}

/**
 * Komponent do podpowiadania/wybierani organizacji.
 */
const UserSuggest = ({ value, onAddUser }: {
    value: string;
    onAddUser: (uId: string) => void;
}) => {
    const msgs = useMsgs();
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(null);
    const req = useRef(0);
    const queryFunc = useCallback((query) => {
        const r = ++req.current;
        store.userApi.suggestUserForOrganization(value, query.value).then(res => {
            if (r !== req.current) return;
            setSuggestions(res);
        })
    }, [value]);
    const clearFunc = useCallback(() => {
        setSuggestions([]);
    }, [])
    const handleQueryChange = useCallback((_, {newValue}) => {
        setQuery(newValue);
        setSelected(null);
    }, []);
    return <div className="d-flex">
        <Autosuggest
            id="suggest-user"
            suggestions={suggestions}
            getSuggestionValue={getOptionLabel}
            onSuggestionsFetchRequested={queryFunc}
            onSuggestionsClearRequested={clearFunc}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(_, {suggestion}) => setSelected(suggestion.value)}
            inputProps={{
                placeholder: msgs.admin.hintAddNextUser,
                value: query,
                onChange: handleQueryChange
            }}
            theme={{
                container: 'autosuggest flex-grow-1',
                input: 'form-control',
                suggestionsContainer: 'dropdown',
                suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
                suggestion: 'dropdown-item',
                suggestionHighlighted: 'active'
            }}
        />
        <Button
            variant="outline-secondary" className="ml-2 btn-circle"
            disabled={!selected}
            onClick={() => {
                onAddUser(selected)
                setSelected(null);
            }}
        >+</Button>
    </div>
}

const OrganizationsFilter = ({ value, onChange, ...props}: $Diff<FilterInputProps, { fields: any }>) => {
    const msgs = useMsgs();
    const query = useRpcQuery('/user', 'getPackets');
    const fields = useMemo<Array<FilterFieldConfig>>(() => {
        if(!query.isSuccess) return null;
        return [
            {
                label: msgs.gui.labelPacket,
                field: 'packet',
                type: 'select',
                className: 'status',
                getValue: (i: PacketInfo) => i.id,
                getLabel: (i: PacketInfo) => getLangValue(i.name),
                options: query.data,
                permanent: true,
            }
        ]
    }, [msgs, query.data] );
    const handleChange=useCallback((value) => {
        onChange(value.fields);
        console.log("Organizations filter", value.fields);
    }, [onChange]);
    const valueFields=useMemo(() => {
        return {
            fields: value,
            text: '',
        }
    }, [ value ])
    if(!fields) return null;
    return <FilterInput mainField={false} fields={fields} onChange={handleChange} value={valueFields} {...props}/>
}

export const OrganizationsTable = ({ actions, showOrigin, showPromoCodes, ...props }: $Diff<DataTableProps, { columns: Array<DataTableColumn<any, any>>; }> & {
    actions: (row: OrganizationInfo) => React$Node;
    showOrigin: boolean; showPromoCodes: boolean;
}) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumn<OrganizationInfo>>>(() => {
        let res = [
            {
                accessor: 'id',
                className: 'id',
                Header: msgs.gui.labelRFID,
                filter: 'text',
            }, {
                accessor: 'ecId',
                className: 'id',
                Header: msgs.gui.labelECID,
                filter: 'text',
            }, {
                accessor: 'name',
                Header: msgs.gui.labelOrganizationName,
                filter: 'text',
            }, {
                accessor: 'tax',
                className: 'nip',
                Header: msgs.gui.labelNIPPacket,
                filter: 'text',
                Cell: ({row}) => <>{row.original.tax}<br/>{getLangValue(row.original.packet)}</>,
            }, {
                accessor: 'added',
                className: 'date',
                Header: msgs.gui.labelRegisterDate,
                Cell: dateCell,
                filter: 'date',
            }];
        if(showPromoCodes) res.push({
            accessor: "promoCodes",
            Header: msgs.gui.labelPromoCode,
            className: "status",
            filter: "text",
        });
        if (showOrigin) res.push({
            accessor: "origin",
            Header: msgs.admin.labelOrganizationOrigin,
            className: "status",
            filter: "text",
        })

        if (actions) res.push({
            id: "actions",
            Header: msgs.gui.labelActions,
            Cell: ({row}) => actions(row.original),
            className: "actions2",
        });

        return res;
    }, [msgs.gui.language, showPromoCodes, actions]);
    return <DataTable columns={columns} {...props}/>;
}

type State= {
    category: OrganizationStatus;
    tabStatus: OrganizationStatus;

    statusFilter: Array<FieldFilter>;
    extraFilters: Array<FieldFilter>;
    finalFilters: Array<FieldFilter>;
    dataTimestamp: number;
    actions: null|() => React$Node;
    selected: DataTableRowSelection;
    selectedAmount: number;
}


export default class OrganizationsScreen extends React.Component<RouteChildrenProps, State> {
    static url="/organizations";

    edit: boolean;
    report: boolean;
    /** Na potrzeby eksportu */
    query: TableQuery;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("OrganizationsWrite");
        this.report=store.hasRight("OrganizationsExport");
        const tab: OrganizationStatus=getLocationState(this.props.location, 'tab') || "New";
        this.state={
            tabStatus: tab,
            statusFilter: [{ field: 'status', value: tab }],
            extraFilters: [],
            finalFilters: [{ field: 'status', value: tab }],
            dataTimestamp: 0,
            actions: this.getActions(tab),
            selected: null,
            selectedAmount: 0,
        }
    }

    refresh() {
        this.setState({ dataTimestamp: Date.now() })
    }

    handleOrganizationAccept(e: SyntheticEvent, org: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        dialogOpen(this, AcceptOrganizationDialog.link(org.id), () => {
            this.refresh();
            events.emit(Events.OrganizationChange);
        });
        // store.userApi.changeOrganizationStatus(user.id, "Active").then(() => {
        //     this.setState({ dataTimestamp: Date.now() })
        // });
    }

    handleOrganizationReject(e: SyntheticEvent, user: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeOrganizationStatus(user.id, "Rejected").then(() => {
            this.refresh()
            events.emit(Events.OrganizationChange);
        });
    }

    handleOrganizationActivate(e: SyntheticEvent, user: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeOrganizationStatus(user.id, "Active").then(() => {
            this.refresh()
            events.emit(Events.OrganizationChange);
        });
    }

    handleOrganizationTempBlock(e: SyntheticEvent, user: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeOrganizationStatus(user.id, "TemporaryBlocked").then(() => {
            this.refresh()
            events.emit(Events.OrganizationChange);
        });
    }

    handleOrganizationBlock(e: SyntheticEvent, user: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeOrganizationStatus(user.id, "Blocked").then(() => {
            this.refresh()
            events.emit(Events.OrganizationChange);
        });
    }

    handleOrganizationDelete(e: SyntheticEvent, org: OrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        if(window.confirm(msgs.gui.deleteConfirm)) {
            store.userApi.deleteOrganization(org.id).then((err) => {
                if (Validate.isError(err)) {
                    window.alert(getMessage(Validate.getError(err)));
                    return;
                }
                this.refresh()
                events.emit(Events.OrganizationChange);
            });
        }
    }

    waitingActions=(row) => <>
        <Button
            variant="success"
            onClick={(e) => this.handleOrganizationAccept(e, row)}
        >{msgs.gui.actionActivate}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationReject(e, row)}
        >{msgs.gui.actionReject}</Button>
    </>;

    activeActions=(row) => <>
        <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationTempBlock(e, row)}
        >{msgs.gui.actionTempBlock}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationBlock(e, row)}
        >{msgs.gui.actionBlock}</Button>
    </>;

    tempBlockedActions=(row) => <>
        <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationBlock(e, row)}
        >{msgs.gui.actionBlock}</Button> <Button
            variant="success"
            onClick={(e) => this.handleOrganizationActivate(e, row)}
        >{msgs.gui.actionActivate}</Button>
    </>;

    blockedActions = (row) => <>
        <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationTempBlock(e, row)}
        >{msgs.gui.actionTempBlock}</Button> <Button
        variant="success"
        onClick={(e) => this.handleOrganizationActivate(e, row)}
    >{msgs.gui.actionActivate}</Button>
    </>;

    rejectedActions = (row) => <>
        <Button
            variant="danger"
            onClick={(e) => this.handleOrganizationDelete(e, row)}
        >{msgs.gui.actionDelete}</Button>
    </>;

    getActions(status: OrganizationStatus) {
        switch(status) {
            case "New": return this.waitingActions;
            case "Active": return this.activeActions;
            case "TemporaryBlocked": return this.tempBlockedActions;
            case "Blocked": return this.blockedActions;
            case "Rejected": return this.rejectedActions;
        }
    }

    shouldComponentUpdate(nextProps: $ReadOnly<Props>, nextState: $ReadOnly<State>, nextContext: any): boolean {
        if(this.state.tabStatus!==nextState.tabStatus) {
            this.setState({
                actions: this.getActions(nextState.tabStatus)
            })
        }
        return true;
    }

    onAddOrganization() {
        dialogOpen(this, EditOrganizationDialog.link('-'), () => this.refresh());
    }

    handleRowClick=(o: OrganizationInfo) => {
        this.props.history.push(langLink(ViewOrganizationScreen.link(o.id)));
    }

    handleSelectionChange=(selected: DataTableRowSelection) => {
        this.setState({
            selected: selected,
            selectedAmount: Object.keys(selected).length
        })
    }

    handleRejectSelected=() => {
        store.userApi.changeMultipleOrganizationStatus(Object.keys(this.state.selected), "Rejected").then(res => {
            this.refresh();
            events.emit(Events.OrganizationChange);
        })
    }

    renderSelectedActions() {
        if(this.state.tabStatus!=="New" || this.state.selectedAmount===0) return null;
        return <>
            <Button variant="link" onClick={this.handleRejectSelected}>{msgs.admin.actionRejectSelected}</Button>
        </>;
    }

    exportResults() {
        if(!this.query) return;
        store.userApi.exportOrganizations(this.query).then(file => {
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        }).finally(waitGlass());
    }

    queryOrganization=(query: TableQuery) => {
        this.query=query;   // zapamiętujemy zapytanie na potrzeby eksportu
        return store.userApi.getOrganizations(query);
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuOrganizations}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuOrganizations}>
                {this.edit?<Button onClick={e => this.onAddOrganization(e)}>{msgs.admin.actionAddOrganization}</Button>:null}
            </PageHeader>

            <Nav
                variant="tabs"
                defaultActiveKey={this.state.tabStatus}
                onSelect={(key) => {
                    this.setState({
                        tabStatus: key,
                        statusFilter: [{ field: 'status', value: key }],
                        finalFilters: [{ field: 'status', value: key }, ...this.state.extraFilters],
                        dataTimestamp: Date.now(),
                    })
                    replaceHistoryState(this.props.history, this.props.location, 'tab', key);
                }}
            >
                <Nav.Item><Nav.Link eventKey="New">{msgs.gui.statusNew}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Active">{msgs.gui.statusActive}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="TemporaryBlocked">{msgs.gui.statusTemporaryBlocked}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Blocked">{msgs.gui.statusBlocked}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Rejected">{msgs.gui.statusRejected}</Nav.Link></Nav.Item>
                {this.report?<div className="actions"><Button variant="info" onClick={() => this.exportResults()}>{msgs.gui.actionExportResults}</Button></div>:null}
            </Nav>
            <div className="tab-content">
                <OrganizationsFilter
                    className="mb-3 mt--3rem"
                    key={this.state.tabStatus+"_filter"}
                    value={this.state.extraFilters}
                    onChange={(value) => this.setState({
                        extraFilters: value,
                        finalFilters: [...this.state.statusFilter, ...value],
                        dataTimestamp: Date.now(),
                    })}
                />
                <OrganizationsTable
                    className="datatable-users"
                    showOrigin={this.state.tabStatus==="Active" || this.state.tabStatus==="Blocked" || this.state.tabStatus==="TemporaryBlocked"}
                    data={this.queryOrganization}
                    customFilters={this.state.finalFilters}
                    onRowClick={this.handleRowClick}
                    showPromoCodes={this.state.tabStatus==="New" || this.state.tabStatus==="Active"}
                    onSelectionChange={this.state.tabStatus==="New"?this.handleSelectionChange:null}
                    dataTimestamp={this.state.dataTimestamp}
                    historyState={"table_"+this.state.tabStatus}
                    key={this.state.tabStatus}
                    actions={this.state.actions}
                    initialSortBy="-added"
                />
                {this.renderSelectedActions()}
            </div>
        </>;
    }
}
OrganizationsScreen.contextType=LangContext;

type ViewProps = RouteChildrenProps<{ id: string }> & {

}

type ViewState= {
    data: OrganizationDetails|null;
    changePacket: boolean;
    dataTimestamp: number;
}

const OrganizationUsersTable = ({...props}: $Diff<DataTableProps, { columns: any }>) => {
    const msgs = useMsgs();
    const remoteHelp = useMemo(() => store.hasRight("RemoteHelp"), []);
    const columns = useMemo<Array<DataTableColumn<UserOrganizationInfo>>>(() => [
        {
            accessor: "name",
            Header: <>{msgs.gui.labelFullName}<br/>{msgs.gui.labelEmail}</>,
            filter: "text",
            className: "name",
            Cell: ({row}) => <><h1>{row.original.name}</h1>{row.original.email}</>
        }, {
            accessor: "lastOrgLogged",
            Header: msgs.gui.labelLastLogin,
            Cell: dateTimeCell,
            filter: "date",
            className: "datetime",
        }, {
            accessor: "status",
            Header: msgs.gui.labelStatus,
            className: "status",
            Cell: ({value}) => msgs.gui["status" + value],
            filter: "select",
            options: [
                {label: "", value: ""},
                {label: msgs.gui.statusActive, value: "Active"},
                {label: msgs.gui.statusBlocked, value: "Blocked"},
                {label: msgs.gui.statusTemporaryBlocked, value: "TemporaryBlocked"}
            ]
        }, {
            id: "actions",
            className: "actions2",
            Cell: ({row}) => <>
                {remoteHelp ? <ClientOrderButton uoId={row.original.id}/> : null}
            </>,
        }
    ], [msgs.gui.language]);
    return <DataTable columns={columns} {...props}/>;
}

const ImportedLink = ({ id }: { id: string }) => {
    const msgs=useMsgs();
    const p=msgs.gui.labelPricesFile;
    return <a href={`/data/vprices/${id}/${p}.pdf`} target="_blank"><Icon.Download/></a>;
}

const NotesMap = ({ value }: { value: { [string]: any } }) => {
    if(!value) return null;
    const entries=Object.entries(value);
    if(entries.length===0) return null;
    return <div className="notes-map">
        {entries.map(([ key, value ], index) => <div key={index} dangerouslySetInnerHTML={{ __html: value }}/>)}
    </div>
}

/**
 * Informacje o monitoringu faktur; podgląd.
 */
const InvoiceMonitoringInfo = ({ settings, onEdit }: { settings: InvoiceMonitoringOrganizationData, onEdit: () => void }) => {
    const msgs=useMsgs();
    if(!settings) return null;
    return <>
        <Card className="data basic">
            <Card.Body>
                <Row>
                    <Col md={10} className="sep-border">
                        <Row>
                            <Col md={12}>
                                <h3>{msgs.admin.titleInvoiceMonitoring}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>{msgs.admin.labelSubscription}</label>
                                {settings.subscription}
                                {settings.subscription!=="Free" && settings.subscriptionDueTo && (" ("+formatString(msgs.gui.infoValidTo, formatDate(settings.subscriptionDueTo))+")")}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.admin.labelImSync}</label>
                                {settings.sync}
                                {formatDateTime(settings.lastSync)}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.gui.channelSMS}</label>
                                {`${settings.periodSms}/(${settings.extraSms}) ${settings.totalSms}`}
                                <Hint>{formatString(msgs.admin.imUsageHint, settings.periodSms, settings.extraSms, settings.totalSms)}</Hint>
                            </Col>
                            <Col md={3}>
                                <label>{msgs.gui.channelEmail}</label>
                                {`${settings.periodEmails}/(${settings.extraEmails}) ${settings.totalEmails}`}
                                <Hint>{formatString(msgs.admin.imUsageHint, settings.periodEmails, settings.extraEmails, settings.totalEmails)}</Hint>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={3}>
                                <label>{msgs.gui.labelDefaultScenario}</label>
                                {getLangValue(settings.scenario)}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.admin.labelImClients}</label>
                                {`${settings.unpaidClients}/${settings.clients}`}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.admin.labelImInvoices}</label>
                                {`${settings.unpaidInvoices}/${settings.invoices}`}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.admin.labelVindicationGracePeriod}</label>
                                {settings.vindicationGracePeriod}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2} className="text-center">
                        <Button variant="link" onClick={onEdit}>
                            <div className="icon"><span className="icon-edit"/></div>
                            {msgs.admin.imEditSettings}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>;
}

const DisableInvoicesForm = ({ orgId }) => {
    const [date, setDate] = useState<string | null>(null);
    const [ processing, setProcessing ] = useState(false);
    const handleDisableInvoices = useCallback(async () => {
        if(!date) return;
        setProcessing(true);
        try {
            const res = await store.userApi.disableAllInvoicesBefore(orgId, date);
            window.alert(formatString(msgs.admin.imDisableAllInvoicesResult, res, formatDate(date)));
            setDate(null);
        }catch (e) {
            window.alert(e);
        }finally {
            setProcessing(false);
        }
    }, [ setProcessing, date, orgId, setDate ] )

    return <>
        <Row>
            <Col md={12}>
                {msgs.admin.imDisableAllInvoicesTitle}
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <DateInput
                    value={date}
                    onChange={setDate}
                    popperPlacement="top"
                />
            </Col>
            <Col md={6}>
                <Button
                    disabled={processing || !date}
                    onClick={handleDisableInvoices}
                    variant="danger"
                >{msgs.admin.imDisableAllInvoicesAction}</Button>
            </Col>
        </Row>
    </>
}

export const EditInvoiceMonitoringSettingsDialog = () => {
    const msgs = useMsgs();
    const {id} = useParams();
    const query = useRpcQuery('/user', 'getInvoiceMonitoringSettings', id);
    const client=useQueryClient();
    const history=useHistory();
    if (!query.data) return null;
    const settings: InvoiceMonitoringOrganizationSettings = query.data;

    return <Form
        enableReinitialize={true}
        initialValues={settings}
        validate={(values: InvoiceMonitoringOrganizationSettings) => {
            let err={};
            const type=values.type;
            if(type!=="Free" && type!=="Corporate") {
                if(!values.subscriptionEnd) err.subscriptionEnd='validation.required';
            }
            if(typeof(values.vindicationGracePeriod)==='number') {
                if(values.vindicationGracePeriod<0 || values.vindicationGracePeriod>999) {
                    err.vindicationGracePeriod='validation.invalidValue';
                }
            }

            return err;
        }}
        onSubmit={async (values: InvoiceMonitoringOrganizationSettings) => {
            let fin: InvoiceMonitoringOrganizationSettings= {
                type: null,
                dom: null,
                extraEmail: null,
                extraSms: null,
                orgId: settings.orgId,
                subscriptionEnd: null,
                vindicationGracePeriod: values.vindicationGracePeriod
            };
            const oldType=settings.type, newType=values.type;
            if(oldType!==newType) { // zmiana typu abonamentu
                fin.type=newType;
                if(newType==="Corporate" && oldType!=="Free") {
                    if(!window.confirm("Zmiana abonamentu na Corporate z niedarmowego, co spowoduje skasowanie istniejącego pakietu wraz z pulą dostępnych SMS oraz E-mail.\nCzy jesteś pewien?")) {
                        return
                    }
                }
            }
            if(settings.dom!==values.dom) fin.dom=values.dom;
            if(settings.extraEmail!==values.extraEmail) fin.extraEmail=values.extraEmail;
            if(settings.extraSms!==values.extraSms) fin.extraSms=values.extraSms;
            if(settings.subscriptionEnd!==values.subscriptionEnd) fin.subscriptionEnd=values.subscriptionEnd;
            console.log("Save IM settings: ", { old: settings, values, fin });

            await store.userApi.saveInvoiceMonitoring(fin);
            await client.removeQueries([ "api", "/user", "getInvoiceMonitoringSettings", id ])
            await client.invalidateQueries([ "api", "/user", "getDisplayOrganization", id ])
            events.emit(Events.OrganizationChange);
            window.setTimeout(() => history.goBack(), 50);
        }}
    >{(formik) => {
        return <Dialog
            title={msgs.admin.imEditSettings}
            onAccept={(e) => {
                formik.handleSubmit(e);
                return false;
            }}
        >
            <div style={{ minHeight: '22em' }}>
                <Row>
                    <Col md={6}>
                        <Form.Group name="type">
                            <Form.Label>{msgs.admin.labelSubscription}</Form.Label>
                            <Form.Select>
                                <option value="Free">Free</option>
                                <option value="Mini">Mini</option>
                                <option value="Midi">Midi</option>
                                <option value="Maxi">Maxi</option>
                                <option value="Corporate">Corporate</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="subscriptionEnd">
                            <Form.Label>{msgs.admin.labelImSubscriptionEnd}</Form.Label>
                            <Form.Date/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group name="dom">
                            <Form.Label>{msgs.admin.labelImDOM}</Form.Label>
                            <Form.Number min={1} max={31} step={1}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group name="extraEmail">
                            <Form.Label>{msgs.admin.labelExtraEmail}</Form.Label>
                            <Form.Number min={0} step={1}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group name="extraSms">
                            <Form.Label>{msgs.admin.labelExtraSMS}</Form.Label>
                            <Form.Number min={0} step={1}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group name="vindicationGracePeriod">
                            <Form.Label>{msgs.admin.labelVindicationGracePeriod}</Form.Label>
                            <Form.Number min={0} max={999}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <DisableInvoicesForm orgId={id}/>
                    </Col>
                </Row>
            </div>
        </Dialog>
    }}
    </Form>;
}
EditInvoiceMonitoringSettingsDialog.url='/edit_im/:id';
EditInvoiceMonitoringSettingsDialog.link= (id: string) => "/edit_im/" + id;

export class ViewOrganizationScreen extends React.Component<ViewProps, ViewState> {
    static url="/organization/:id";
    static link=(id: string) => "/organization/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("OrganizationsWrite");
        this.state={
            data: null,
            changePacket: false,
            dataTimestamp: 0,
        }
        this.usersFilter=[{ field: "org", value: this.props.match.params.id } ];
    }

    refresh() {
        store.userApi.getDisplayOrganization(this.props.match.params.id).then((data: OrganizationDetails) => {
            sortRights(data.services);
            this.setState({
                data,
                dataTimestamp: Date.now()
            });
        });
    }

    componentDidUpdate(prevProps: $ReadOnly<ViewProps>, prevState: $ReadOnly<ViewState>, snapshot: any) {
        if(this.props.match.params.id!==prevProps.match.params.id) {
            this.refresh();
        }
    }

    componentDidMount(): void {
        this.refresh();
    }

    onAddUser(e: Event) {

    }

    onEdit(e: Event, page?: string) {
        dialogOpen(this, EditOrganizationDialog.link(this.state.data.id)+(page?"#"+page:""), () => {
            console.log("Edit refresh");
            this.refresh();
        });
    }

    onEditImSettings(e: Event) {
        dialogOpen(this, EditInvoiceMonitoringSettingsDialog.link(this.state.data.id), () => {
            console.log("Edit refresh");
            this.refresh();
        });
    }

    handleUserSelect = (row: UserOrganizationInfo) => {
        dialogOpen(this, EditUserDialog.link(row.id), () => this.refresh());
    }

    handlePacketChange = (packet: string) => {
        // console.log("Change packet to: ", packet);
        store.userApi.changePacket(this.props.match.params.id, packet).then(() => {
            this.refresh();
            events.emit(Events.OrganizationChange);
        });
    }

    render() {
        if(!this.state.data) return null;
        const org: OrganizationDetails=this.state.data;
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem to={OrganizationsScreen.url}>{msgs.admin.menuOrganizations}</BreadcrumbItem>
                <BreadcrumbItem active>{org.name}</BreadcrumbItem>
            </Breadcrumb>
            <Card className="organization-header mt-5 blue">
                <Card.Body>
                    <Row>
                        <Col md={6} className="sep-border">
                            <Row>
                                <Col md={12}>
                                    <h3 className="occupy">{org.name}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <label>{msgs.gui.labelNIP}</label>
                                    {org.tax}
                                    {!org.promoCodes?null:<><br/><label>{msgs.gui.labelPromoCode}</label>{org.promoCodes}</>}
                                </Col>
                                <Col md={3}>
                                    <label>{msgs.gui.labelAddress}</label>
                                    {org.address}<br/>
                                    {org.postalCode} {org.city}
                                </Col>
                                <Col md={3}>
                                    <label>{msgs.gui.labelECID}</label>
                                    {org.ecId}
                                </Col>
                                <Col md={3}>
                                    <label>{msgs.admin.labelPacket}</label>
                                    {getLangValue(org.packet?org.packet.name:null)}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={4}>
                                    <div className="icon"><span className="icon-linegraph"/></div>
                                    <label>{msgs.gui.labelVindicationAmount}</label>
                                    {org.vindicationsTotal>0?formatString(msgs.gui.labelItemCount, org.vindicationsInProgress, org.vindicationsTotal):msgs.gui.labelItemCountNone}
                                </Col>
                                <Col md={4} className="sep-border">
                                    <div className="icon"><span className="icon-display"/></div>
                                    <label>{msgs.gui.labelOtherAmount}</label>
                                    {org.ordersTotal>0?formatString(msgs.gui.labelItemCount, org.ordersInProgress, org.ordersTotal):msgs.gui.labelItemCountNone}
                                </Col>
                                <Col md={4} className="text-center">
                                    <Button variant="link" onClick={(e) => this.onEdit(e)}>
                                        <div className="icon"><span className="icon-edit"/></div>
                                        {msgs.gui.titleEditOrganization}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <PageHeader title={org.name} custom>
                <h1> </h1>
                {this.edit?<Button
                    className={"float-right"}
                    onClick={(e) => this.onEdit(e, "users")}
                >{msgs.gui.actionAddUserToOrganization}</Button>:null}
            </PageHeader>
            <NotesMap value={org.notes}/>
            <InvoiceMonitoringInfo settings={org.invoiceMonitoring} onEdit={(e) => this.onEditImSettings(e)}/>

            <div className="subheader">
                <h3>{msgs.gui.titleCompanyUsers}</h3>
            </div>
            <OrganizationUsersTable
                data={store.userApi.queryUsersInOrganizations}
                customFilters={this.usersFilter}
                onRowClick={this.handleUserSelect}
                dataTimestamp={this.state.dataTimestamp}
            />
            <Row>
                <Col md={6}>
                    <div className="subheader">
                        <h3>{msgs.gui.titleCompanyPrices}</h3>
                        {(this.edit && this.state.data.packet)?<Button variant="link" onClick={e => this.onEdit(e, "prices")}>
                            {msgs.gui.actionEdit}
                        </Button>:null}
                    </div>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col md={6}><label>{msgs.gui.labelServiceName}</label></Col>
                                <Col md={6}><label>{msgs.gui.labelPricesType}</label></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {this.state.data.services.map((i: OrderTypeInfo) => <Row
                                key={i.id}
                            >
                                <Col md={6}><label>{getLangValue(i.name)}</label></Col>
                                <Col md={6}><label>{i.imported?[ msgs.admin.pricesImported, " ", <ImportedLink key="l" id={org.id}/> ]:(i.custom?msgs.admin.pricesCustom:msgs.admin.pricesStandard)}</label></Col>
                            </Row>)}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <div className="subheader">
                        <h3>{msgs.gui.labelRightsLevel}
                        </h3>
                        <Button variant="link" onClick={() => this.setState({ changePacket: true })}>
                            {msgs.gui.actionEdit}
                        </Button>
                    </div>
                    <Card>
                        <Card.Header>
                            <label>{msgs.gui.labelPacket}</label>
                            <h3>{this.state.data.packet?getLangValue(this.state.data.packet.name):null}</h3>
                            <Row>
                                <Col md={6}><label>{msgs.gui.labelService}</label></Col>
                                <Col md={6}><label>{msgs.gui.labelRightsLevel}</label></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {this.state.data.services.map((i: OrderTypeInfo) => <Row
                                key={i.id}
                            >
                                <Col md={6}><label>{getLangValue(i.name)}</label></Col>
                                <Col md={6}><label>{msgs.admin.labelAvailable}</label></Col>
                            </Row>)}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {this.state.changePacket?<SelectPacketDialog
                onHide={() => this.setState({ changePacket: false })}
                value={this.state.data.packet?this.state.data.packet.id:null}
                onSelect={(packet) => this.handlePacketChange(packet)}
            />:null}
        </>;
    }
}
ViewOrganizationScreen.contextType=LangContext;

/** Pomocnicze okno do wyboru/zmiany pakietu dla organizacji */
export const SelectPacketDialog = ({ title, value, onSelect, onHide }: {
    title?: string;
    /** Aktualny pakiet */
    value: string|null;
    /** Funkcja wywoływana po wybraniu pakietu i zaakceptowaniu zmiany */
    onSelect: (packetId: string) => void;
    onHide?: () => void;
}) => {
    const [ packets, setPackets ]= useState<Array<PacketInfo>>(null);
    const [ packet, setPacket ] = useState(value);
    useEffect(() => {
        store.getPackets().then(packets => {
            setPackets(packets);
            if(!packet && packets.length>0) setPacket(packets[0].id);
        });
    });
    if(!packets) return null;
    const lang=currentLang.code;
    const sel: PacketInfo=packets.find((i: PacketInfo) => i.id===packet);
    // console.log("Sel: ", sel);

    return <Dialog
        internal
        title={msgs.admin.titleSelectPackage}
        acceptButton={value?msgs.gui.actionChange:msgs.gui.actionActivate}
        onAccept={() => {
            let nv=packet?packet:null;
            if(value!==nv) onSelect(nv);
        }}
        onHide={onHide}
    >
        <Alert variant="warning">
            <div className="icon in-circle">
                <Icon.Info/>
            </div>
            <div
                className="content"
                dangerouslySetInnerHTML={{ __html: msgs.admin.hintPacketInfo }}
            />
        </Alert>
        <BForm.Group>
            <BForm.Label>{msgs.admin.labelPacket}</BForm.Label>
            <BForm.Control
                custom as="select"
                value={packet || ""}
                onChange={(e)=> setPacket(e.target.value)}
            >{packets.map((i: PacketInfo) => <option key={i.id} value={i.id}>{i.name[lang]}</option>)}</BForm.Control>
        </BForm.Group>
        <Alert variant="info" className="mh-5">
            {sel?sel.desc:null}
        </Alert>
    </Dialog>
}


type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState= {
    tab: string;
    value: OrganizationData;
    packets: Array<PacketInfo>;
    insurers: Array<Option>;
    globalDicts: Dictionaries;
    dicts: VindicationDictionaries;
}

function getStatusIndex(status: UOStatus) {
    switch (status) {
        case "Active":
            return 0;
        case "New":
            return 1;
        case "TemporaryBlocked":
        case "Blocked":
            return 2;
        case "Rejected":
            return 3;
        default:
            return 4;
    }
}

export class EditOrganizationDialog extends React.Component<EditProps, EditState> {
    static url = "/edit_organization/:id";
    static link = (id: string) => "/edit_organization/" + id;

    static userRights: Array<UserRight>;

    edit: boolean;
    id: string;

    constructor(props) {
        super(props);
        this.edit = store.hasRight("OrganizationsWrite");
        this.state = {
            value: null,
            packets: null,
            tab: "main",
            insurers: null,
            globalDicts: null,
            dicts: null,
            dataTimestamp: 0,
        }
    }

    componentDidMount(): void {
        let url = this.props.match.params.id;
        let sep = url.indexOf('#');
        const id = sep >= 0 ? url.substr(0, sep) : url;
        this.id=id;

        if (!EditOrganizationDialog.userRights) {
            store.userApi.getUsersRights().then(userRights => {
                EditOrganizationDialog.userRights = userRights;
                this.forceUpdate();
            })
        }

        store.getDictionaries().then((globalDicts: Dictionaries) => this.setState({ insurers: globalDicts.insurers, globalDicts }));
        store.getPackets().then(packets => this.setState({packets}));
        store.userApi.getOrganization(id).then((data: OrganizationData) => {
            sortRights(data.services);
            if(Array.isArray(data.users)) data.users.sort((u1: UserOrganizationData, u2: UserOrganizationData) => {
                let res=getStatusIndex(u1.status)-getStatusIndex(u2.status);
                if(res!==0) return res;
                return u1.name.toLowerCase().localeCompare(u2.name.toLowerCase());
            })
            this.setState({value: data});
        });
        store.userApi.getVindicationDictionaries().then((dicts) => {
            this.setState({ dicts });
        });
        const hash = sep >= 0 ? url.substr(sep + 1) : "";
        // console.log("Hash: ", hash);

        if (hash === "prices") {
            this.setState({tab: "prices"});
        } else if (hash === "users") {
            this.setState({tab: "users"});
        }
    }

    render() {
        if (!this.state.value || !this.state.packets || !EditOrganizationDialog.userRights
            || !this.state.insurers || !this.state.globalDicts || !this.state.dicts) return null;
        const lang = currentLang.code;
        const isNew = this.id === '-';
        return <Form
            initialValues={this.state.value}
            validate={OrganizationDataValidation}
            readonly={!this.edit}
            onSubmit={async (values, helpers) => {
                let res = await store.userApi.saveOrganization(values);
                if (!Form.setError(helpers, res)) {
                    this.props.history.goBack();
                    events.emit(Events.OrganizationChange);
                }
            }}
        >{(formik) => {
            const sel: PacketInfo = this.state.packets.find(p => p.id === formik.values.packet);
            return <Dialog
                title={isNew ? msgs.gui.titleAddOrganization : msgs.gui.titleEditOrganization}
                onAccept={this.edit ? (e) => {
                    formik.handleSubmit(e);
                    return false;
                } : null}
            >
                <Tabs
                    id="edit-organization-tabs"
                    activeKey={this.state.tab}
                    onSelect={(tab) => this.setState({tab})}
                >
                    <Tab eventKey="main" title={msgs.gui.labelOrganizationData}>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="tax">
                                    <Form.Label>{msgs.gui.labelNIP}</Form.Label>
                                    <Form.NIP/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="ecId">
                                    <Form.Label>{msgs.gui.labelECID}</Form.Label>
                                    <Form.PositiveNumber
                                        readonly={!!formik.values.ecId}
                                        step={1} maxLength={18} min={0}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group name="name">
                            <Form.Label>{msgs.gui.labelCompanyName}</Form.Label>
                            <Form.Text maxLength={500}/>
                        </Form.Group>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="postalCode">
                                    <Form.Label>{msgs.gui.labelPostalCode}</Form.Label>
                                    <Form.PostalCode maxLength={20}/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="city">
                                    <Form.Label>{msgs.gui.labelCity}</Form.Label>
                                    <Form.Text maxLength={120}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="address">
                                    <Form.Label>{msgs.gui.labelFullAddress}</Form.Label>
                                    <Form.Text maxLength={500}/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="country">
                                    <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                                    <Form.Country menuPlacement="top"/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group name="status">
                                    <Form.Label>{msgs.gui.labelStatus}</Form.Label>
                                    <Form.StatusSelect/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group name="packet">
                                    <Form.Label>{msgs.admin.labelPacket}</Form.Label>
                                    {isNew ? <Form.Select>
                                        <option value="">{msgs.gui.optionSelect}</option>
                                        {this.state.packets.map((p: PacketInfo) => <option key={p.id} value={p.id}>{p.name[lang]}</option>)}
                                    </Form.Select> : <BForm.Control disabled={true} value={sel ? sel.name[lang] : ""}/>}
                                    <p className="text-muted">{sel ? sel.desc : ""}</p>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group name="insurers">
                            <Form.Label>{msgs.gui.labelInsurerName}</Form.Label>
                            <Form.MultiSelect readonly={true} options={this.state.insurers}/>
                        </Form.Group>
                    </Tab>
                    {isNew ? null : <Tab eventKey="users" title={msgs.gui.labelUsers}>
                        <UserCompanySettings
                            globalDicts={this.state.globalDicts}
                            dicts={this.state.dicts}
                            organizations={formik.values.users}
                            onChange={org => formik.setFieldValue("users", org)}
                            readonly={!this.edit}
                            userRights={EditOrganizationDialog.userRights}
                            companyView admin
                        />
                        <UserSuggest
                            value={this.id}
                            onAddUser={(uId) => {
                                store.userApi.getUserToOrganizationStruct(uId, this.id).then(ud => {
                                    if(formik.values.users.find((i: UserOrganizationData) => i.userId===ud.userId && i.orgId===ud.orgId)) return;   // już jest

                                    formik.setFieldValue("users", [ ...formik.values.users, ud ]);
                                })}}
                        />
                    </Tab>}
                    {isNew || !formik.values.packet || !formik.values.services ? null : <Tab eventKey="prices" title={msgs.gui.labelPrices}>
                        {formik.values.services.map((o: OrderTypeInfo) => <Row
                            key={o.id}
                            className="mb-2"
                        >
                            <Col md={4}>
                                <h5>{getLangValue(o.name)}</h5>
                            </Col>
                            <Col md={8}>
                                <SingleFileInput
                                    download remove readonly={!this.edit} preview={false} simple info
                                    value={o.dbFile}
                                    onChange={(f) => {
                                        formik.setFieldValue("services", arrayUpdate(formik.values.services, {...o, dbFile: f}));
                                    }}
                                />
                            </Col>
                        </Row>)}
                    </Tab>}
                </Tabs>
            </Dialog>
        }}</Form>;
    }
}
EditOrganizationDialog.contextType=LangContext;

type AcceptOrganizationDialogState = {
    packets: Array<PacketInfo>;
    origins: Array<String>;
}

export class AcceptOrganizationDialog extends React.Component<RouteChildrenProps<{ id: string }>, AcceptOrganizationDialogState> {
    static url="/organizations/:id/accept";
    static link=(oid: string) => "/organizations/"+oid+"/accept";

    constructor(props) {
        super(props);
        this.state = {
            packets: null,
            origins: null,
        }
    }

    componentDidMount() {
        store.userApi.getPackets().then(packets => this.setState({ packets }));
        store.getOrganizationOrigins().then(origins => this.setState({ origins }));
    }

    render() {
        if(!this.state.packets || !this.state.origins) return null;
        return <Form
            initialValues={{ packet: "", ecId: "", origin: "", }}
            validate={{
                packet: { notEmpty: true },
                ecId: { notEmpty: true },
                origin: { notEmpty: true },
            }}
            onSubmit={async (values, formik) => {
                let res=await store.userApi.acceptOrganization(this.props.match.params.id,
                    values.ecId,
                    values.packet,
                    values.origin,
                )
                if(!Form.setError(formik, res)) {
                    this.props.history.goBack();
                    events.emit(Events.OrganizationChange);
                }
            }}
        >{(formik)=><Dialog
            title={msgs.admin.titleSelectPackage}
            acceptButton={msgs.gui.actionActivate}
            onAccept={() => {
                if(formik.isSubmitting) return false;
                formik.handleSubmit();
                return false;
            }}
        >
            <IconAlert>{msgs.admin.hintPacketInfo}</IconAlert>
            <Form.Group name="packet">
                <Form.Label>{msgs.admin.labelPacket}</Form.Label>
                <Form.Select>
                    <option value="">{msgs.gui.optionSelect}</option>
                    {this.state.packets.map((i: PacketInfo) => <option key={i.id} value={i.id}>{getLangValue(i.name)}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group name="ecId">
                <Form.Label>{msgs.gui.labelECID}</Form.Label>
                <Form.PositiveNumber type="number" step={1} min={0} maxLength={18}/>
            </Form.Group>
            <Form.Group name="origin">
                <Form.Label>{msgs.admin.labelOrganizationOrigin}</Form.Label>
                <Form.Select>
                    <option value="">{msgs.gui.optionSelect}</option>
                    {this.state.origins.map((i: string, index) => <option key={index} value={i}>{i}</option>)}
                </Form.Select>
            </Form.Group>
        </Dialog>}</Form>;
    }
}

type RejectOrganizationState = {
    reasons: Array<RejectionReasonInfo>|null;
    reason: string|null;
}
//
// export class RejectOrganizationDialog extends React.Component<{ id: string}, RejectOrganizationState> {
//     constructor(props) {
//         super(props);
//         this.state = {
//             reasons: null,
//         }
//     }
//
//     componentDidMount() {
//         store.getRejectionReasons().then(reasons => this.setState({ reasons }));
//     }
//
//     handleAccept = () => {
//
//     }
//
//     render() {
//         if(!this.state.reasons) return null;
//
//         return (
//             <Dialog
//                 acceptButton={msgs.gui.actionReject}
//                 onAccept={this.state.reason?this.handleAccept:false}
//             >
//
//             </Dialog>
//         );
//     }
//
// }