//@flow
import React from 'react';
import {Breadcrumb, Button, Nav} from "react-bootstrap";
import HomeScreen from "./HomeScreen";
import msgs, {getLangValue, LangContext} from "../lib/Language";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import type {FieldFilter, OrderInfo, OrderStatus, OrderTypeInfo} from "../api";
import {RouteChildrenProps, RouteComponentProps} from "react-router";
import {Events, events, store} from "../application";
import Error404 from "../auth/Error404";
import type {DataTableColumn, DataTableRowSelection} from "../lib/DataTable";
import DataTable from "../lib/DataTable";
import {formatDate} from "../lib/DateTime";
import {getLocationState, replaceHistoryState} from "../lib/Utils";

type State = {
    order: OrderTypeInfo;
    dataTimestamp: number;
    tabStatus: OrderStatus;
    statusFilter: Array<FieldFilter>;
    selected: DataTableRowSelection;
    selectedAmount: number;
}

/**
 * Ekran z listą innych usług
 */
export default class OrdersScreen extends React.Component<RouteChildrenProps<{ ident: string }>, State> {
    static url="/order/:ident";
    static link=(type: OrderTypeInfo) => "/order/"+type.ident;

    columns: Array<DataTableColumn<OrderInfo>>;
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("OrdersWrite");
        const order: OrderTypeInfo=store.getOtherOrders().find(o => o.ident===this.props.match.params.ident);

        const tab=getLocationState(this.props.location, 'tab') || "Ordered";

        this.state={
            dataTimestamp: Date.now(),
            tabStatus: tab,
            statusFilter: [{ field: 'status', value: tab }, { field: "type", value: order.id }],
            order,
            selected: null,
            selectedAmount: 0,
        }
        this.updateView(tab);
    }

    refresh() {
        this.setState({ dataTimestamp: Date.now() })
    }


    handleOrderAccept(e: SyntheticEvent, order: OrderInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.orderAccept(order.id).then(() => {
            this.refresh();
            events.emit(Events.OrderChange);
        });
    }

    handleOrderReject(e: SyntheticEvent, order: OrderInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.orderReject(order.id).then(() => {
            this.refresh();
            events.emit(Events.OrderChange);
        });
    }

    handleOrderDone(e: SyntheticEvent, order: OrderInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.orderMarkAsDone(order.id).then(() => {
            this.refresh();
            events.emit(Events.OrderChange);
        });
    }

    updateColumns(actions: (row: OrderInfo) => React$Node) {
        this.columns = [
            {
                accessor: 'id',
                className: 'id',
                Header: msgs.gui.labelID,
                filter: 'text',
            }, {
                accessor: "client",
                className: "half",
                Header: msgs.gui.labelCustomer,
                filter: 'text',
                Cell: ({ row }) => <>
                    <h1>{row.original.clientName}</h1>
                    <span className="d-block">{row.original.clientTax}</span>
                    <span>{row.original.clientFirstName} {row.original.clientLastName}</span>
                </>
            }, {
                accessor: "target",
                className: 'half',
                Header: msgs.admin.labelOrderTarget,
                filter: 'text',
                Cell: ({ row }) => <>
                    <h1>{row.original.name}</h1>
                    <span>{row.original.tax}</span>
                </>
            }, {
                accessor: 'added',
                className: 'date',
                Header: msgs.gui.labelOrderDate,
                Cell: ({ value }) => formatDate(value),
                filter: 'date',
            },
        ]
        if(this.edit && actions) {
            this.columns.push({
                id: "actions",
                Header: msgs.gui.labelActions,
                Cell: ({row}) => actions(row.original),
                className: "actions2",
            });
        }
    }

    displayWaiting() {
        this.updateColumns((row) => <>
            <Button
                variant="success"
                onClick={(e) => this.handleOrderAccept(e, row)}
            >{msgs.gui.actionActivate}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleOrderReject(e, row)}
        >{msgs.gui.actionReject}</Button>
        </>)
    }

    displayActive() {
        this.updateColumns((row) => <>
            <Button
                variant="success"
                onClick={(e) => this.handleOrderDone(e, row)}
            >{msgs.gui.actionFinish}</Button>
        </>);
    }

    displayDone() {
        this.updateColumns(null);
    }

    displayRejected() {
        this.updateColumns(null);
    }

    componentDidUpdate(prevProps: $ReadOnly<RouteComponentProps>, prevState: $ReadOnly<State>, snapshot: any) {
        if(this.props.match.params.ident!==prevProps.match.params.ident) {
            // console.log("Change params: ", this.props.match.params.ident);
            const order: OrderTypeInfo=store.getOtherOrders().find(o => o.ident===this.props.match.params.ident);
            this.setState({
                order,
                tabStatus: 'Ordered',
                statusFilter: [{ field: 'status', value: 'Ordered' }, { field: "type", value: order.id }],
                dataTimestamp: Date.now(),
            });
        }
        return true;
    }

    updateView(status: OrderStatus) {
        switch(status) {
            case "Ordered":
                this.displayWaiting();
                break;
            case "InProgress":
                this.displayActive();
                break;
            case "Rejected":
                this.displayRejected();
                break;
            case "Done":
                this.displayDone();
                break;
        }

    }

    shouldComponentUpdate(nextProps: $ReadOnly<Props>, nextState: $ReadOnly<State>, nextContext: any): boolean {
        if(this.state.tabStatus!==nextState.tabStatus) {
            this.updateView(nextState.tabStatus);
        }
        return true;
    }

    handleSelectionChange=(selected: DataTableRowSelection) => {
        this.setState({
            selected: selected,
            selectedAmount: Object.keys(selected).length
        })
    }

    handleRejectSelected=() => {
        store.userApi.multipleOrderReject(Object.keys(this.state.selected)).then(res => {
            this.refresh();
            events.emit(Events.OrderChange);
        })
    }

    handleAcceptSelected=() => {
        store.userApi.multipleOrderAccept(Object.keys(this.state.selected)).then(res => {
            this.refresh();
            events.emit(Events.OrderChange);
        })
    }

    renderSelectedActions() {
        if(this.state.tabStatus!=="Ordered" || this.state.selectedAmount===0) return null;
        return <>
            <Button variant="link" onClick={this.handleRejectSelected}>{msgs.admin.actionRejectSelected}</Button>
            <Button variant="link" onClick={this.handleAcceptSelected}>{msgs.admin.actionAcceptSelected}</Button>
        </>;
    }


    render() {
        if(!this.state.order) return <Error404/>;
        const name=getLangValue(this.state.order.name);

        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuOrders}</BreadcrumbItem>
                <BreadcrumbItem active>{name}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={name}/>

            <Nav
                variant="tabs"
                activeKey={this.state.tabStatus}
                onSelect={(key) => {
                    this.setState({
                        tabStatus: key,
                        statusFilter: [{ field: 'status', value: key }, { field: "type", value: this.state.order.id }],
                        dataTimestamp: Date.now(),
                    })
                    replaceHistoryState(this.props.history, this.props.location, 'tab', key);
                }}
            >
                <Nav.Item><Nav.Link eventKey="Ordered">{msgs.gui.statusOrdered}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="InProgress">{msgs.gui.statusInProgress}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Rejected">{msgs.gui.statusRejected}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Done">{msgs.gui.statusDone}</Nav.Link></Nav.Item>
            </Nav>
            <div className="tab-content">
                <DataTable
                    className="datatable-orders"
                    columns={this.columns}
                    onSelectionChange={this.state.tabStatus==="Ordered"?this.handleSelectionChange:null}
                    data={store.userApi.queryOrders}
                    initialSortBy="-added"
                    customFilters={this.state.statusFilter}
                    dataTimestamp={this.state.dataTimestamp}
                    historyState={"table_"+this.state.tabStatus}
                    key={this.state.tabStatus}
                />
                {this.renderSelectedActions()}
            </div>

        </>;
    }
}
OrdersScreen.contextType=LangContext;