//@flow
import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {Container} from "react-bootstrap";
import {Footer, Icon} from "../lib/Components";
import {langLink, langPath, SwitchLanguage} from "../lib/Language";
import LoginScreen from "./LoginScreen";
import ResetPasswordScreen, {ResetEmailSendScreen} from "./ResetPasswordScreen";
import PasswordChangeScreen, {PasswordChangedScreen} from "./PasswordChangeScreen";
import {store} from "../application";

const LoginRedirect=() => {
    const location=useLocation();

    console.log("Storing return url: ", location);
    store.setReturnPage({
        pathname: location.pathname,
        state: location.state
    });
    // history.replace(langLink(LoginScreen.url));

    return <Redirect to={langLink(LoginScreen.url)}/>;
}

/** Główna część aplikacji logowania */
const LoginApp= () => {
    useEffect(() => {
        document.body.classList.add("auth");
        return () => {
            document.body.classList.remove("auth");
        }
    });
    return <>
        <main>
            <Container>
                <header>
                    <a href="/">
                        <Icon.Logo/>
                    </a>
                    <SwitchLanguage/>
                </header>
                <Switch>
                    <Route path={langPath(LoginScreen.url)} exact={true} component={LoginScreen}/>

                    <Route path={langPath(ResetPasswordScreen.url)}  exact={true} component={ResetPasswordScreen}/>
                    <Route path={langPath(ResetEmailSendScreen.url)} exact={true} component={ResetEmailSendScreen}/>

                    <Route path={langPath(PasswordChangeScreen.url)} component={PasswordChangeScreen}/>
                    <Route path={langPath(PasswordChangedScreen.url)} exact={true} component={PasswordChangedScreen}/>
                    {/*<Route path={loggedPages} exact><LoginRedirect/></Route>*/}
                    {/*<Route component={Error404}/>*/}

                    <Route><LoginRedirect/></Route>
                </Switch>
            </Container>
        </main>
        <Footer simple/>
    </>;
}

export default LoginApp;

