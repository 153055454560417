//@flow
/* eslint-disable no-use-before-define */
import React, {useMemo} from 'react';
import {RouteChildrenProps, RouteComponentProps} from "react-router";
import type {DifficultDebtFile, DifficultDebtInfo} from "../api";
import type {LangInfo} from "../lib/Language";
import msgs, {formatString, LangContext, LanguagesOrdered, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Col, Form, InputGroup, Nav, ProgressBar} from "react-bootstrap";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, Icon, PageHeader} from "../lib/Components";
import {formatDateTime} from "../lib/DateTime";
import HomeScreen from "./HomeScreen";
import {CellProps} from "react-table";
import {Events, events, store} from "../application";
import type {AllDataTableColumn, ClientDataTableProps} from "../lib/DataTable";
import {ClientDataTable} from "../lib/DataTable";
import Dropzone from "react-dropzone";
import type {UploadInfo} from "../lib/Upload";
import {deleteUpload, getDownloadLink, mimeTypeToIcon, upload} from "../lib/Upload";

const DifficultDebtsTable = ({ onDelete, onDownload, ...props}: $Diff<ClientDataTableProps, { columns: any}> & { onDelete: () => void, onDownload: () => void } ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<AllDataTableColumn<DifficultDebtInfo>>>(() => [
        {
            Header: '',
            accessor: 'download',
            className: "delete",
            Cell: ({ row }: CellProps<DifficultDebtInfo>) => <Button
                onClick={(e) => onDownload(e, row.original)}
            ><Icon icon="download"/></Button>,

        },
        {
            Header: msgs.gui.labelFileName,
            id: 'name',
            accessor: (row: DifficultDebtInfo) => (row.name?row.name:"")+"\n"+row.fileName,
            filter: 'text',
            Cell: ({ row }: CellProps<DifficultDebtInfo>) => <>
                {row.original.name?row.original.name:row.original.fileName}
                <div className="small text-muted">{row.original.fileName}</div>
            </>,
        },
        {
            Header: msgs.gui.labelFileAdded,
            accessor: 'added',
            filter: 'date',
            className: "datetime",
            Cell: ({ value }) => formatDateTime(value),
        }, {
            Header: '',
            accessor: 'id',
            className: "delete",
            Cell: ({ row }: CellProps<DifficultDebtInfo>) => onDelete?<DeleteButton onClick={(e) => onDelete(e, row.original)} />:null,
        }
    ], [msgs.gui.language, onDelete ]);
    return <ClientDataTable columns={columns} {...props}/>
}

type State= {
    lang: string;
    data: Array<DifficultDebtInfo>|null;
    selected: Array<string>;
}

const dataPrefix='data_';

export default class DifficultDebtsScreen extends React.Component<RouteChildrenProps, State> {
    static url="/difficult_debts";
    edit: boolean;

    constructor(props) {
        super(props);
        this.state={
            lang: LanguagesOrdered[0].code,
            data: null,
            selected: [],
        }
        this.edit=store.hasRight('DifficultDebtsWrite');
    }

    refresh() {
        store.userApi.getAllDifficultDebts().then((data: Array<DifficultDebtInfo>) => {
            let ns={ data };
            LanguagesOrdered.forEach((l: LangInfo)=> {
                ns[dataPrefix+l.code]=data.filter((i: DifficultDebtInfo) => i.lang===l.code);
            })
            this.setState(ns);
        })
    }
    
    componentDidMount(): void {
        this.refresh();
    }


    onAdd(e: Event) {
        e.preventDefault();
        dialogOpen(this, AddDifficultDebtDialog.link(this.state.lang), () => this.refresh());
    }

    onDownload = (e: SyntheticEvent, f: DifficultDebtInfo) => {
        e.stopPropagation();
        e.preventDefault();
        window.location.href=getDownloadLink(f.fId, f.name);
    }

    onDelete = (e: SyntheticEvent, f: DifficultDebtInfo) => {
        e.stopPropagation();
        e.preventDefault();
        store.userApi.deleteDifficultDebtFile(f.id).then(() => this.refresh());
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuDifficultDebts}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuDifficultDebts}>
                {this.edit?<Button onClick={(e) => this.onAdd(e)}>{msgs.admin.actionAddDifficultDebt}</Button>:null}
            </PageHeader>
            <Nav
                variant="tabs"
                defaultActiveKey={LanguagesOrdered[0].code}
                activeKey={this.state.lang}
                onSelect={(e) => this.setState({ lang: e })}
            >
                {LanguagesOrdered.map((l: LangInfo) => (
                    <Nav.Item key={l.code}>
                        <Nav.Link eventKey={l.code}>
                            {formatString(msgs.gui.labelVersionLang, l.code)}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <div className="tab-content">
                <DifficultDebtsTable
                    onDelete={this.edit?this.onDelete:null}
                    onDownload={this.onDownload}
                    data={this.state[dataPrefix+this.state.lang]}
                    key={this.state.lang}
                    historyState={"table_"+this.state.lang}
                />
            </div>
        </>;
    }
}
DifficultDebtsScreen.contextType=LangContext;

type AddFileInfo = {
    upload: UploadInfo;
    name: string;
}

type AddState = {
    files: Array<AddFileInfo>;
}

export class AddDifficultDebtDialog extends React.Component<RouteComponentProps<{ lang: string }>, AddState> {
    static url="/difficult_debt/:lang";
    static link=(lang: string) => "/difficult_debt/"+lang;

    static idGen=0;

    constructor(props) {
        super(props);
        this.state={
            files: []
        }
    }

    handleOnDrop = (files: FileList) => {
        for(let i=0;i<files.length;++i) {
            const f=files[i];
            upload(f, String(++AddDifficultDebtDialog.idGen), (info: UploadInfo) => {
                if(info.state==="New") {
                    this.setState({ files: [...this.state.files, { upload: info, name: "" } ] });
                } else {
                    this.setState({
                        files: this.state.files.map((i: AddFileInfo) => {
                            if(i.upload.id===info.id) return { upload: info, name: i.name };
                            return i;
                        })
                    })
                }
            }).finally();
        }
    }

    onAccept = () => {
        return new Promise((resolve, reject) => {
            for(let i=0;i<this.state.files.length;++i) {
                const st=this.state.files[i].upload.state;
                if(st==="Uploading" || st==="New") return false;  // jeżeli jest w trackie przesyłania, to nie możemy zamknąć okna
            }

            let files: Array<DifficultDebtFile>=this.state.files
                .filter((f: AddFileInfo) => f.upload.state==="Done")
                .map((f: AddFileInfo) => ({
                    dbId: f.upload.result.id,
                    customName: f.name
                }))
            ;
            store.userApi.addDifficultDebtFiles(this.props.match.params.lang, files).then(() => {
                resolve();
                events.emit(Events.DifficultDebtChange, this.props.match.params.lang)
            }).catch(reject);
        });
    }

    onCancel() {
        for(let i=0;i<this.state.files.length;++i) {
            const up: UploadInfo=this.state.files[i].upload;
            if(up.state==="Uploading" || up.state==="New") {
                up.cancel();
            } else {
                deleteUpload(up.result).finally();
            }
        }

    }

    renderItem(f: AddFileInfo, index: number) {
        return <Form.Row key={f.upload.id}>
            <Col className="preview">
                <i className={f.upload.result?mimeTypeToIcon(f.upload.result.mimeType):"fas fa-spinner"}/>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>{f.upload.filename}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder={msgs.gui.labelNameThisFile}
                            value={f.name}
                            maxLength={500}
                            onChange={(e) => {
                                this.setState({
                                    files: this.state.files.map((i: AddFileInfo, mi) => {
                                        if(index===mi) return { name: e.target.value, upload: i.upload };
                                        return i;
                                    })
                                })
                            }}
                        />
                        <InputGroup.Append>
                            <DeleteButton
                                disabled={!f.upload.result}
                                onClick={() => {
                                    this.setState({
                                        files: this.state.files.filter((i: AddFileInfo, mi) => index!==mi)
                                    }, () => {
                                        deleteUpload(f.upload.result).finally();
                                    });
                                }}
                            />
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <ProgressBar
                    variant={!f.upload.result?"primary":"success"}
                    now={f.upload.progress}
                    max={100}
                />
            </Col>
        </Form.Row>;
    }

    render() {
        return <Dialog
            title={formatString(msgs.gui.titleAddFileLang, this.props.match.params.lang)}
            onAccept={this.onAccept}
            onCancel={() => this.onCancel()}
        >
            <Dropzone
                onDrop={this.handleOnDrop}
                noClick={true}
            >{({ getRootProps, getInputProps, open, isDragActive }) => (
                <div {...getRootProps({ className: "dropzone "+(isDragActive?"active":"") })}>
                    <input {...getInputProps()}/>
                    <Button size="xl" variant="outline-secondary" onClick={(e: SyntheticEvent) => { e.stopPropagation(); open(); }}
                    >{msgs.gui.actionAddFile}</Button>
                    <p dangerouslySetInnerHTML={{ __html: msgs.gui.hintUpload }}/>
                </div>
            )}</Dropzone>
            <div className="files">
                {this.state.files.map((f: AddFileInfo, index) => this.renderItem(f, index))}
            </div>
        </Dialog>;
    }
}
AddDifficultDebtDialog.contextType=LangContext;

type EditState = {
    name: string;
}

export class EditDifficultDebtDialog extends React.Component<RouteChildrenProps<{ lang: string, id: string }>, EditState> {
    static url="/difficult_debt/:lang/:id";
    static link=(lang: string, id: string) => "/difficult_debt/"+lang+"/"+id;

    constructor(props) {
        super(props);
        this.state={
            name: '',
        }
    }

    onAccept() {
    }

    render() {
        const title=formatString(msgs.gui.titleAddFileLang, this.props.match.params.lang)
        return <Dialog
            title={title}
            onAccept={() => this.onAccept()}
        >
            <Form.Control
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
            />
            </Dialog>;
    }
}
EditDifficultDebtDialog.contextType=LangContext;