//@flow
import React, {Ref} from 'react';
import {emptyLangString, LanguagePanel, RichEditor} from "../AdminComponents";
import type {LangInfo, Language} from "../lib/Language";
import msgs, {defaultLanguage, LangContext, langLink, LanguagesOrdered} from "../lib/Language";
import type {TextDetails, TextInfo} from "../api";
import {RouteChildrenProps} from "react-router";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {formatDate} from "../lib/DateTime";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import { AllDataTable } from "../lib/DataTable";
import {BreadcrumbItem, PageHeader, SaveButton} from "../lib/Components";
import type {SaveButtonState} from "../lib/Components";

export default class TextPagesScreen extends React.Component<RouteChildrenProps, void> {
    static url="/text_pages";

    columns: Array<AllDataTableColumn<TextInfo>>;

    constructor(props) {
        super(props);
        // Kolumny są tu, aby nie tworzył się nowy obiekt za każdym razem
        this.columns=[
            {
                accessor: "name",
                Header: msgs.gui.labelID,
                filter: "text",
                className: "nd"
            },
            ...LanguagesOrdered.map((l: LangInfo) => ({
                id: l.code,
                accessor: (row) => row.title[l.code],
                Header: msgs.gui.labelTitle+' '+l.code,
                filter: 'text',
                className: "lang-part",
            })), {
                accessor: 'modified',
                Header: msgs.gui.labelChangeTime,
                Cell: ({ value }) => formatDate(value),
                filter: 'date',
                className: "date",
            }
        ];
    }

    onSelect(row: TextInfo) {
        this.props.history.push(langLink(EditTextPageScreen.link(row.name)));
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuTextPages}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuTextPages}/>
            <AllDataTable
                columns={this.columns}
                data={store.userApi.getTextPages}
                onRowClick={(r) => this.onSelect(r)}
                historyState="table"
            />
        </>;
    }
}
TextPagesScreen.contextType=LangContext;

type EditProps =RouteChildrenProps<{ name: string }> &{

}

type EditState = TextDetails & {
    /** Czy wczytano */
    loaded: boolean;
    lang: Language;

    saveState: SaveButtonState;
}

export class EditTextPageScreen extends React.Component<EditProps, EditState> {
    static url="/text_page/:name";
    static link = (name: string) => "/text_page/"+encodeURI(name);

    edit: boolean;
    editor: Ref<RichEditor>;

    constructor(props) {
        super(props);
        this.state={
            loaded: false,
            lang: defaultLanguage,

            title: emptyLangString(),
            message: emptyLangString(),
            variables: {},
            saveState: null,
        }
        this.editor=React.createRef();
        this.edit=store.hasRight("PagesEdit");
    }

    componentDidMount(): void {
        store.userApi.getTextPageDetails(this.props.match.params.name).then((details) => {
            this.setState({ loaded: true, ...details });
        });
    }

    onSave(_: Event) {
        this.setState({ saveState: "loading" })
        store.userApi.saveTextPage(this.props.match.params.name, this.state.title, this.state.content).then(() => {
            events.emit(Events.TextPageChange);
            this.setState({ saveState: "done" })
        }).catch(() => this.setState({ saveState: "error" }));
    }

    render() {
        if(!this.state.loaded) return null;
        const variables=Object.entries(this.state.variables).sort((i1, i2) => i1[0].localeCompare(i2[0]));
        const lang= this.state.lang;
        const title=this.props.match.params.name;

        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem to={TextPagesScreen.url}>{msgs.admin.menuTextPages}</BreadcrumbItem>
                <BreadcrumbItem active>{title}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={title}/>
            <Form>
                <p className="text-info">{this.state.desc}.</p>
                <LanguagePanel id="langName" value={this.state.lang} onChange={lang => this.setState({ lang })}/>
                <div className="tab-content">
                    <Form.Group controlId="title" className="w-100">
                        <Form.Control
                            type="text"
                            disabled={!this.edit}
                            placeholder={msgs.gui.labelMessageTitle}
                            value={this.state.title[lang]}
                            onChange={(e) => this.setState({ title: { ...this.state.title, [this.state.lang]: e.target.value  }})}
                            maxLength={1024}
                        />
                    </Form.Group>
                    <Form.Group className="w-100">
                        <RichEditor
                            key={lang}
                            readonly={!this.edit}
                            ref={this.editor}
                            value={this.state.content[lang]}
                            onChange={(v) => {
                                this.setState({ content: { ...this.state.content, [this.state.lang]: v }})
                            } }
                        />
                    </Form.Group>
                    <Form.Group className="text-center">
                        <SaveButton
                            disabled={!this.edit}
                            onClick={(e) => this.onSave(e)}
                            state={this.state.saveState}
                        >{msgs.gui.actionSave}</SaveButton>
                    </Form.Group>
                    {variables.length>0?<>
                        <p className="text-info" dangerouslySetInnerHTML={{ __html: msgs.admin.hintFreeMarkerVariable }}/>
                        <p className="text-info">{msgs.admin.labelVariables}:</p>
                        <ul className="text-info">
                            {variables.map(([k, v]) =>
                                <li
                                    key={k}
                                    className={"clickable"}
                                    onClick={() => this.editor.current.insertText("${"+k+"}")}
                                >
                                    <code>{"${"+k+"}"}</code> - {v}
                                </li>
                            )}
                        </ul>
                    </>:null}
                </div>
            </Form>
        </>;
    }
}
EditTextPageScreen.contextType=LangContext;