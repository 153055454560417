//@flow
// Plik z ekranami związanymi z edycją treści powiadomień dla modułu monitoringu faktur.
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Breadcrumb, Button} from "react-bootstrap";
import {BreadcrumbItem, PageHeader, SaveButton} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import msgs, {langLink, useMsgs} from "../lib/Language";
import {AllDataTable} from "../lib/DataTable";
import {store} from "../application";
import {formatDate} from "../lib/DateTime";
import {useHistory, useParams} from "react-router-dom";
import type {MonitoringTemplateDetails, MonitoringTemplateInfo} from "../api";
import {BForm, Form} from "../lib/Form";
import {RichEditor, SMSInput} from "../AdminComponents";
import {FormikProps, useFormikContext} from "formik";
import type {SaveButtonState} from "../lib/Components";
import type {DataTableColumn} from "../lib/DataTable";
import {useRpcStaticQuery} from "../lib/QueryUtils";

const styleValues=[ "BeforePayment", "SlightlyOutOfDate", "Expired", "HeavilyOverdue" ];

const StyleSelect = (props) => {
    const msgs=useMsgs();
    return <Form.Select {...props}>
        <option>{msgs.gui.optionSelect}</option>
        {styleValues.map(v => <option key={v} value={v}>{msgs.gui[`iss${v}`]}</option>)}
    </Form.Select>
}

/**
 * Komponent dla ekranu z listą treści.
 */
const MonitoryNotesScreen = () => {
    const msgs = useMsgs();
    const columns=useMemo<Array<DataTableColumn>>(() => [ {
        accessor: "type",
        Header: msgs.gui.labelType,
        className: "sm",
        filter: "text",
        Cell: ({ value }) => value==="isms"?"SMS":"E-mail"
    }, {
        accessor: "name",
        Header: msgs.gui.labelID,
        className: "md",
        filter: "text",
    }, {
        accessor: "style",
        Header: msgs.gui.labelCommunicationStyle,
        className: "md",
        filter: "select",
        options: styleValues.map(v => ({ value: v, label: msgs.gui[`iss${v}`] })),
        Cell: ({ value }) => value && msgs.gui[`iss${value}`],
    }, {
        accessor: "title",
        Header: msgs.gui.labelTitle,
        filter: 'text',
        className: "lang-part"
    }, {
        accessor: 'changed',
        Header: msgs.gui.labelChangeTime,
        Cell: ({ value }) => formatDate(value),
        filter: 'date',
        className: "date"
    } ], [ msgs ]);
    const history=useHistory();
    const handleClick=useCallback((row: MonitoringTemplateInfo) => {
        history.push(langLink(MonitoryNoteScreen.link(row.id)));
    }, [ history ]);
    useEffect(() => {
        if(!store.hasRight("MonitoringEditNotification")) history.replace("/");
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.admin.menuNotificationTemplates}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.admin.menuNotificationTemplates}>
            <Button onClick={e => history.push(MonitoryNoteScreen.link("isms/-"))}>{msgs.admin.buttonAddSMSTemplate}</Button>
            <Button onClick={e => history.push(MonitoryNoteScreen.link("iemail/-"))}>{msgs.admin.buttonAddEmailTemplate}</Button>
        </PageHeader>
        <AllDataTable
            columns={columns}
            data={store.userApi.getNotificationsTemplates}
            onRowClick={handleClick}
            historyState="notes-table"
        /></>

}
MonitoryNotesScreen.url="/monitory_note";
MonitoryNotesScreen.link=() => MonitoryNotesScreen.url;
export default MonitoryNotesScreen;

function parseId(id: string): [ string|null, string|null ] {
    if(typeof(id)!=='string') return [ null, null ];
    const i=id.indexOf('/');
    if(i===-1) return null;
    let cat=id.substring(0, i);
    let name=id.substring(i+1)
    if(cat!=="isms" && cat!=="iemail") return [ null, null ];
    return [ cat, name==="-"?null:name ];
}

const variablesMapping = [
    [ "DATA", "date"],
    [ "LOGO", "logo"],
    [ "LOGO_EN", "logo"],
    [ "LOGO_ORGANIZACJI", "logo"],

    [ "NAZWA_ORGANIZACJI", "orgName"],
    [ "NIP_ORGANIZACJI", "orgTax"],
    [ "ADRES_ORGANIZACJI", "orgAddress"],
    [ "KOD_POCZTOWY_ORGANIZACJI", "orgPostalCode"],
    [ "MIASTO_ORGANIZACJI", "orgCity"],
    [ "MNB_TELEFON_ORGANIZACJI", "orgPhone"],
    [ "MNB_TELEFON_ORGANIZACJI_EN", "orgPhone"],

    [ "MNB_EMAIL_ORGANIZACJI", "orgEmail"],

    [ "NAZWA_KONTRAHENTA", "counterpartyName"],
    [ "NIP_KONTRAHENTA", "counterpartyTax"],
    [ "ADRES_KONTRAHENTA", "counterpartyAddress"],
    [ "KOD_POCZTOWY_KONTRAHENTA", "counterpartyPostalCode"],
    [ "MIASTO_KONTRAHENTA", "counterpartyCity"],
    [ "TELEFON_KONTRAHENTA", "counterpartyPhone"],
    [ "EMAIL_KONTRAHENTA", "counterpartyEmail"],

    [ "SALDO_KONTRAHENTA", "balance"],
    [ "SALDO_SLOWNIE_KONTRAHENTA", "balanceInWordsPl"],
    [ "SALDO_SLOWNIE_EN_KONTRAHENTA", "balanceInWordsEn"],

    [ "SALDO_KONTRAHENTA_PO_TERMINIE", "balanceOverdue"],
    [ "SALDO_SLOWNIE_KONTRAHENTA_PO_TERMINIE", "balanceOverdueInWordsPl"],
    [ "SALDO_SLOWNIE_KONTRAHENTA_PO_TERMINIE_EN", "balanceOverdueInWordsEn"],

    [ "SALDO_KONTRAHENTA_PRZED_TERMINEM", "balanceBefore"],
    [ "SALDO_SLOWNIE_KONTRAHENTA_PRZED_TERMINEM", "balanceBeforeInWordsPl"],
    [ "SALDO_SLOWNIE_KONTRAHENTA_PRZED_TERMINEM_EN", "balanceBeforeInWordsEn"],

    [ "TABELA_FAKTUR_KONTRAHENTA", "invoicesPl"],
    [ "TABELA_FAKTUR_KONTRAHENTA_EN", "invoicesEn"],
    [ "TABELA_FAKTUR_KONTRAHENTA_PO_TERMINIE", "invoicesOverduePl"],
    [ "TABELA_FAKTUR_KONTRAHENTA_PO_TERMINIE_EN", "invoicesOverdueEn"],
    [ "TABELA_FAKTUR_KONTRAHENTA_PRZED_TERMINEM", "invoicesBeforePl"],
    [ "TABELA_FAKTUR_KONTRAHENTA_PRZED_TERMINEM_EN", "invoicesBeforeEn"],
    [ "DATA_NAJSTARSZEJ_FAKTURY", "invoiceLatestDate"],
];

/**
 * Funkcja pomocnicza, która mapuje ze starego systemu na nowy.
 * @param val
 */
function replaceOldNew(val: string): string {
    if(val===null || val.length===0) return val;
    for(const [ f, t ] of variablesMapping) {
        val=val.replaceAll('['+f+']', '${'+t+'}');
    }
    return val;
}

/**
 * Komponent podmieniający zmienne
 * @constructor
 */
const ReplaceVariables = () => {
    const msgs=useMsgs();
    const ctx=useFormikContext();
    return <Button onClick={() => {
        const newTitle=replaceOldNew(ctx.values.title);
        const newContent=replaceOldNew(ctx.values.content);
        if(newTitle!==ctx.values.title) ctx.setFieldValue('title', newTitle);
        if(newContent!==ctx.values.content) ctx.setFieldValue('content', newContent);
    }}>{msgs.admin.buttonReplaceVariable}</Button>
}

/**
 * Komponent dla widoku/edycji treści pojedynczego powiadomienia.
 */
export const MonitoryNoteScreen =() => {
    const {id} = useParams();
    const dId=decodeURIComponent(id);
    const [cat, name] = parseId(dId);
    const msgs = useMsgs();
    const [note, setNote] = useState<MonitoringTemplateDetails | null>(null);
    const history = useHistory();
    const [ saveState, setSaveState ] = useState<SaveButtonState>();
    const editor: React$Ref<RichEditor>=useRef<RichEditor>(null);
    const smsInput: React$Ref<HTMLInputElement>=useRef(null);
    useEffect(() => {
        if (cat && name) {
            store.userApi.getNotificationTemplate(dId).then(t => setNote(t));
        } else if (cat && !name) {
            setNote({
                type: cat,
                style: "",
                name: "",
                title: "",
                content: ""
            });
        } else {
            history.replace(langLink("/"));
        }

    }, [dId, cat, name, history]);
    const variablesQuery=useRpcStaticQuery("/user", "getNotificationTemplateVariables");

    const handleDelete=useCallback(() => {
        store.userApi.deleteNotificationTemplate(dId).then((res) => {
            if(!res) {
                window.alert(msgs.admin.deleteErrorNotificationTemplateInUse);
                return;
            }
            history.replace(langLink(MonitoryNotesScreen.link()));
        }).catch(err => {

        });
    }, [ dId ]);

    const title = name || msgs.admin.labelNewTemplate;
    const isSms=cat==="isms";
    const edit=true;

    const variables = variablesQuery.data?(isSms?variablesQuery.data.sms:variablesQuery.data.email):null;
    console.log("Variables: ", variables);

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem to={MonitoryNotesScreen.url}>{msgs.admin.menuNotificationTemplates}</BreadcrumbItem>
            <BreadcrumbItem active>{title}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={title}>
            {name && <Button
                variant="danger"
                onClick={handleDelete}
                disabled={note && note.used}
                title={note && note.used && msgs.admin.deleteErrorNotificationTemplateInUse}
            >{msgs.gui.actionDelete}</Button>}
        </PageHeader>
        <div className="tab-content">
            {note && <Form
                initialValues={note}
                validate={(values) => {
                    let err={};
                    if(!name) {
                        if(!values.name) err.name="validation.required";
                    }
                    if(values.content.trim().length===0) {
                        err.content="validation.required";
                    }
                    if(!isSms && values.title.trim().length===0) {
                        err.title="validation.required";
                    }
                    if(!values.style) {
                        err.style="validation.required";
                    }

                    return err;
                }}
                onSubmit={(values, helpers) => {
                    setSaveState("loading");
                    store.userApi.updateNotificationTemplate(
                        name?dId:(cat+"/"+values.name),
                        values.style,
                        isSms?values.content:values.title,
                        values.content
                    ).then(res => {
                        if(typeof(res)==='object' && res?.error===true) {
                            setSaveState('default');
                            window.alert(res.main);
                        } else {
                            setSaveState("done");
                            if (!name) history.replace(langLink(MonitoryNoteScreen.link(res)));
                        }
                    }).catch(err => {
                        setSaveState("done");
                        console.log("Save error: ", err);
                        helpers.setErrors({
                            name: "validation.notUniqName"
                        })
                    })
                }}
            >{(formik: FormikProps) => <>
                <Form.Row>
                    <Form.ColGroup name="name" md={9}>
                        <Form.Label>{msgs.admin.labelTemplateName}</Form.Label>
                        <Form.Text readonly={name!==null}/>
                    </Form.ColGroup>
                    <Form.ColGroup name="style" md={3}>
                        <Form.Label>{msgs.gui.labelCommunicationStyle}</Form.Label>
                        <StyleSelect readonly={note.used}/>
                    </Form.ColGroup>
                </Form.Row>
                {isSms?<>
                    <Form.Group name="content">
                        <Form.Label>{msgs.admin.labelSMSContent}</Form.Label>
                        <SMSInput
                            value={formik.values.content}
                            onChange={v => formik.setFieldValue("content", v)}
                            controlRef={smsInput}
                        />
                        <Form.Error/>
                    </Form.Group>
                </>:<>
                    <Form.Group name="title">
                        <Form.Label>{msgs.admin.labelEmailTitle}</Form.Label>
                        <Form.Text/>
                    </Form.Group>
                    <Form.Group name="content" className="w-100">
                        <Form.Label>{msgs.gui.labelMessageContent}</Form.Label>
                        <RichEditor
                            // ref={this.editor}
                            // readonly={!this.edit}
                            mode="email"
                            className="im-editor"
                            value={formik.values.content}
                            ref={editor}
                            onChange={(v) => formik.setFieldValue("content", v)}
                        />
                        <Form.Error/>
                    </Form.Group>
                </>}
                <BForm.Group className="text-center">
                    <SaveButton
                        disabled={!edit}
                        state={saveState}
                    >{msgs.gui.actionSave}</SaveButton>
                </BForm.Group>
                <p className="text-info" dangerouslySetInnerHTML={{ __html: msgs.admin.hintFreeMarkerVariable }}/>
                <p className="text-info">{msgs.admin.labelVariables}:</p>
                <ul className="text-info">
                    {variables && Object.entries(variables).map(([k, v]) =>
                        <li
                            key={k}
                            className={"clickable"}
                            onClick={() => {
                                const v="${"+k+"}";
                                if(editor.current) editor.current.insertText(v)
                                else if(smsInput.current) {
                                    const input=smsInput.current;
                                    if(input.selectionStart || input.selectionStart=='0') {
                                        const start=input.selectionStart;
                                        formik.setFieldValue('content',
                                            formik.values.content.substring(0, start)+
                                            v+
                                            formik.values.content.substring(input.selectionEnd)
                                        );
                                        window.setTimeout(() => {
                                            input.focus();
                                            input.selectionEnd=input.selectionStart=start+v.length;
                                        }, 0);
                                    } else {
                                        formik.setFieldValue('content', formik.values.content + v);
                                    }
                                }
                            }}
                        >
                            <code>{"${"+k+"}"}</code> - {v}
                        </li>
                    )}
                </ul>
                <ReplaceVariables/>
            </>}
            </Form>}
        </div>
    </>
}
MonitoryNoteScreen.url="/monitory_note/:id";
MonitoryNoteScreen.link=(id: string) => "/monitory_note/"+encodeURIComponent(id);
