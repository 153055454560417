//@flow
import React, {useMemo} from 'react';
import {RouteChildrenProps, RouteComponentProps} from "react-router";
import type {DictionaryType, SystemRight, WebDictionaryEntry} from "../api";
import {Events, events, store} from "../application";
import type {AllDataTableProps, DataTableColumn} from "../lib/DataTable";
import {ClientDataTable} from "../lib/DataTable";
import msgs, {formatString, getLangValue, LangContext, Languages, LanguagesOrdered, useMsgs} from "../lib/Language";
import {Breadcrumb, Button} from "react-bootstrap";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, IconAlert, PageHeader} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {Form} from "../lib/Form";
import {emitEvent} from "../lib/Events";
import {Countries} from "../lib/Data";
import {Redirect} from "react-router-dom";

type State = {
    values: null|Array<WebDictionaryEntry>;
}

type DictionaryVariant="SimpleLabel"|"SimpleLangLabel"|"KeyLabel"|"KeyLangLabel";

function getVariant(type: DictionaryType): DictionaryVariant {
    switch (type) {
        case "OrganizationOrigin": return "SimpleLabel";
        case "GlobalPrices": return "SimpleLabel";
        case "StageNames": return "KeyLangLabel";
        default:
            throw Error("Unimplemented");
    }
}

/** Wymagane prawo do słownika */
function requiredRight(type: DictionaryType): SystemRight {
    switch (type) {
        case "OrganizationOrigin": return "OrganizationOrigin";
        case "GlobalPrices": return "VindicationGlobalPrices";
        case "StageNames": return "VindicationStageNames";
        default:
            return "Admin";
    }
}

const DictionaryTable = ({ type, variant, onDelete, ...props }: $Diff<AllDataTableProps, { columns: any }> & {
    type: DictionaryType;
    variant: DictionaryVariant;
    onDelete: (item: WebDictionaryEntry) => void;
}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumn<WebDictionaryEntry>>>(() => {
        let res=[];
        switch (type){
            case "GlobalPrices":
                res.push({
                    accessor: "label",
                    Header: msgs.gui.labelCountry,
                    Cell: ({ value }) => {
                        const v=Countries.byCode.get(value);
                        if(!v) return value;
                        return <><img className="flag" src={Countries.img(value)}/> {v.name}</>
                    }
                });
                break;
            default:
                switch (variant) {
                    case "SimpleLabel":
                        res.push({
                            accessor: "label",
                            Header: msgs.admin.labelMessage,
                        });
                        break;
                    case "KeyLangLabel":
                        res.push({
                                accessor: "value",
                                Header: msgs.admin.labelValue,
                            }, ...LanguagesOrdered.map(l => ({
                                id: "langLabel-" + l.code,
                                Header: msgs.admin.labelMessage +' '+ l.code,
                                Cell: ({row}) => getLangValue(row.original.langLabel, l),
                            }))
                        );
                }
        }
        res.push({
            id: "actions",
            Header: '',
            className: "delete",
            Cell: ({ row }) => <DeleteButton onClick={(e) => onDelete(row.original, e)}/>
        })
        return res;
    }, [ type, variant, msgs.gui.language, onDelete ]);
    return <ClientDataTable columns={columns} {...props}/>
}

type Props= RouteComponentProps<{ type: DictionaryType }>;

export default class DictionaryScreen extends React.Component<Props, State> {
    static url = "/dictionary/:type";
    static link = (type: DictionaryType) => "/dictionary/"+type;

    access: boolean;
    edit: boolean;
    variant: DictionaryVariant;
    type: DictionaryType;

    constructor(props) {
        super(props);
        this.type=this.props.match.params.type;
        this.edit=true;
        this.access=store.hasRight(requiredRight(this.type));
        this.variant=getVariant(this.type);
        this.state = {
            values: null,
        }
    }

    refresh = () => {
        if(!this.access) return;
        store.userApi.getEditDictionary(this.type).then((data) => this.setState({ values: data }));
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: $ReadOnly<Props>, prevState: $ReadOnly<State>, snapshot: any) {
        if(prevProps.match.params.type!==this.props.match.params.type) {
            this.type=this.props.match.params.type;
            this.access=store.hasRight(requiredRight(this.type));
            this.variant=getVariant(this.type);
            this.setState({ values: null });
            this.refresh();
        }
    }

    onDelete = (item: WebDictionaryEntry) => {
        store.userApi.deleteDictionaryEntry(this.type, item).finally(() => {
            emitEvent(Events.DictionariesChange, this.type);
            this.refresh();
        });
    }


    onAdd(e: SyntheticEvent) {
        dialogOpen(this, EditDictionaryEntryDialog.link(this.type, "-"), this.refresh);
    }

    onEdit=(item: WebDictionaryEntry) => {
        dialogOpen(this, EditDictionaryEntryDialog.link(this.type, item.id), this.refresh);
    }

    render() {
        if(!this.access) {  // brak uprawnień
            console.log("No access redirect");
            return <Redirect to="/"/>;
        }
        if(this.state.values===null) return null;
        const title=msgs.admin['titleDictionary'+this.type];
        const info=msgs.admin['titleDictionary'+this.type+'Info'];
        return  <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{title}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={title}>
                {this.edit?<Button onClick={(e) => this.onAdd(e)}>{msgs.gui.buttonAdd}</Button>:null}
            </PageHeader>
            {info?<IconAlert>{info}</IconAlert>:null}
            <DictionaryTable
                data={this.state.values}
                type={this.type}
                variant={this.variant}
                onDelete={this.onDelete}
                onRowClick={this.onEdit}
            />
        </>
    }
}
DictionaryScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ id: string, type: DictionaryType }> & {

}

type EditState = {
    value: WebDictionaryEntry;
}

export class EditDictionaryEntryDialog extends React.Component<EditProps, EditState> {
    static url = "/dictionary/:type/:id";
    static link = (type: DictionaryType, id: string) => "/dictionary/"+type+"/"+id;

    type: DictionaryType;
    variant: DictionaryVariant;

    constructor(props) {
        super(props);
        this.type=this.props.match.params.type;
        this.variant=getVariant(this.type);

        this.state={
            value: null,
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        store.userApi.getEditDictionaryEntry(this.type, id).then(value => {
            this.setState({ value })
        });
    }

    render() {
        if(!this.state.value) return null;
        return <Form
            initialValues={this.state.value}
            onSubmit={async (values, helpers) => {
                let res=await store.userApi.saveDictionaryEntry(this.type, values);
                if(!Form.setError(helpers, res)) {
                    this.props.history.goBack();
                    events.emit(Events.DictionariesChange, this.type);
                }
            }}
        >{(formik) => <Dialog
            title={this.props.match.params.id==='-'?msgs.admin.titleAddDictionaryValue:msgs.admin.titleEditDictionaryValue}
            onAccept={(e) => { formik.handleSubmit(e); return false; }}
        >
            {(this.variant==="KeyLabel" || this.variant==="KeyLangLabel")?<Form.Group name="value">
                    <Form.Label>{msgs.admin.labelValue}</Form.Label>
                    <Form.Text/>
                </Form.Group>:null}
            {(this.variant==="SimpleLangLabel" || this.variant==="KeyLangLabel")?<>
                <Form.Group name="langLabel">
                    <Form.Label>{formatString(msgs.admin.labelMessageLang, Languages.pl.code)}</Form.Label>
                    <Form.MultiLanguageTextInput lang={Languages.pl.code}/>
                </Form.Group>
                <Form.Group name="langLabel">
                    <Form.Label>{formatString(msgs.admin.labelMessageLang, Languages.en.code)}</Form.Label>
                    <Form.MultiLanguageTextInput lang={Languages.en.code}/>
                </Form.Group>
            </>:null}
            {(this.variant==="SimpleLabel" || this.variant==="KeyLabel")?<>
                <Form.Group name="label">
                    {this.type==="GlobalPrices"?<>
                        <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                        <Form.Country/>
                    </>:<>
                        <Form.Label>{msgs.admin.labelMessage}</Form.Label>
                        <Form.Text/>
                    </>}
                </Form.Group>
            </>:null}
        </Dialog>}</Form>;
    }
}
EditDictionaryEntryDialog.contextType=LangContext;