//@flow
import React, {Ref, useEffect, useMemo, useState} from 'react';
import {Dropdown, Spinner} from "react-bootstrap";
import msgs, {getLangValue, LangContext, useMsgs} from "../lib/Language";
import type {AccessRight, AdminUserOrganizationInfo, OrderRegistrationData} from "../api";
import {Events, store} from "../application";
import {RouteComponentProps, useRouteMatch} from "react-router";
import {Dialog, dialogOpen, SendAlert} from "../lib/Components";
import {ServiceForm} from "../lib/AddService";
import {FormikBag} from "formik";
import {emitEvent} from "../lib/Events";
import {useHistory, useLocation} from "react-router-dom";
import {AddVindication} from "../lib/AddVindication";
import {VindicationScreen} from "./VindicationsScreen";
import type {AddVindicationAPI, VindicationServerAPI} from "../lib/AddVindication";
import {ReceivableInfo, VindicationRegisterData} from "../api";

type RegisterState = {
    /** Wybrany typ */
    type: AccessRight;
    /** Czy wysłano dane do serwera */
    submitted: boolean;
}

/**
 * Okno dodawania usługi. Może być wybrane jako globalne - z formularzem wyboru typu
 * lub dla danego typu.
 */
export class RegisterServiceDialog extends React.Component<RouteComponentProps<{ name: string, uoid: string }>, RegisterState> {
    static url = "/service/add/:name/:uoid";
    static link = (a: AccessRight|string, uoId: string) => "/service/add/"+(a?(typeof(a)==='object'?a.ident:a):"")+"/"+uoId;

    formik: Ref<FormikBag>;

    constructor(props) {
        super(props);

        this.formik=React.createRef();
        this.state = {
            type: null,
            submitted: false,
        }
    }

    componentDidMount() {
        store.userApi.getUserServices(this.props.match.params.uoid).then(services => {
            const s=services.find((a: AccessRight) => a.ident===this.props.match.params.name);
            this.setState({
                type: s
            })
        })
    }

    registerOrder = async (typeId: string, data: OrderRegistrationData) => {
        return await store.userApi.registerOrder(this.props.match.params.uoid, typeId, data);
    }

    render() {
        const type=this.state.type;
        if(!type) return null;

        let title;
        if(this.state.submitted) title=msgs.gui.titleServiceSend;
        else title=msgs.gui.actionAddServicePrefix+": "+getLangValue(type.name);

        return <Dialog
            title={title}
            cancelButton={this.state.submitted?null:msgs.gui.buttonCancel}
            acceptButton={this.state.submitted?msgs.gui.actionFinish:this.formik.current?msgs.gui.actionSend:undefined}
            onAccept={() => {
                if(this.formik.current) {
                    this.formik.current.handleSubmit();
                    return false;
                } else {
                }
            }}
        >
            {this.state.submitted?<SendAlert>{msgs.gui.hintServiceSend}</SendAlert>:
                <ServiceForm
                    type={type}
                    registerOrder={this.registerOrder}
                    onSubmitted={() => {
                        this.setState({ submitted: true });
                        emitEvent(Events.OrderChange, type);
                    }}
                    formikRef={this.formik}
                />}
        </Dialog>
    }
}
RegisterServiceDialog.contextType=LangContext;


/**
 * Ekran dodawania windykacji dla zalogowanego użytkownika w oparciu o komponent AddVindication.
 */
export const VindicationNew=() => {
    const [ info, setInfo ] = useState<AdminUserOrganizationInfo|null>(null);
    const history=useHistory();
    const math=useRouteMatch<{ uoid: string }>();
    const uoId=math.params.uoid;
    const api=useMemo<VindicationServerAPI>(() => {
        return {
            // To jakoś inaczej nie ma sensu, chyba że zapamiętałoby się ustawienie (położenie) użytkownika w bazie
            getCountry: async() => "PL",
            getDictionaries: store.getDictionaries.bind(store),
            getInsurers: async() => store.userApi.getOrganizationInsurers(uoId),
            getCompanyInfo: store.userApi.getCompanyInfo.bind(store.userApi),
            // To dla trybu zalogowanego użytkownika
            getVindicationsForDebtor: async (nip: string) => store.userApi.getVindicationsForDebtor(uoId, nip),
            registerVindication: async (vindication: VindicationRegisterData) => store.userApi.registerVindication(uoId, vindication),
            generateReport: null,   // nie ma raportu dla pracownika
            updateVindicationContacts: async (vId: string, data: VindicationRegisterData) => store.userApi.updateVindicationContacts(uoId, vId, data),
            registerMultipleVindications: async (vindications: Array<VindicationRegisterData>) => store.userApi.registerMultipleVindications(uoId, vindications),
            checkDocuments: async (docs: Array<ReceivableInfo>) => store.userApi.checkDocuments(uoId, docs),
            // To dla trybu windykacja z rejestracją
            getRegisteredReport: null,
            updateRegisterVindicationContacts: null,
        }
    }, [ uoId ]);
    useEffect(() => {
        setInfo(null);  // jakby się zmieniło (?)
        store.userApi.getUserOrganizationInfo(uoId).then(info => setInfo(info));
    }, [ uoId ]);

    if(!info) return null;

    return <AddVindication
        logged
        displayInterestRate={info.country!=="PL"}
        onCancel={() => history.goBack()}
        onSuccess={() => {
            history.goBack();
        }}
        viewLink={VindicationScreen.link}
        serverApi={api}
        eventName={Events.VindicationChange}
    >{(api: AddVindicationAPI) => <Dialog
        title={api.title}
        cancelButton={!api.handleBack?null:api.backLabel || ((api.stage==="tax" || api.stage==="contact")?msgs.gui.buttonCancel:msgs.gui.buttonBack)}
        acceptButton={!api.handleNext?null:api.nextLabel || ((api.stage==="done" || api.stage==="multiple_done" || api.stage==="contact")?msgs.gui.actionFinish:msgs.gui.buttonNext)}
        onAccept={(e) => {
            if(api.stage==="done") history.goBack();
            else api.handleNext(e);
            return false;
        }}
        onCancel={(e) => {
            api.handleBack(e);
            return false;
        }}
    >{api.render()}</Dialog>
    }</AddVindication>
}
VindicationNew.url="/debtcollection_new/:uoid";
VindicationNew.link=(uoid: string) => "/debtcollection_new/"+uoid;

/**
 * Komponent z akcjami dla przycisku zlecania w imieniu klienta.
 * Jest to wyciągnięte do osobnego komponentu, bo aby pobrać usługi trzeba sprawdzić prawa.
 * Nie ma sensu robienie tego w momencie wyświetlania przycisku - dopiero po kliknięciu
 **/
const ClientOrderActions = ({ uoId }: {
    uoId: string;
}) => {
    const msgs=useMsgs();
    const history=useHistory();
    const location=useLocation();
    const [ actions, setActions ] = useState<Array<AccessRight>>(null);

    useEffect(() => {
        store.userApi.getUserServices(uoId).then(actions => setActions(actions));
    }, [uoId])

    if(actions===null) return <Spinner animation="grow" />
    if(actions.length===0) return <Dropdown.Header>{msgs.admin.hintNotClientServices}</Dropdown.Header>;
    return actions.map((a: AccessRight) => <Dropdown.Item
        eventKey={a.ident}
        key={a.ident}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if(!a.type) {
                dialogOpen(history, location, RegisterServiceDialog.link(a, uoId));
            } else {
                dialogOpen(history, location, VindicationNew.link(uoId));
            }
        }}
    >{getLangValue(a.name)}</Dropdown.Item>);
}

const popperConfig={
    strategy: "fixed"
}

/**
 * Komponent/przycisk, który wywołuje akcje zleć w imieniu klienta.
 */
const ClientOrderButton = ({ uoId }: {
    /** Id użytkownika w organizacji */
    uoId: string;
}) => {
    const msgs=useMsgs();
    return <Dropdown>
            <Dropdown.Toggle id={`coa_${uoId}`}>{msgs.admin.actionClientOrder}</Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={false} popperConfig={popperConfig}>
                <ClientOrderActions uoId={uoId}/>
            </Dropdown.Menu>
        </Dropdown>
}

export default ClientOrderButton;