//@flow
/* eslint-disable no-use-before-define */
import React from 'react';
import msgs, {LangContext} from "../lib/Language";
import {Breadcrumb, Button, Col, Row} from "react-bootstrap";
import type {Option, RoleDetails, RoleInfo} from "../api";
import {RoleDetailsValidation} from "../api";
import {RouteChildrenProps} from "react-router";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";
import {Form} from "../lib/Form";

type State = {
    dataTime: number;
}

export default class RolesScreen extends React.Component<RouteChildrenProps, State> {
    static url="/roles";

    column: Array<AllDataTableColumn<RoleInfo>>;
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("AccessLevelWrite")
        this.state = {
            dataTime: Date.now(),
        }
        this.column=[
            {
                accessor: 'name',
                Header: msgs.gui.labelRole,
                filter: 'text',
                className: "lg",
            },
            {
                accessor: 'desc',
                Header: msgs.admin.labelRoleDescription,
                filter: 'text',
            },
            {
                id: 'actions',
                Header: '',
                className: "delete",
                Cell: ({row}) => this.edit?<DeleteButton confirm onClick={(e) => this.onDelete(row.original, e)}/>:null,
            }
        ]
    }

    refresh() {
        this.setState({ dataTime: Date.now() })
    }


    onAdd() {
        dialogOpen(this, EditRoleDialog.link('-'), () => this.refresh());
    }

    onSelect(r: RoleInfo) {
        dialogOpen(this, EditRoleDialog.link(r.id), () => this.refresh());
    }

    onDelete(r: RoleInfo, e: SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        store.userApi.deleteRole(r.id).then(() => {
            this.refresh()
            events.emit(Events.RoleChange);
        });
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuRoles}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuRoles}>
                {this.edit?<Button onClick={e => this.onAdd(e)}>{msgs.admin.actionAddRole}</Button>:null}
            </PageHeader>
            <AllDataTable
                columns={this.column}
                data={store.userApi.getAllRoles}
                onRowClick={(r) => this.onSelect(r)}
                dataTimestamp={this.state.dataTime}
                historyState="table"
            />
        </>;
    }
}
RolesScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState =  {
    value: RoleDetails;
}

export class EditRoleDialog extends React.Component<EditProps, EditState> {
    static url="/role/:id";
    static link=(id: string) => "/role/"+id;

    /** Tablica ze wszystkimi dostępnymi prawami w systemie - nigdy się nie zmienia */
    static rights: Array<Array<Option>>;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("AccessLevelWrite")
        this.state={
            value: null,
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        // Za pierwszym razem pobieramy
        if(!EditRoleDialog.rights) {
            store.userApi.getAdminRights().then((rights: Array<Option>) => {
                EditRoleDialog.rights=[];
                let group:Array<Option>=null;
                rights.forEach((i: Option) => {
                    if(group===null || group[0].category!==i.category) {
                        EditRoleDialog.rights.push(group=[]);
                    }
                    group.push(i);
                })
                this.forceUpdate();
            });
        }
        store.userApi.getRole(id).then((r: RoleDetails) => {
            this.setState({ value: r });
        })
    }

    render() {
        if(!this.state.value || !EditRoleDialog.rights) return null;
        return <Form
            initialValues={this.state.value}
            validate={RoleDetailsValidation}
            readonly={!this.edit}
            onSubmit={async (values, helpers) => {
                let res=await store.userApi.saveRole(values);
                if(Form.setError(helpers, res)) return;
                this.props.history.goBack();
                events.emit(Events.RoleChange);
            }}
        >{(formik) => <Dialog
            title={this.props.match.params.id==='-'?msgs.admin.titleAddRole:msgs.admin.titleEditRole}
            onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
        >
            <Form.Group name="name">
                <Form.Label>{msgs.gui.labelRoleName}</Form.Label>
                <Form.Text maxLength={200}/>
            </Form.Group>
            <Form.Group name="desc">
                <Form.Label>{msgs.admin.labelRoleDescription}</Form.Label>
                <Form.TextArea/>
            </Form.Group>
            <Form.Group name="rights">
                <Form.Label>{msgs.gui.labelRole}</Form.Label>
                {EditRoleDialog.rights.map((cat: Array<Option>) => <Row
                    key={cat[0].category}
                >
                    {cat.map((r: Option) => <Col
                        md={6} key={r.value} className="pb-2"
                    ><Form.FlagCheck
                        key={r.value} id={"rf_"+r.value} flag={r.value}
                    >{r.label}</Form.FlagCheck></Col>)}
                </Row>)}
            </Form.Group>
            <Form.Row>
                <p className="text-info" dangerouslySetInnerHTML={{ __html: msgs.admin.hintRights }}/>
            </Form.Row>
        </Dialog>}</Form>
    }
}
EditRoleDialog.contextType=LangContext;
