//@flow
import React, {useState} from 'react';
import {store} from "../application";
import {Breadcrumb, Button, Col, Container, FormCheck, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {BreadcrumbItem, PageHeader, waitGlass} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import msgs from "../lib/Language";
import {getDownloadLink} from "../lib/Upload";
import {DateInput, formatJSON, startOfDay} from "../lib/DateTime";
import Validate from "../lib/Validation";

const Report = ({ get, children, ...props }) => {
    return <p><Button onClick={(e) => {
        e.preventDefault();
        get().then(file => {
            if(Validate.isError(file)) {
                window.alert(file.main);
                return;
            }
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        }).finally(waitGlass());
    }} {...props}>{children}</Button></p>
}

const ReportsScreen = () => {
    const [org, setOrg] = useState("");
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(formatJSON(startOfDay(new Date())));
    if (!store.hasRight("Reports")) return null;
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.admin.menuReports}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.admin.menuReports}/>
        <div className="tab-content">
            <Report
                get={store.userApi.monitoredPerOrganizationReport}>{msgs.admin.reportOrganizationMonitoredCount}</Report>
            <Report get={store.userApi.usersInOrganizationReport}>{msgs.admin.reportUsersInOrganization}</Report>
            <Row>
                <Col md={2} className="align-self-center">
                    <FormLabel>EC-Id organizacji</FormLabel>
                    <FormControl value={org} onChange={e => setOrg(e.target.value)} type="number" step={1}/>
                </Col>
                <Col md={9} className="align-self-center">
                    <Report
                        get={() => store.userApi.monitoringEventsInOrganization(org)}
                        disabled={!org}
                    >Zdarzenia monitorowania dla organizacji</Report>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={2}>
                    <FormLabel>EC-Id organizacji</FormLabel>
                    <FormControl value={org} onChange={e => setOrg(e.target.value)} type="number" step={1}/>
                </Col>
                <Col md={2}>
                    <FormLabel>Data od</FormLabel>
                    <DateInput value={from} onChange={setFrom}/>
                </Col>
                <Col md={2}>
                    <FormLabel>Data do</FormLabel>
                    <DateInput value={to} onChange={setTo}/>
                </Col>
                <Col md={6}>
                    <Report
                        get={() => store.userApi.invoiceMonitoringSubscription(org, from, to)}
                    >Historia abonamentu monitoringu faktur</Report>
                    <Report
                        get={() => store.userApi.invoiceMonitoringSendMessages(org, from, to)}
                    >Wysłane powiadomienia</Report>
                </Col>
            </Row>
        </div>
    </>
}
ReportsScreen.url="/reports"

export default ReportsScreen;