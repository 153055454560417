//@flow
import React from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import ResetPasswordScreen from "./ResetPasswordScreen";
import {RouteComponentProps} from "react-router";
import Validation from "../lib/Validation";
import {AuthPage, EmailInput, IconButton, PasswordInput, waitGlass} from "../lib/Components";
import msgs, {getMessage, LangContext, LangLink, moveTo} from "../lib/Language";
import {store} from "../application";
import {isDevelopment} from "../lib/Utils";

type State = {
    email: string;
    password: string;
    invalid: string|null;
}

export default class LoginScreen extends React.Component<RouteComponentProps, State> {
    static url='/login';

    constructor(props) {
        super(props);
        this.state={
            email: isDevelopment?"gg@euleo.pl":"",
            password: isDevelopment?"password":"",
            invalid: null,
        }
    }

    onLogin(e: Event) {
        e.preventDefault();
        store.publicApi.login(this.state.email, this.state.password).then(res => {
            let err=Validation.getError(res);
            if(err) {
                this.setState({ invalid: err });
                return;
            }
            store.processLogin().then(() => {
                // Przekierowanie do docelowej strony
                const last=store.getReturnPage();
                if(last) {
                    this.props.history.replace(last);
                } else {
                    moveTo(this.props, "/", false, true);
                }
            });
        }).finally(waitGlass());
    }

    render() {
        return (
        <AuthPage title={msgs.gui.titleLogin} footer={false}>
            <Modal.Body>
                {this.state.invalid?<h3 className="text-center mb-3 text-danger">
                    {getMessage(this.state.invalid)}
                </h3>:null}
                <Form className="login-form">
                    <Form.Group controlId="email">
                        <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                        <EmailInput value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>{msgs.gui.labelPassword}</Form.Label>
                        <PasswordInput value={this.state.password} onChange={e => this.setState({ password: e.target.value })}/>
                    </Form.Group>
                    <Row className="d-flex align-items-center">
                        <Col md={6}>
                            <LangLink to={ResetPasswordScreen.url}>{msgs.gui.labelNoPassword}</LangLink>
                        </Col>
                        <Col md={6} className="text-right">
                            <IconButton
                                icon={<span className="icon-user"/>}
                                size="xl" type="submit" onClick={(e) => this.onLogin(e)}
                            >{msgs.gui.actionLogin}</IconButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </AuthPage>
       );
    }
}
LoginScreen.contextType=LangContext;