//@flow
import React from 'react';
import {Breadcrumb, Button, Col, Form as BForm} from "react-bootstrap";
import type {AccountsConfig, BirConfig, ContactConfig, IPApiConfig, SystemSettings} from "../api";
import Validate from "../lib/Validation";
import msgs, {LangContext} from "../lib/Language";
import HomeScreen from "./HomeScreen";
import {store} from "../application";
import {Form} from "../lib/Form";
import {BreadcrumbItem, PageHeader} from "../lib/Components";
import {parse, isValid, parseJSON} from 'date-fns'
import {dateFormat, formatDate, formatJSON, parseDate} from "../lib/DateTime";

type SmtpSettingsState = {
    testTo: string;
}

class SmtpSettings extends React.Component<{ value: SmtpSettings }, SmtpSettingsState> {

    edit: boolean;
    constructor(props) {
        super(props);
        this.state={ testTo: '' };
        this.edit=store.hasRight("ConfigWrite");
    }

    sendTestEmail(e: Event) {
        e.preventDefault();
        store.userApi.sendTestEmail(this.state.testTo).then(res => {
            if(res===null) {
                window.alert("Wiadomość przekazana poprawnie do serwera SMTP");
            } else {
                window.alert("Błąd wysyłania wiadomość: "+res);
            }
        })
    }

    render() {
        return <Form
            initialValues={this.props.value}
            readonly={!this.edit}
            onSubmit={async (values) => {
                await store.userApi.updateEmailSettings(values);
                return true;
            }}
        >{() => <>
            <h3>{msgs.admin.titleSMTPSettings}</h3>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="hostname">
                        <Form.Label>{msgs.admin.labelSMTPHost}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group name="port">
                        <Form.Label>{msgs.admin.labelSMTPPort}</Form.Label>
                        <Form.Number min={1} max={65535} maxLength={6}/>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group name="mode">
                        <Form.Label>{msgs.admin.labelSMTPMode}</Form.Label>
                        <Form.Select>
                            <option value="NORMAL">{msgs.admin.modeNORMAL}</option>
                            <option value="TLS">{msgs.admin.modeTLS}</option>
                            <option value="SSL">{msgs.admin.modeSSL}</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="username">
                        <Form.Label>{msgs.admin.labelSMTPUsername}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="password">
                        <Form.Label>{msgs.admin.labelSMTPPassword}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="fromEmail">
                        <Form.Label>{msgs.admin.labelSMTPFromEmail}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="fromName">
                        <Form.Label>{msgs.admin.labelSMTPFrom}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <h3>{msgs.admin.titleIMAPSettings}</h3>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="imapHostname">
                        <Form.Label>{msgs.admin.labelIMAPHost}</Form.Label>
                        <Form.Text maxLength={128}/>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group name="imapPort">
                        <Form.Label>{msgs.admin.labelIMAPPort}</Form.Label>
                        <Form.Number min={1} max={65535} maxLength={6}/>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group name="imapSSL">
                        <Form.Label>{msgs.admin.labelIMAPSSL}</Form.Label>
                        <Form.Switch>{msgs.admin.labelIMAPSSL}</Form.Switch>
                    </Form.Group>
                </Col>
            </Form.Row>
            <BForm.Group className="text-center">
                <Form.Save
                    disabled={!this.edit}
                >{msgs.admin.actionSaveMailSettings}</Form.Save>
            </BForm.Group>
            <h4>{msgs.admin.titleSMTPTest}</h4>
            <BForm.Row>
                <Col md={6}>
                    <BForm.Group>
                        <BForm.Label>{msgs.admin.labelEmail}</BForm.Label>
                        <BForm.Control
                            type="text"
                            value={this.state.testTo}
                            onChange={e => this.setState({ testTo: e.target.value })}
                        />
                    </BForm.Group>
                </Col>
                <Col md={6}>
                    <Button
                        className="position-absolute"
                        style={{ right: 0, bottom: "1rem" }}
                        disabled={!Validate.email(this.state.testTo) || !this.edit}
                        onClick={(e) => this.sendTestEmail(e)}
                    >{msgs.admin.actionSMTPTest}</Button>
                </Col>
            </BForm.Row>
            <p className="text-info">{msgs.admin.hintSMTPTest}</p>
        </>}</Form>;
    }
}
SmtpSettings.contextType=LangContext;

class ContactSettingsForm extends React.Component<{ value: ContactConfig }, void> {
    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("ConfigWrite");
    }

    render() {
        return <Form
            initialValues={this.props.value}
            readonly={!this.edit}
            onSubmit={async(val) => {
                await store.userApi.updateContactSettings(val);
                return true;
            }}
        >{() => <>
            <h3>{msgs.admin.titleContactSettings}</h3>
            <Form.RowGroup name="email">
                <Form.Label>{msgs.admin.labelContactEmail}</Form.Label>
                <Form.Text/>
            </Form.RowGroup>
            <Form.Row>
                <Col className="text-center">
                    <Form.Save disabled={!this.edit}>{msgs.admin.actionSaveOther}</Form.Save>
                </Col>
            </Form.Row>
        </>}</Form>;
    }
}
ContactSettingsForm.contextType=LangContext;


type OtherSettingsState = {
    value: BirConfig & IPApiConfig & AccountsConfig & {
        days: string;
    };
}

/**
 * Funkcja parsująca dni z pola tekstowego do tablicy dni.
 * Jeżeli jest błąd, to zwracany jest null
 * @param days
 */
function parseDays(days: string): Array<Date>|null {
    if(typeof(days)!=='string') return null;
    const parts=days.trim().split(/\s*[\s;,]\s*/);
    let res=[];
    for(let p of parts) {
        p=p.trim();
        if(p.length===0) continue;  //?? czy błąd
        let d=parse(p, dateFormat, new Date());
        if(!isValid(d)) return null;
        res.push(d);
    }
    return res;
}

class OtherSettings extends React.Component<void, OtherSettingsState> {
    edit: boolean;

    constructor(props) {
        super(props);
        const settings: SystemSettings = this.props.value;
        this.state={ value: {
                birKey: settings.bir.key,
                ipApiKey: settings.ipapi.key,
                resetPasswordTimeout: settings.accounts.resetPasswordTimeout,
                days: settings.freeDays.days.map(i => formatDate(parseDate(i), dateFormat)).join(" "),
            }
        }
        this.edit=store.hasRight("ConfigWrite");

    }

    render() {
        return <Form
            initialValues={this.state.value}
            readonly={!this.edit}
            validate={(values) => {
                let err={};
                if(!Array.isArray(parseDays(values.days))) err.days='validation.invalidValue';
                return err;
            }}
            onSubmit={async(val) => {
                await Promise.all([
                    store.userApi.updateBirSettings({ key: val.birKey }),
                    store.userApi.updateIPApiSettings({ key: val.ipApiKey }),
                    store.userApi.updateAccountsSettings({ resetPasswordTimeout: val.resetPasswordTimeout }),
                    // store.userApi.updateFreeDaysSettings({ days: parseDays(val.days).map(d => formatJSON(d)) }),
                ])
                return true;
            }}
        >{() => <>
            <h3>{msgs.admin.titleOtherSettings}</h3>
            <Form.RowGroup name="birKey">
                <Form.Label>{msgs.admin.labelBIRKey}</Form.Label>
                <Form.Text maxLength={64}/>
            </Form.RowGroup>
            <Form.RowGroup name="ipApiKey">
                <Form.Label>{msgs.admin.labelIPAPIKey}</Form.Label>
                <Form.Text maxLength={64}/>
            </Form.RowGroup>
            <Form.RowGroup name="resetPasswordTimeout">
                <Form.Label>{msgs.admin.labelResetPasswordTimeout}</Form.Label>
                <Form.Number maxLength={5}/>
            </Form.RowGroup>
            <Form.RowGroup name="days">
                <Form.Label>{msgs.admin.labelFreeDays}</Form.Label>
                <Form.Text readonly={true}/>
            </Form.RowGroup>
            <Form.Row>
                <Col className="text-center">
                    <Form.Save disabled={!this.edit}>{msgs.admin.actionSaveOther}</Form.Save>
                </Col>
            </Form.Row>
        </>}</Form>;
    }
}
OtherSettings.contextType=LangContext;


type State = {
    loaded: boolean;
    settings: SystemSettings|null;
}

export default class SettingsScreen extends React.Component<void, State> {
    static url="/settings";

    constructor(props) {
        super(props);
        this.state={
            settings: null,
        }
    }

    componentDidMount(): void {
        store.userApi.getSystemSettings().then((s: SystemSettings) => {
            this.setState({ settings: s });
        });
    }

    render() {
        if(this.state.settings===null) return null;
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuSettings}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuSettings}/>

            <SmtpSettings value={this.state.settings.smtp}/>
            {/*<ContactSettingsForm value={this.state.settings.contact}/>*/}
            <OtherSettings value={this.state.settings}/>
        </>;
    }
}
SettingsScreen.contextType=LangContext;
