//@flow
import React, {Ref} from 'react';
import {emptyLangString, LanguagePanel, RichEditor} from "../AdminComponents";
import type {LangInfo, Language} from "../lib/Language";
import msgs, {defaultLanguage, getMessage, LangContext, langLink, LanguagesOrdered} from "../lib/Language";
import type {TextDetails, TextInfo} from "../api";
import {RouteChildrenProps} from "react-router";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {formatDate} from "../lib/DateTime";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {AllDataTableColumn} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";
import {BreadcrumbItem, PageHeader, SaveButton} from "../lib/Components";
import type {ErrorResult} from "../lib/Validation";
import Validate from "../lib/Validation";
import type {SaveButtonState} from "../lib/Components";

export default class TemplatesScreen extends React.Component<RouteChildrenProps, void> {
    static url="/emails";

    columns: Array<AllDataTableColumn<TextInfo>>;

    constructor(props) {
        super(props);
        this.state={
            data: null,
        }
        // Kolumny są tu, aby nie tworzył się nowy obiekt za każdym razem
        this.columns=[ {
            accessor: "name",
            Header: msgs.gui.labelID,
            className: "name",
            filter: "text",
        }, ...LanguagesOrdered.map((l: LangInfo) => ({
            id: l.code,
            accessor: (row) => row.title[l.code],
            Header: msgs.gui.labelTitle+' '+l.code,
            filter: 'text',
            className: "lang-part"
        })), {
            accessor: 'modified',
            Header: msgs.gui.labelChangeTime,
            Cell: ({ value }) => formatDate(value),
            filter: 'date',
            className: "date"
        } ];
    }

    onSelectRow(row: TextInfo) {
        this.props.history.push(langLink(EditTemplateScreen.link(row.name)));
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuSystemNotifications}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuSystemNotifications}/>
            <AllDataTable
                columns={this.columns}
                data={store.userApi.getEmailTemplates}
                onRowClick={(r) => this.onSelectRow(r)}
                historyState="table"
            />
        </>;
    }
}
TemplatesScreen.contextType=LangContext;


type EditProps = RouteChildrenProps<{ template: string }> &{
}

type EditState = TextDetails & {
    /** Czy wczytano */
    loaded: boolean;
    lang: Language;
    errors: ErrorResult;
    saveState: SaveButtonState;
}

/**
 * Strona z edycją treści szablonu wiadomości e-mail.
 */
export class EditTemplateScreen extends React.Component<EditProps, EditState> {
    static url="/email/:template";

    static link(name: string): string {
        return "/email/"+encodeURI(name);
    }

    edit: boolean;
    editor: Ref<RichEditor>;

    constructor(props) {
        super(props);
        this.state={
            loaded: false,
            lang: defaultLanguage,

            title: emptyLangString(),
            message: emptyLangString(),

            errors: null,
            saveState: null,
        }
        this.editor=React.createRef();
        this.edit=store.hasRight("EmailTemplatesWrite");
    }

    componentDidMount(): void {
        store.userApi.getEmailTemplate(this.props.match.params.template).then((details) => {
            this.setState({ loaded: true, ...details });
        });
    }

    onSave(e: SyntheticEvent) {
        // console.log("Save: ", this.state.title, this.state.content);
        e.preventDefault();
        e.stopPropagation();
        this.setState({ saveState: "loading" });
        store.userApi.saveEmailTemplate(this.props.match.params.template, this.state.title, this.state.content).then((err: ErrorResult|null) => {
            events.emit(Events.TemplateChange);
            this.setState({ errors: err });
            if(err) {
                window.alert(msgs.admin.invalidTemplate);
                this.setState({ saveState: "error" })
            } else {
                this.setState({ saveState: "done" })
            }
        });
    }

    onTemplateTest(e: Event) {
        const lang=this.state.lang;
        store.userApi.sendEmailTemplate(lang,
            this.props.match.params.template,
            this.state.title[lang], this.state.content[lang]).then((err: ErrorResult) => {
            if(Validate.isError(err)) {
                window.alert(getMessage(err.main))
            } else {
                window.alert(msgs.admin.infoTestTemplateSend);
            }
        });

    }

    renderError(field: string) {
        if(!this.state.errors || !this.state.errors.fields) return;
        const msg=this.state.errors.fields[field+'_'+this.state.lang];
        if(!msg) return null;
        return <Form.Control.Feedback type="invalid" className="clickable"
            onClick={(e) => {
                window.alert(msg);
            }}
        >{msgs.admin.invalidTemplateHint}</Form.Control.Feedback>
    }

    render() {
        if(!this.state.loaded) return null;
        const lang= this.state.lang;
        const title=this.props.match.params.template;
        const variables=Object.entries(this.state.variables).sort((i1, i2) => i1[0].localeCompare(i2[0]));
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem to={TemplatesScreen.url}>{msgs.admin.menuSystemNotifications}</BreadcrumbItem>
                <BreadcrumbItem active>{title}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={title}/>
            <Form>
                <p className="text-info">{this.state.desc}.</p>
                <LanguagePanel id="langName" value={this.state.lang} onChange={lang => this.setState({ lang })}/>
                <div className="tab-content">
                    <Form.Group controlId="title" className="w-100">
                        <Form.Label>{msgs.gui.labelMessageTitle}</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={!this.edit}
                            placeholder={msgs.gui.labelMessageTitle}
                            value={this.state.title[lang]}
                            onChange={(e) => this.setState({ title: { ...this.state.title, [this.state.lang]: e.target.value  }})}
                            maxLength={1024}
                        />
                        {this.renderError("title")}
                    </Form.Group>
                    <Form.Group className="w-100">
                        <Form.Label>{msgs.gui.labelMessageContent}</Form.Label>
                        <RichEditor
                            key={lang}
                            ref={this.editor}
                            readonly={!this.edit}
                            mode={this.state.rawEditor?"raw":"email"}
                            className={"email-editor"}
                            value={this.state.content[lang]}
                            onChange={(v) => this.setState({ content: { ...this.state.content, [this.state.lang]: v }})}
                        />
                        {this.renderError("content")}
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button
                            size="xl"
                            onClick={(e) => this.onTemplateTest(e)}
                        >{msgs.admin.actionTemplateTest}</Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <SaveButton
                            state={this.state.saveState}
                            disabled={!this.edit}
                            onClick={(e) => this.onSave(e)}
                        >{msgs.gui.actionSave}</SaveButton>
                    </Form.Group>
                    {variables.length>0?<>
                        <p className="text-info" dangerouslySetInnerHTML={{ __html: msgs.admin.hintFreeMarkerVariable }}/>
                        <p className="text-info">{msgs.admin.labelVariables}:</p>
                        <ul className="text-info">
                            {variables.map(([k, v]) =>
                                <li
                                    key={k}
                                    className={"clickable"}
                                    onClick={() => this.editor.current.insertText("${"+k+"}")}
                                >
                                    <code>{"${"+k+"}"}</code> - {v}
                                </li>
                            )}
                        </ul>
                    </>:null}
                </div>
            </Form>
        </>;
    }
}
EditTemplateScreen.contextType=LangContext;