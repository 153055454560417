//@flow
/* eslint-disable no-use-before-define */
import React, {useMemo} from 'react';
import {RouteChildrenProps} from "react-router";
import type {BannerInfo} from "../api";
import {BannerInfoValidation} from "../api";
import type {LangInfo, Language} from "../lib/Language";
import msgs, {LangContext, Languages, LanguagesOrdered, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Col} from "react-bootstrap";
import {formatDate} from "../lib/DateTime";
import {BreadcrumbItem, DeleteButton, Dialog, dialogOpen, PageHeader} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {Form} from "../lib/Form";
import {Events, events, store} from "../application";
import type {AllDataTableColumn, AllDataTableProps} from "../lib/DataTable";
import {AllDataTable} from "../lib/DataTable";

type State = {
    timeStamp: number;
}

const BannersTable=( { onDelete, ...props}: $Diff<AllDataTableProps, { columns: any }> & { edit: boolean, onDelete: (row) => void }) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<AllDataTableColumn<BannerInfo>>>(() => [
        ...LanguagesOrdered.map((l: LangInfo) => ({
            id: l.code,
            accessor: (row) => row.name[l.code],
            Header: msgs.gui.labelTitle+' '+l.code,
            filter: 'text',
            className: "half",
        })),
        {
            accessor: 'from',
            Header: msgs.admin.labelActiveFrom,
            filter: 'date',
            className: "date",
            Cell: ({ value }) => formatDate(value),
        },
        {
            accessor: 'to',
            Header: msgs.admin.labelActiveTo,
            filter: 'date',
            className: "date",
            Cell: ({ value }) => formatDate(value),
        },
        {
            accessor: 'status',
            className: "status",
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => msgs.gui['status'+value],
            filter: "select",
            options: [
                { label: "", value: "" },
                { label: msgs.gui.statusActive, value: "Active" },
                { label: msgs.gui.statusBlocked, value: "Blocked" },
                { label: msgs.gui.statusTemporaryBlocked, value: "TemporaryBlocked" }
            ]
        },
        {
            id: 'actions',
            className: "delete",
            Header: '',
            Cell: ({ row }) => onDelete?<DeleteButton onClick={(e) => onDelete(row.original, e)}/>:null,
        }
    ], [ msgs.gui.language, onDelete ]);
    return <AllDataTable columns={columns} {...props}/>
}

export default class BannersScreen extends React.Component<RouteChildrenProps, State> {
    static url="/banners";

    edit: boolean;

    constructor(props) {
        super(props);
        this.state = {
            timeStamp: Date.now(),
        }
        this.edit=store.hasRight("BannerEdit");
    }

    refresh() {
        this.setState({ timeStamp: Date.now() });
    }

    onAdd() {
        dialogOpen(this, EditBannerScreen.link('-'), () => this.refresh());
    }

    onSelect=(i: BannerInfo) => {
        dialogOpen(this, EditBannerScreen.link(i.id), () => this.refresh());
    }

    onDelete = (i: BannerInfo, e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        store.userApi.deleteBanner(i.id).then(() => this.refresh());
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuBanners}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuBanners}>
                {this.edit?<Button onClick={(e) => this.onAdd(e)}>{msgs.admin.actionAddBaner}</Button>:null}
            </PageHeader>
            <BannersTable
                onDelete={this.edit?this.onDelete:null}
                data={store.userApi.getBanners}
                onRowClick={this.onSelect}
                dataTimestamp={this.state.timeStamp}
                historyState="table"
            />
        </>;
    }
}
BannersScreen.contextType=LangContext;

type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState = {
    lang: Language;
    value: BannerInfo|null;
}


export class EditBannerScreen extends React.Component<EditProps, EditState> {
    static url="/banner/:id";
    static link=(id: string) => "/banner/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("BannerEdit");
        this.state={
            lang: Languages.pl.code,
            value: null
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;
        store.userApi.getBanner(id).then((b: BannerInfo) => {
            this.setState({ value: b} )
        })
    }

    render() {
        if(!this.state.value) return null;
        return <Form
            lang={this.state.lang}
            readonly={!this.edit}
            initialValues={this.state.value}
            validate={BannerInfoValidation}
            onSubmit={async (values, helper) => {
                let res=await store.userApi.saveBanner(values);
                if(!Form.setError(helper, res)) {
                    this.props.history.goBack();
                    events.emit(Events.BannerChange);
                }
            }}>{(formik) => (<Dialog
            title={this.props.match.id==='-'?msgs.admin.titleAddBanner:msgs.admin.titleEditBanner}
            onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
        >
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="name" controlId="name_pl">
                        <Form.Label>{msgs.admin.labelBannerName} ({Languages.pl.code})</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.pl.code} maxLength={500}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="name" controlId="name_en">
                        <Form.Label>{msgs.admin.labelBannerName} ({Languages.en.code})</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.en.code} maxLength={500}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={4}>
                    <Form.Group name="from">
                        <Form.Label>{msgs.admin.labelActiveFrom}</Form.Label>
                        <Form.Date/>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group name="to">
                        <Form.Label>{msgs.admin.labelActiveTo}</Form.Label>
                        <Form.Date/>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group name="status">
                        <Form.Label>{msgs.gui.labelStatus}</Form.Label>
                        <Form.Control as="select">
                            <option value="">{msgs.gui.comboboxSelect}</option>
                            <option value="Active">{msgs.gui.statusActive}</option>
                            <option value="TemporaryBlocked">{msgs.gui.statusTemporaryBlocked}</option>
                            <option value="Blocked">{msgs.gui.statusBlocked}</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.RowGroup name="flags">
                <Form.FlagCheck
                    flag="OpenInNewWindow"
                >{msgs.admin.labelOpenInNewWindow}</Form.FlagCheck>
            </Form.RowGroup>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="url" controlId="url_pl" maxLength={2048}>
                        <Form.Label>{msgs.admin.labelURL} ({Languages.pl.code})</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.pl.code}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="url" controlId="url_en" maxLength={2048}>
                        <Form.Label>{msgs.admin.labelURL} ({Languages.en.code})</Form.Label>
                        <Form.MultiLanguageTextInput lang={Languages.en.code}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="plFileId">
                        <Form.FileInput imageOnly/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="enFileId">
                        <Form.FileInput imageOnly/>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Dialog>)}</Form>;
    }
}
EditBannerScreen.contextType=LangContext;