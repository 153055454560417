//@flow

/** Limit znaków dla SMSa składającego się z prosty (ASCII) znaków */
export const SMS_SIMPLE_LENGTH=160;
/** Limit znaków dla SMSa składającego się ze znaków innych niż proste (innych ASCII) */
export const SMS_UTF_LENGTH=70;

/**
 * Funkcja sprawdza, czy jest to prosty SMS.
 * @param v treść do sprawdzenia
 */
export function isSimpleSms(v: string): boolean {
    for(let i=0;i<v.length;++i) {
        // console.log("Char: ", value.charCodeAt(i), value.charCodeAt(i)>0x7f);
        if(v.charCodeAt(i)>0x7f) return false;
    }
    return true;
}

/**
 * Funkcja zwraca liczbę SMSów, które zostaną użyte do wysłania danej treści.
 * @param v treść, dla której sprawdzić liczbę wiadomości.
 */
export function countSmsMessages(v: string): number {
    if(v===null || v===undefined || v.length===0) return 0;
    if(isSimpleSms(v)) return Math.ceil(v.length/SMS_SIMPLE_LENGTH)
    return Math.ceil(v.length/SMS_UTF_LENGTH);
}