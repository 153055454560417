//@flow
import React from 'react';
import {Breadcrumb, Button, Form, Modal} from "react-bootstrap";
import type {LangInfo, LangString} from "../lib/Language";
import msgs, {LangContext, LanguagesOrdered, updateKey} from "../lib/Language";
import {RouteChildrenProps} from "react-router";
import type {TextInfo} from "../api";
import HomeScreen from "./HomeScreen";
import {store} from "../application";
import type {AllDataTableColumn, DataTableInstance} from "../lib/DataTable";
import {ClientDataTable} from "../lib/DataTable";
import {BreadcrumbItem, PageHeader, SaveButton} from "../lib/Components";
import type {SaveButtonState} from "../lib/Components";

type Props = RouteChildrenProps & {

}

type LangKey= {
    k: string;
    v: LangString;
}

type State = {
    data: Array<LangKey>|null;
    /** Aktualnie edytowany klucz */
    editor: string|null;
    /** Liczba zmienionych */
    changed: number;
    saveState: SaveButtonState;
    /** Przeciążone (zmienione) wartości */
    [string]: LangString;
}

export default class LangKeysScreen extends React.Component<Props, State> {
    static url="/lang_strings";

    columns: Array<AllDataTableColumn<TextInfo>>;
    edit: boolean;
    table: DataTableInstance;
    data: Array<LangKey>|null;

    constructor(props) {
        super(props);
        this.state={
            data: null,
            editor: null,
            changed: 0,
            saveState: null,
        }
        this.edit=store.hasRight("LangKeysWrite");
        this.columns=[ {
            Header: msgs.admin.langKeyName,
            accessor: 'k',
            filter: 'text',
            type: "string",
            className: "lg hard-break",
        }, ...LanguagesOrdered.map((l: LangInfo) => ({
            Header: l.name,
            id: l.code,
            accessor: (row: LangKey) => row.v[l.code],
            type: "string",
            filter: "text",
            className: "half",
        }))]
    }

    setInstance= (table) => {
        this.table=table;
    }

    refresh() {
        store.userApi.getLangKeys().then(data => {
            let d=[];
            Object.entries(data).forEach(([k: string, v: LangString ]) => d.push({ k, v }));
            d.sort((e1, e2) => e1.k.localeCompare(e2.k));
            this.data=d;
            this.updateData();
        })
    }

    componentDidMount(): void {
        this.refresh();
    }

    onSave(e: Event) {
        e.preventDefault();
        let update={};
        Object.entries(this.state).forEach(([k: string, v: LangString ]) => {
            if(k==='data' || k==='editor' || k==='changed' || k==="saveState" || v===null) return;
            update[k]={...v};
        });
        this.setState({ saveState: "loading" });
        store.userApi.updateLangKeys(update).then(() => {
            window.setTimeout(() => {
                let empty={
                    saveState: "done",
                    changed: 0,
                };
                Object.keys(this.state).forEach((k) => {
                    if(k==='data' || k==='editor' || k==='changed' || k==="saveState") return;
                    empty[k]=undefined;
                });
                this.setState(empty, () => this.refresh());
            }, 800);
        }).catch(() => this.setState({ saveState: "error"}));
    }

    updateData() {
        let data=this.data.map((i: LangKey) => {
            const val=this.state[i.k];
            if(val) {
                return { k: i.k, v: val, e: true };
            } else {
                return i;
            }
        })
        this.setState({ data: data});
    }

    handleRowClick(row: LangKey) {
        if(this.state[row.k]===undefined) {
            this.setState({
                [row.k]: {...row.v},
                changed: this.state.changed+1,
            })
        }
        this.setState({
            editor: row.k
        });
    }

    handleEdited() {
        const key: string=this.state.editor;
        const org: LangKey=this.state.data.find((i: LangKey) => i.k===key);
        const val: LangString=this.state[key];
        let changed=LanguagesOrdered.some((i: LangInfo) => val[i.code]!==org.v[i.code]);
        if(!changed) {
            this.setState({
                [key]: undefined,
                changed: this.state.changed-1,
                editor: null,
            });
        } else {
            this.setState({
                editor: null,
            })
        }
        this.updateData();
    }

    render() {
        const edited=this.state.editor;
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuLanguageKeys}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuLanguageKeys}/>
            <Form className="pb-4">
                <Form.Group>
                    <ClientDataTable
                        columns={this.columns}
                        data={this.state.data}
                        onRowClick={this.edit?(row) => this.handleRowClick(row):null}
                        onReady={this.setInstance}
                    />
                </Form.Group>
                <Form.Group className="text-center">
                    <SaveButton
                        disabled={!this.edit || this.state.changed===0}
                        onClick={e => this.onSave(e)}
                        state={this.state.saveState}
                    >{msgs.gui.actionSaveChanges}</SaveButton>
                </Form.Group>
            </Form>
            <Modal
                size="lg"
                show={!!edited}
                onHide={() => this.handleEdited()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{edited}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {edited?<Form>
                        <Form.Group>
                            <Form.Label>{msgs.admin.langKeyName}</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={edited}
                            />
                        </Form.Group>
                        {LanguagesOrdered.map((l: LangInfo) => (
                            <Form.Group key={l.code}>
                                <Form.Label>{l.name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state[edited][l.code]}
                                    onChange={(e) => {
                                        this.setState({ [edited]: updateKey(this.state[edited], l.code, e.target.value) })
                                    }}
                                />
                            </Form.Group>
                        ))}
                    </Form>:null}
                </Modal.Body>
            </Modal>
        </>;
    }
}
LangKeysScreen.contextType=LangContext;