//@flow
import React from 'react';
import type {Language} from "../lib/Language";
import msgs, {defaultLanguage, LangContext} from "../lib/Language";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {emptyLangString, LanguagePanel, RichEditor} from "../AdminComponents";
import type {TechnicalMessage} from "../api";
import HomeScreen from "./HomeScreen";
import {store} from "../application";
import {BreadcrumbItem, PageHeader, SaveButton} from "../lib/Components";
import type {SaveButtonState} from "../lib/Components";

type State = TechnicalMessage & {
    loaded: boolean;
    lang: Language;
    saveState: SaveButtonState;
}

export default class TechnicalMessageScreen extends React.Component<void, State> {
    static url="/technical_message";

    edit: boolean;
    constructor(props) {
        super(props);
        this.edit=store.hasRight("TechnicalMessage");
        this.state={
            lang: defaultLanguage,
            loaded: false,
            enabled: false,
            message: emptyLangString(),
            saveState: null,
        }
    }

    componentDidMount(): void {
        store.userApi.getTechnicalMessage().then((tm: TechnicalMessage) => {
            this.setState({
                loaded: true,
                ...tm
            })
        }).catch(() => this.setState({saveState: "error"}));
    }

    onSave() {
        this.setState({ saveState: "loading"});
        store.userApi.updateTechnicalMessage({
            enabled: this.state.enabled,
            message: this.state.message,
        }).then(() => {
            this.setState({ saveState: "done"});
        }).catch(() => this.setState({ saveState: "error" }));
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuTechnicalMessage}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuTechnicalMessage} custom>
                <Form.Check
                    className="form-control-xl"
                    custom
                    disabled={!this.edit}
                    type="switch"
                    id="isEnabled"
                    label={<h1>{msgs.admin.menuTechnicalMessage}</h1>}
                    checked={this.state.enabled}
                    onChange={(e: SyntheticInputEvent) => {
                        this.setState({enabled: e.target.checked});
                        store.userApi.enableTechnicalMessage(e.target.checked).finally();
                    }}
                />
            </PageHeader>
            <Form>
                <LanguagePanel id="message" value={this.state.lang} onChange={lang => this.setState({ lang })}/>
                <div className="tab-content">
                    <Form.Group>
                        <RichEditor
                            readonly={!this.edit}
                            value={this.state.message[this.state.lang]}
                            onChange={v => this.setState({ message: { ...this.state.message, [this.state.lang]: v }} )}
                        />
                    </Form.Group>
                    <Form.Group className={"text-center mt-5"}>
                        <SaveButton
                            onClick={() => this.onSave()}
                            disabled={!this.edit}
                            state={this.state.saveState}
                        >{msgs.admin.actionSaveOther}</SaveButton>
                    </Form.Group>
                </div>
            </Form>
        </>;
    }
}
TechnicalMessageScreen.contextType=LangContext;
