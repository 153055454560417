//@flow
import React, {useMemo} from 'react';
import {RouteChildrenProps, RouteComponentProps} from "react-router";
import {Alert, Badge, Breadcrumb, Button, Card, Col, Nav, Row} from "react-bootstrap";
import HomeScreen from "./HomeScreen";
import msgs, {
    formatString,
    getLangValue,
    LangContext,
    LangLink,
    langLink,
    LanguagesOrdered,
    useMsgs
} from "../lib/Language";
import {
    BreadcrumbItem,
    CombinedNIPInput,
    Dialog,
    dialogOpen,
    ErrorMessage,
    PageHeader,
    waitGlass
} from "../lib/Components";
import type {FieldFilter, OrderStatus, RejectionReasonInfo, VindicationDetails, VindicationTableInfo, VindicationViewReceivable} from "../api";
import type {DataTableColumn, DataTableProps, DataTableRowSelection} from "../lib/DataTable";
import DataTable from "../lib/DataTable";
import {Events, events, store} from "../application";
import {formatDate, formatDateTime} from "../lib/DateTime";
import {formatMoney, getLocationState, replaceHistoryState, stringJoin} from "../lib/Utils";
import {BForm} from "../lib/Form";
import {DebtsOtherTable, DebtsTable, DebtStructure, DisplayVindicationType} from "../lib/VindicationsView";
import {getDisplayLink, getDownloadLink} from "../lib/Upload";
import Validate from "../lib/Validation";
import type {LangInfo} from "../lib/Language";
import {TableQuery, TableResult} from "../api";
import {DataExportButton, exportFile} from "../lib/DataExport";
import type {ExportColumnInfo, ExportFormat} from "../lib/DataExport";
import {ViewOrganizationScreen} from "./OrganizationsScreen";
import {Countries} from "../lib/Data";


const VindicationsTable = ({ actions, customColumns, ...props}: $Diff<DataTableProps, { columns: any }> & {
    actions: (row: VindicationTableInfo) => React$Node,
    customColumns: Array<DataTableColumn<VindicationTableInfo>>;
} ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumn<VindicationTableInfo>>>(() => {
        let columns = [
            {
                accessor: 'fullId',
                className: 'md text-break',
                Header: msgs.gui.labelOrderIdType,
                filter: 'text',
                Cell: ({ row }) => <>
                    <h1>{row.original.fullId}</h1>
                    <span><DisplayVindicationType value={row.original}/></span>
                </>
            }, {
                accessor: "client",
                Header: msgs.gui.labelCustomer,
                className: "half",
                filter: 'text',
                Cell: ({ row }) => <>
                    <h1>{row.original.client}</h1>
                    <span className="d-block">{row.original.clientTax}</span>
                    {row.original.clientUser && <span>{row.original.clientFistName} {row.original.clientLastName}</span>}
                </>
            }, {
                accessor: "debtor",
                Header: msgs.gui.labelDebtor,
                className: "half",
                filter: 'text',
                Cell: ({ row }) => <>
                    <h1>{row.original.debtor}</h1>
                    <span>{row.original.tax}</span>
                </>
            }, {
                accessor: "initial",
                className: 'money',
                Header: msgs.gui.labelInitialValue,
                filter: 'range',
                Cell: ({ value, row }) => formatMoney(value, row.original.currency)
            }, {
                accessor: "actual",
                className: 'money',
                Header: msgs.gui.labelCurrentBalance,
                filter: 'range',
                Cell: ({ value, row }) => formatMoney(value, row.original.currency)
            }, {
                accessor: 'added',
                className: 'added',
                Header: msgs.gui.labelOrderDate,
                Cell: ({ value }) => formatDateTime(value),
                filter: 'date',
            }, {
                accessor: 'changed2',
                className: "datetime",
                Header: msgs.admin.labelVindicationChanged,
                Cell: ({ value }) => formatDateTime(value),
                filter: 'date',

            }
        ]
        if(Array.isArray(customColumns)) columns.push(...customColumns);
        if(actions) {
            columns.push({
                id: "actions",
                Header: msgs.gui.labelActions,
                Cell: ({row}) => actions(row.original),
                className: "actions2",
            });
        }
        return columns;

    }, [ msgs.gui.language, actions, customColumns ]);
    return <DataTable initialSortBy="-added" columns={columns} {...props}/>;
}


type State = {
    dataTimestamp: number;
    tabStatus: OrderStatus;
    statusFilter: Array<FieldFilter>;
    actions: (row: VindicationTableInfo) => React$Node;
    customColumns: Array<DataTableColumn<VindicationTableInfo>>;
    selected: DataTableRowSelection;
    selectedAmount: number;
}


export default class VindicationsScreen extends React.Component<RouteChildrenProps, State> {
    static url="/debtcollections";

    edit: boolean;
    report: boolean;
    /** Na potrzeby eksportu */
    query: TableQuery;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("VindicationsWrite");
        this.report=store.hasRight("VindicationsExports");
        const tab=getLocationState(this.props.location, 'tab') || "Ordered";
        this.state={
            dataTimestamp: Date.now(),
            tabStatus: tab,
            statusFilter: [{ field: 'status', value: tab }],
            actions: this.getActions(tab),
            customColumns: this.getCustomColumns(tab),
            selected: null,
            selectedAmount: 0,
        }
    }

    componentDidMount() {
    }

    refresh() {
        this.setState({ dataTimestamp: Date.now() })
    }

    handleAccept(e: SyntheticEvent, v: VindicationTableInfo) {
        e.stopPropagation(); e.preventDefault();
        dialogOpen(this, AcceptVindicationDialog.link(v.id), () => this.refresh());
    }

    handleReject(e: SyntheticEvent, v: VindicationTableInfo) {
        e.stopPropagation(); e.preventDefault();
        dialogOpen(this, RejectDialog.link(v.id), () => this.refresh());
    }

    handleDone(e: SyntheticEvent, v: VindicationTableInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.vindicationDone(v.id).then(() => this.refresh());
    }

    waitingActions = (row: VindicationTableInfo) => <>
        <Button
            variant="danger"
            onClick={(e) => this.handleReject(e, row)}
        >{msgs.gui.actionReject}</Button> <Button
            disabled={!row.clientEcId}
            title={!row.clientEcId?msgs.admin.errorNoEcId:null}
            variant="success"
            onClick={(e) => this.handleAccept(e, row)}
        >{msgs.gui.actionAccept}</Button>
    </>;

    activeActions = (row) => <>
        <Button
            variant="success"
            onClick={(e) => this.handleDone(e, row)}
        >{msgs.gui.actionFinish}</Button>
    </>;

    getCustomColumns(tab: OrderStatus): Array<DataTableColumn<VindicationTableInfo>>|null {
        switch(tab) {
            case "Rejected":
                return [
                    {
                        accessor: "rejectionReason",
                        Header: msgs.gui.labelRejectionReason,
                        className: "half",
                        filter: 'text',
                        Cell: ({ value }) => LanguagesOrdered.map((l: LangInfo) => <p
                            key={l.code}>{getLangValue(value, l.code)}</p>)
                    }
                ];
            default:
                return null;
        }
    }

    getActions(tab: OrderStatus) {
        if(!this.edit) return null;
        switch(tab) {
            case "Ordered":
                return this.waitingActions;
            case "InProgress":
                return null;
                // return this.activeActions;
            case "Rejected":
                return null;
            case "Done":
                return null;
            case "Grace":
                return null;
        }
    }

    shouldComponentUpdate(nextProps: $ReadOnly<Props>, nextState: $ReadOnly<State>, nextContext: any): boolean {
        if(this.state.tabStatus!==nextState.tabStatus) {
            this.setState({
                actions: this.getActions(nextState.tabStatus),
                customColumns: this.getCustomColumns(nextState.tabStatus),
            })
        }
        return true;
    }

    handleSelect=(item: VindicationTableInfo) => {
        this.props.history.push(langLink(VindicationScreen.link(item.id)));
    }

    rowClassName=(item: VindicationTableInfo) => {
        if(!item.clientEcId) return "disconnected";
        return null;
    }

    handleSelectionChange=(selected: DataTableRowSelection) => {
        this.setState({
            selected: selected,
            selectedAmount: Object.keys(selected).length
        })

    }

    handleAcceptSelected=() => {
        store.userApi.vindicationMultipleAccept(Object.keys(this.state.selected)).then(res => {
            this.refresh();
            events.emit(Events.VindicationChange);
        })
    }

    handleRejectSelected=() => {
        RejectDialog.ids=Object.keys(this.state.selected);
        dialogOpen(this, RejectDialog.link("*"), () => this.refresh());
    }

    renderSelectedActions() {
        if(this.state.tabStatus!=="Ordered" || this.state.selectedAmount===0) return null;
        return <>
            <Button variant="link" onClick={this.handleRejectSelected}>{msgs.admin.actionRejectSelected}</Button>
            <Button variant="link" onClick={this.handleAcceptSelected}>{msgs.admin.actionMultipleAcceptAsNew}</Button>
        </>;
    }

    exportResults() {
        if(!this.query) return;
        store.userApi.exportVindications(this.query).then(file => {
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        }).finally(waitGlass());
    }

    queryVindications=(query: TableQuery) => {
        this.query=query;   // zapamiętujemy zapytanie na potrzeby eksportu
        return store.userApi.queryVindications(query);
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuOrders}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuVindications}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuVindications}/>

            <Nav
                variant="tabs"
                defaultActiveKey={this.state.tabStatus}
                onSelect={(key) => {
                    this.setState({
                        tabStatus: key,
                        statusFilter: [{ field: 'status', value: key }],
                        dataTimestamp: Date.now(),
                    })
                    replaceHistoryState(this.props.history, this.props.location, 'tab', key);
                }}
            >
                <Nav.Item><Nav.Link eventKey="Ordered">{msgs.gui.statusOrdered}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="InProgress">{msgs.gui.statusInProgress}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Rejected">{msgs.gui.statusRejected}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Done">{msgs.gui.statusDone}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Grace">{msgs.gui.statusGrace}</Nav.Link></Nav.Item>
                {this.report?<div className="actions"><Button variant="info" onClick={() => this.exportResults()}>{msgs.gui.actionExportResults}</Button></div>:null}
            </Nav>
            <div className="tab-content">
                <VindicationsTable
                    customColumns={this.state.customColumns}
                    onSelectionChange={this.state.tabStatus==="Ordered"?this.handleSelectionChange:null}
                    actions={this.state.actions}
                    className="datatable-vindications"
                    data={this.queryVindications}
                    customFilters={this.state.statusFilter}
                    onRowClick={this.handleSelect}
                    rowClassName={this.rowClassName}
                    dataTimestamp={this.state.dataTimestamp}
                    historyState={"table_"+this.state.tabStatus}
                    key={this.state.tabStatus}
                />
                {this.renderSelectedActions()}
            </div>
        </>;
    }
}
VindicationsScreen.contextType=LangContext;
VindicationsScreen.mainClassName="screen-debtcollections-list";


const Property=({ label, children }) => {
    return <Row className="data">
        <Col md={3} className="label">{label}</Col>
        <Col md={9} className="value">{children}</Col>
    </Row>
}

type ViewState = {
    v: VindicationDetails;
}

export class VindicationScreen extends React.Component<RouteComponentProps<{ id: string}>, ViewState> {
    static url="/debtcollections/:id";
    static link = (id: string) => "/debtcollections/"+id;

    edit: boolean;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("VindicationsWrite");
        this.state={
            v: null
        };
    }

    refresh() {
        store.userApi.getVindication(this.props.match.params.id).then(v => {
            this.setState({v});
        })
    }

    handleAccept = () => {
        dialogOpen(this, AcceptVindicationDialog.link(this.state.v.id), () => this.refresh());
    }

    handleReject = () => {
        dialogOpen(this, RejectDialog.link(this.state.v.id), () => this.refresh());
    }

    handleDone = ()  => {
        store.userApi.vindicationDone(this.state.v.id).then(() => this.refresh());
    }

    componentDidUpdate(prevProps: $ReadOnly<RouteComponentProps<{ id: string}>>, prevState: $ReadOnly<ViewState>, snapshot: any) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.refresh();
        }
    }

    componentDidMount() {
        this.refresh();
    }

    handleExportDebtStructure = async (format: ExportFormat)=> {
        // <DebtStructure data={v.receivables} currency={v.currency}/>
        const receivables: Array<VindicationViewReceivable>=this.state.v.receivables;
        const currency: string=this.state.v.currency;
        const columns: Array<ExportColumnInfo>=[
            {
                accessor: (r: VindicationViewReceivable) => getLangValue(r.type),
                label: msgs.gui.labelType,
                width: 4,
                type: "string",
            }, {
                accessor: "number",
                label: msgs.gui.labelDocumentNr,
                width: 5,
                type: "string",
                align: 'right',
            }, {
                accessor: "initial",
                label: msgs.gui.labelInitialValue,
                width: 4,
                type: "money",
                currency: currency,
            }, {
                accessor: "sellDate",
                label: msgs.gui.labelPublished,
                width: 4,
                type: "date",
            }, {
                accessor: "paymentDate",
                label: msgs.gui.labelDueTo,
                width: 4,
                type: "date",
            }, {
                accessor: "actualCapital",
                label: msgs.gui.labelActualCapital,
                width: 4,
                type: 'money',
                currency: currency,
            }, {
                accessor: "recovered",
                label: msgs.gui.labelRecovered,
                width: 4,
                type: 'money',
                currency: currency,
            }
        ];
        await exportFile<VindicationViewReceivable>(
            this.state.v.fullId+' '+msgs.gui.titleDebtStructure,
            format,
            columns,
            receivables,
            { sheetName: msgs.gui.titleDebtStructure }
        )
    }

    render() {
        if(!this.state.v) return null;
        const v:VindicationDetails=this.state.v;
        const title=formatString(msgs.gui.titleVindication, v.fullId);

        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuOrders}</BreadcrumbItem>
                <BreadcrumbItem to={VindicationsScreen.url}>{msgs.admin.menuVindications}</BreadcrumbItem>
                <BreadcrumbItem active>{v.fullId}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={title}>
                {v.status==="Ordered"?<Button variant="danger" onClick={this.handleReject}>{msgs.gui.actionReject}</Button>:null}
                {v.status==="Ordered"?<Button
                    disabled={!v.clientEcId}
                    variant="success"
                    onClick={this.handleAccept}
                    title={!v.clientEcId?msgs.admin.errorNoEcId:null}
                >{msgs.gui.actionAccept}</Button>:null}
                {/*{v.status==="InProgress"?<Button variant="success" onClick={this.handleDone}>{msgs.gui.actionFinish}</Button>:null}*/}
            </PageHeader>
            <Row>
                <Col md={6}>
                    <Card className="properties h-100">
                        <Card.Body>
                            <Card.Title>{msgs.gui.titleDebtor}</Card.Title>
                            <Property label={msgs.gui.labelDebtor}>{v.debtor}</Property>
                            <Property label={msgs.gui.labelNIP}>
                                {v.tax}
                                {v.status!=="Ordered"?null:<Button
                                    variant="link"
                                    onClick={() => dialogOpen(this, EditNipDialog.link(v.id), () => this.refresh())}
                                >{msgs.admin.actionEditTax}</Button> }
                            </Property>
                            <Property label={msgs.gui.labelDebtorAddress}>
                                {v.address}<br/>
                                {v.postalCode} {v.city}
                            </Property>
                            <Property label={msgs.admin.labelAlreadyVindicated}>
                                {v.alreadyVindicated?msgs.gui.labelYes:msgs.gui.labelNo}
                            </Property>
                            <Property label={msgs.admin.labelOtherContact}>
                                {v.email}{v.email?<br/>:null}
                                {v.phone}{v.phone?<br/>:null}
                                {v.otherContacts}
                            </Property>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="properties h-100">
                        <Card.Body>
                            <Card.Title>
                                {msgs.admin.titleClientAndSettings}
                                {v.pricesFile?<a target="_blank" rel="noopener noreferrer" href={getDisplayLink(v.pricesFile, v.pricesFileName)}>{msgs.admin.labelPricesLink}</a>:null}
                            </Card.Title>
                            <Property label={msgs.admin.labelClient}>
                                <LangLink to={ViewOrganizationScreen.link(v.clientId)}>{v.client}</LangLink> {v.clientStatus?msgs.gui['orgStatus'+v.clientStatus]:''}
                            </Property>
                            <Property label={msgs.admin.labelClientUser}>
                                {(v.clientFistName && v.clientLastName)?
                                    v.clientFistName+' '+v.clientLastName:(v.invoiceMonitoringAutomatic?msgs.gui.imVindicationMachine:msgs.admin.labelEcImported)} {!v.worker?null:
                                <Badge variant="primary">{formatString(msgs.admin.labelWorkerUser, v.worker)}</Badge>}
                            </Property>
                            <Property label={msgs.gui.labelNIP}>{v.clientTax}</Property>
                            <Property label={msgs.admin.labelVindicationOptions}>
                                <BForm.Check readOnly inline custom type="radio" checked={v.amicable} label={msgs.admin.labelVindicationAmicable}/>
                                <BForm.Check readOnly inline custom type="radio" checked={v.judicial} label={msgs.admin.labelVindicationJudicial}/>
                                <BForm.Check readOnly inline custom type="radio" checked={v.domestic} label={msgs.admin.labelVindicationDomestic}/>
                            </Property>
                            <Property label={msgs.admin.labelChargeOptions}>
                                <BForm.Check readOnly inline custom type="radio" checked={v.chargeInterests} label={msgs.admin.labelVindicationChargeInterests}/>
                                <BForm.Check readOnly inline custom type="radio" checked={v.chargeCosts} label={msgs.admin.labelVindicationChargeCosts}/>
                            </Property>
                            <Property label={msgs.admin.labelInsurance}>
                                {v.insurer?v.insurer:<span className="text-info">{msgs.admin.insurerMissing}</span>}
                            </Property>
                            <Property label={msgs.gui.labelRegisterDate}>
                                {formatDateTime(v.added)} {v.changed2 && ('('+msgs.admin.labelVindicationChanged+' '+formatDateTime(v.changed2)+')')}
                                {v.status==="Grace"?'/ '+msgs.admin.labelVindicationGraceTimePrefix+' '+formatDateTime(v.grace):null}
                            </Property>
                            <Property label={msgs.gui.labelPrimaryCreditor}>
                                {stringJoin(", ", v.primaryCreditorName,
                                    stringJoin(" ", v.primaryCreditorAddress,
                                        v.primaryCreditorCountry && (Countries.byCode.get(v.primaryCreditorCountry)?.name || v.primaryCreditorCountry))
                                ) || "---"}
                            </Property>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <h3 className="mt-4 mb-4">{msgs.gui.titleDebtStructure}
                <div className="float-right">
                    <DataExportButton run={this.handleExportDebtStructure}/>
                </div>
            </h3>
            {(Array.isArray(v.receivables) && v.receivables.length > 0) ?
                <DebtStructure data={v.receivables} currency={v.currency} initialSum/>
                :
                !v.receivablesFile?null:
                    <Alert variant="secondary">
                        {msgs.admin.labelVindicationReceivablesFile} <a href={getDownloadLink(v.receivablesFile.dbId, v.receivablesFile.name)}>{v.receivablesFile.name}</a>
                    </Alert>
            }
            <h3 className="mt-4 mb-4">{msgs.gui.titleOtherClientDebts}</h3>
            <DebtsTable data={v.vindications}/>
            <h3 className="mt-4 mb-4">{msgs.gui.titleOtherDebts}</h3>
            <DebtsOtherTable data={v.otherVindications} ext/>
        </>
    }
}
VindicationScreen.contextType=LangContext;
VindicationScreen.mainClassName="screen-debtcollections";

type EditNipDialogState ={
    tax: string;
}

export class EditNipDialog extends React.Component<RouteComponentProps<{ id: string }>, EditNipDialogState> {
    static url="/debtcollections/:id/nip";
    static link=(vid: string) => "/debtcollections/"+vid+"/nip";

    constructor(props) {
        super(props);
        this.state = {
            tax: null,
        }
    }

    componentDidMount() {
        store.userApi.getVindication(this.props.match.params.id).then((v: VindicationDetails) => this.setState({
            tax: v.tax || ""
        }));
    }

    render() {
        if(this.state.tax===null) return null;
        return <Dialog
            title={msgs.gui.titleChangeNIP}
            acceptButton={msgs.gui.actionChange}
            onAccept={() => {
                return store.userApi.vindicationUpdateTax(this.props.match.params.id, this.state.tax)
            }}
        >
            <CombinedNIPInput
                value={this.state.tax}
                onChange={(e) => this.setState({ tax: e.target.value })}
            />
        </Dialog>;
    }
}

type RejectDialogState = {
    reasons: Array<RejectionReasonInfo>;
    reason: string;
    processing: boolean;
}

export class RejectDialog extends React.Component<RouteComponentProps<{ id: string}>, RejectDialogState> {
    static url="/debtcollections/:id/reject";
    static link=(vid: string) => "/debtcollections/"+vid+"/reject";
    static ids:Array<string>|null=null;

    constructor(props) {
        super(props);
        this.state= {
            reasons: null,
            reason: "",
            processing: false,
        }
    }

    componentDidMount() {
        store.getRejectionReasons().then(reasons => this.setState({ reasons }));
    }

    componentWillUnmount() {
        RejectDialog.ids=null;
    }

    render() {
        if(!this.state.reasons) return null;

        return <Dialog
            titleClassName="text-danger"
            title={msgs.admin.titleRejectVindication}
            acceptButton={msgs.gui.actionReject}
            onAccept={async () => {
                if(!this.state.reason) return false;
                if(this.state.processing) return false;

                this.setState({ processing: true })
                try {
                    let res;
                    if (RejectDialog.ids) {
                        res=await store.userApi.vindicationMultipleReject(RejectDialog.ids, this.state.reason, null);
                    } else res=await store.userApi.vindicationReject(this.props.match.params.id, this.state.reason, null);
                    events.emit(Events.VindicationChange);
                    return res;
                }finally {
                    this.setState({ processing: false })
                }
            }}
        >
            <BForm.Control as="select" value={this.state.reason} onChange={(e) => this.setState({ reason: e.target.value })}>
                <option value={""} className="font-italic">{msgs.gui.optionSelect}</option>
                {this.state.reasons.map((r: RejectionReasonInfo) => (
                    <option key={r.id} value={r.id}>{getLangValue(r.message)}</option>
                ))}
            </BForm.Control>
        </Dialog>;
    }
}

type AcceptVindicationDialogState = {
    others: Array<VindicationTableInfo>;
    ecId: string;
    error: string;
    processing: boolean;
}

export class AcceptVindicationDialog extends React.Component<RouteComponentProps<{ id: string}>, AcceptVindicationDialogState> {
    static url="/debtcollections/:id/accept";
    static link=(vid: string) => "/debtcollections/"+vid+"/accept";

    constructor(props) {
        super(props);
        this.state = {
            ecId: "",
            others: null,
            error: null,
            processing: false,
        }
    }
    
    componentDidMount() {
        store.userApi.getOtherForVindication(this.props.match.params.id).then(others => this.setState({ others }));
    }

    handleSelect(v: VindicationTableInfo) {
        if(this.state.processing) return;
        this.setState({ processing: true });
        const waitDone=waitGlass();
        store.userApi.vindicationAccept(this.props.match.params.id, v.id, null).then((err) => {
            if(Validate.isError(err)) {
                this.setState({ error: Validate.getError(err)})
                return;
            }
            events.emit(Events.VindicationChange);
            this.props.history.goBack();
        }).finally(() => {
            this.setState({ processing: false });
            waitDone();
        })
    }

    handleAcceptToEc() {
        if(this.state.processing) return;
        this.setState({ processing: true });
        store.userApi.vindicationAccept(this.props.match.params.id, null, this.state.ecId).then((err) => {
            if(Validate.isError(err)) {
                this.setState({ error: Validate.getError(err)})
                return;
            }
            events.emit(Events.VindicationChange);
            this.props.history.goBack();
        }).finally(() => this.setState({ processing: false }))
    }

    handleCreate() {
        if(this.state.processing) return;
        this.setState({ processing: true });
        store.userApi.vindicationAccept(this.props.match.params.id, null, null).then((err) => {
            if(Validate.isError(err)) {
                this.setState({ error: Validate.getError(err)})
                return;
            }
            events.emit(Events.VindicationChange);
            this.props.history.goBack();
        }).finally(() => this.setState({ processing: false }))
    }


    render() {
        if(this.state.others===null) return null;
        
        return <Dialog
            title={msgs.gui.actionAccept}
            cancelButton={null}
            onCancel={null}
            acceptButton={null}
            onAccept={null}
            className={this.state.processing && "c-processing"}
        >
            {this.state.error?<ErrorMessage message={this.state.error}/>:null}
            {/*<BForm.Control*/}
            {/*    type="number"*/}
            {/*    step={1}*/}
            {/*    placeholder={msgs.gui.labelECID}*/}
            {/*    value={this.state.ecId}*/}
            {/*    onChange={(e) => this.setState({ ecId: e.target.value })}*/}
            {/*    maxLength={18}*/}
            {/*/>*/}
            <div className="text-center mt-3">
                {/*<Button variant="warning" size="xl"*/}
                {/*        disabled={!this.state.ecId}*/}
                {/*        onClick={()=> this.handleAcceptToEc()}*/}
                {/*>{msgs.admin.actionAcceptToEc}</Button> */}
                <Button variant="success" size="xl" disabled={this.state.processing}
                        onClick={()=> this.handleCreate()}
                >{msgs.admin.actionAcceptNew}</Button>
            </div>
            {(this.state.others && this.state.others.length>0)?<>
                <div className="text-center mt-5">{msgs.admin.labelOrSelectFromOther}</div>
            {this.state.others.map((v: VindicationTableInfo) =><Alert
                key={v.id}
                variant="success"
                className="service-select"
                onClick={() => {
                    // obsługa zaznaczania tekstu
                    window.setTimeout(() => {
                        if(window.getSelection && window.getSelection().type==='Range') return;
                        this.handleSelect(v);
                    }, 0);
                }}
            >
                <div>
                    <h1>{v.ecId}</h1>
                    <div>
                        {formatDate(v.added)}
                    </div>
                </div>
                <span className="icon-angle-right"/>
            </Alert>)}</>:null}
        </Dialog>
    }

}
