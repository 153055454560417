//@flow
// Plik z ekranami dla scenariuszy monitoringu
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {LangInfo} from "../lib/Language";
import {currentLang, getLangValue, langLink, langSort, Languages, LanguagesOrdered, useMsgs} from "../lib/Language";
import {formatDate} from "../lib/DateTime";
import {useHistory, useParams} from "react-router-dom";
import type {
    MonitoringScenarioDetails,
    MonitoringScenarioEntry,
    MonitoringScenarioInfo,
    MonitoringStage,
    MonitoringTemplateInfo
} from "../api";
import {store} from "../application";
import {Breadcrumb, Button, Col, Table} from "react-bootstrap";
import type {SaveButtonState} from "../lib/Components";
import {BreadcrumbItem, DeleteButton, Icon, PageHeader, SaveButton} from "../lib/Components";
import HomeScreen from "./HomeScreen";
import {AllDataTable} from "../lib/DataTable";
import {BForm, Form} from "../lib/Form";
import {FieldArray, useField} from "formik";
import Validate from "../lib/Validation";

/**
 * Komponent dla ekranu z listą dostępnych scenariuszy monitoringu
 */
const MonitoringScenariosScreen = () => {
    const msgs = useMsgs();
    const columns=useMemo(() => [{
        accessor: "id",
        Header: msgs.gui.labelID,
        className: "id",
        filter: "text",
    }, ...LanguagesOrdered.map((l: LangInfo) => ({
        id: l.code,
        accessor: (row: MonitoringScenarioInfo) => row.name[l.code],
        Header: msgs.gui.labelTitle+' '+l.code,
        filter: 'text',
        className: "lang-part",
    })),{
        accessor: 'enabled',
        Header: msgs.admin.labelIsEnabled,
        className: "md",
        filter: "boolean",
        Cell: ({ value }) => value?msgs.gui.labelYes:msgs.gui.labelNo,
    // },{
    //     accessor: 'def',
    //     Header: msgs.admin.labelDefault,
    //     className: "md",
    //     filter: "boolean",
    //     Cell: ({ value }) => value?msgs.gui.labelYes:msgs.gui.labelNo,
    },{
        accessor: 'changed',
        Header: msgs.gui.labelChangeTime,
        Cell: ({ value }) => formatDate(value),
        filter: 'date',
        className: "date"
    } ], [ msgs ]);
    const history=useHistory();
    const handleClick=useCallback((row: MonitoringScenarioInfo) => {
        history.push(langLink(MonitoringScenarioScreen.link(row.id)));
    }, [ history, msgs ]);
    useEffect(() => {
        if(!store.hasRight("MonitoringEditScenario")) history.replace("/");
    }, []);

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.admin.menuMonitoringScenarios}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.admin.menuMonitoringScenarios}>
            <Button onClick={e => history.push(MonitoringScenarioScreen.link("-"))}>{msgs.gui.buttonAdd}</Button>
        </PageHeader>
        <AllDataTable
            columns={columns}
            data={store.userApi.getMonitoringScenarios}
            onRowClick={handleClick}
            historyState="scenarios-table"
        /></>
}
MonitoringScenariosScreen.url="/monitoring_scenario";
MonitoringScenariosScreen.link=() => MonitoringScenariosScreen.url;
export default MonitoringScenariosScreen;

const MonitoringStageInput = ({ fieldName }: {
    fieldName: string;
}) => {
    const msgs=useMsgs();
    return <Form.Select fieldName={fieldName}>
        <option value="" className="text-italic">{msgs.gui.optionSelect}</option>
        <option value="BeforePayment">{msgs.gui.monitoringStageBeforePayment}</option>
        <option value="SlightlyOutOfDate">{msgs.gui.monitoringStageSlightlyOutOfDate}</option>
        <option value="Expired">{msgs.gui.monitoringStageExpired}</option>
        <option value="HeavilyOverdue">{msgs.gui.monitoringStageHeavilyOverdue}</option>
    </Form.Select>;
}

function stageOrdinal(s: MonitoringStage): number {
    switch (s) {
        case "BeforePayment": return 0;
        case "SlightlyOutOfDate": return 1;
        case "Expired": return 2;
        case "HeavilyOverdue": return 3;
        default: return -1;
    }
}

const MonitoringTemplateInput = ({ templates, stage, fieldName }: {
    stage: MonitoringStage;
    templates: Array<MonitoringTemplateInfo>;
    fieldName?: string;
}) => {
    const [, meta, helpers ] = useField(fieldName);
    const msgs=useMsgs();
    useEffect(() => {
        const t:MonitoringTemplateInfo=templates.find(t => t.id===meta.value);
        if((!t || t.style!==stage)&& meta.value ) helpers.setValue(null, false);
    }, [ stage, helpers, meta, templates ]);
    return <Form.Select fieldName={fieldName} readonly={!stage}>
        <option value="" className="text-italic">{msgs.gui.optionSelect}</option>
        {templates.filter(t => t.style===stage).map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
    </Form.Select>;
}

/**
 * Komponent dla ekranu edycji wybranego scenariusza monitoringu
 */
export const MonitoringScenarioScreen = () => {
    const {id} = useParams();
    const msgs = useMsgs();
    const [scenario, setScenario] = useState<MonitoringScenarioDetails | null>(null);
    const [ smsTemplates, setSmsTemplates ] = useState<Array<MonitoringTemplateInfo>|null>(null);
    const [ emailTemplates, setEmailTemplates ] = useState<Array<MonitoringTemplateInfo>|null>(null);
    const history = useHistory();
    const [saveState, setSaveState] = useState<SaveButtonState>();
    const [lang, setLang] = useState(currentLang.code);
    const handleDelete = useCallback(() => {
        store.userApi.deleteMonitoringScenario(id).then((res) => {
            if (!res) {
                window.alert(msgs.admin.deleteErrorScenarioInUse);
                return;
            }
            history.replace(langLink(MonitoringScenariosScreen.link()));
        }).catch(err => {

        });
    }, [id]);
    useEffect(() => {
        store.userApi.getMonitoringScenario(id).then(s => setScenario(s));
        store.userApi.getNotificationsTemplates().then((t: Array<MonitoringTemplateInfo>) => {
            t.sort((v1, v2) => v1.name.toLowerCase().localeCompare(v2.name.toLowerCase()));
            setSmsTemplates(t.filter(v => v.type==="isms"));
            setEmailTemplates(t.filter(v => v.type==="iemail"));
        });
    }, [id]);
    const title = id === "-" ? msgs.gui.labelNew : (scenario?getLangValue(scenario.name):id);
    const edit = true;

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem to={MonitoringScenariosScreen.url}>{msgs.admin.menuMonitoringScenarios}</BreadcrumbItem>
            <BreadcrumbItem active>{title}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={title}>
            {id !== "-" && <Button
                variant="danger"
                onClick={handleDelete}
            >{msgs.gui.actionDelete}</Button>}
        </PageHeader>
        <div className="tab-content">
            {(scenario && smsTemplates && emailTemplates) && <Form
                initialValues={scenario}
                lang={lang}
                validate={(values) => {
                    let err={};
                    if(values.entries.length===0) err.entries="validation.emptyArray";
                    else {
                        err.entries=[];
                        let hasErrors=false;
                        let last=null;
                        for(const e of values.entries) {
                            let er = {};
                            err.entries.push(er);
                            if (last) {
                                if ((e.days <= last.days)||(e.days >= values.days)) er.days = "validation.invalidValue"
                            }
                            if (!e.sms) er.sms = "validation.required";
                            if (!e.email) er.email = "validation.required";
                            if (!e.stage) er.stage = "validation.required";
                            else if (last && last.stage && stageOrdinal(e.stage) < stageOrdinal(last.stage)) {
                                er.stage = "validation.invalidValue"
                            }
                            last=e;
                            if(Object.keys(er).length>0) hasErrors=true;
                        }
                        if(!hasErrors) delete err.entries;
                    }
                    if(!Validate.langStringNotEmpty(values.name)) err.name="validation.required";

                    // if(!Validate.langStringNotEmpty(values.desc)) err.desc="validation.required";
                    return err;
                }}
                onSubmit={async (values, helpers) => {
                    setSaveState("loading");
                    try {
                        let res = await store.userApi.updateMonitoringScenario(
                            id,
                            values
                        )
                        if (res) {
                            if(id==="-") {
                                history.replace(langLink(MonitoringScenarioScreen.link(res)))
                            }
                        }
                    }finally {
                        setSaveState("done")
                    }
                }}
            >
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="enabled">
                            <Form.Switch>{msgs.admin.labelEnabled}</Form.Switch>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        {/*<Form.Group name="def">*/}
                        {/*    <Form.Switch>{msgs.admin.labelSetAsDefault}</Form.Switch>*/}
                        {/*</Form.Group>*/}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="name" controlId="name_pl">
                            <Form.Label>{msgs.admin.labelTemplateName} ({Languages.pl.code})</Form.Label>
                            <Form.MultiLanguageTextInput lang={Languages.pl.code} maxLength={500}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="name" controlId="name_en">
                            <Form.Label>{msgs.admin.labelTemplateName} ({Languages.en.code})</Form.Label>
                            <Form.MultiLanguageTextInput lang={Languages.en.code} maxLength={500}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group name="desc" controlId="desc_pl">
                            <Form.Label>{msgs.gui.labelDescription} ({Languages.pl.code})</Form.Label>
                            <Form.MultiLanguageTextInput as="textarea" rows={3} lang={Languages.pl.code}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group name="desc" controlId="desc_en">
                            <Form.Label>{msgs.gui.labelDescription} ({Languages.en.code})</Form.Label>
                            <Form.MultiLanguageTextInput as="textarea" rows={3} lang={Languages.en.code}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={3}>
                        <Form.Group name="days">
                            <Form.Label>{msgs.admin.labelMonitoringDays}</Form.Label>
                            <Form.Number min={1} max={765}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <FieldArray name="entries" render={arrayHelpers => (<>
                    <Table>
                        <thead>
                        <tr>
                            <th className="md">{msgs.gui.labelMonitoringDay}</th>
                            <th className="stage">{msgs.gui.labelStage}</th>
                            <th className="half">{msgs.admin.labelEmailTemplate}</th>
                            <th className="half">{msgs.admin.labelSMSTemplate}</th>
                            <th className="delete">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {arrayHelpers.form.values.entries.map((e: MonitoringScenarioEntry, index) => (
                            <tr key={index}>
                                <td className="md">
                                    <Form.Number fieldName={`entries.${index}.days`} float={false} step={1}/>
                                </td>
                                <td className="stage">
                                    <MonitoringStageInput fieldName={`entries.${index}.stage`}/>
                                </td>
                                <td className="half">
                                    <MonitoringTemplateInput stage={e.stage} templates={emailTemplates} fieldName={`entries.${index}.email`}/>
                                </td>
                                <td className="half">
                                    <MonitoringTemplateInput stage={e.stage} templates={smsTemplates} fieldName={`entries.${index}.sms`}/>
                                </td>
                                <td className="md">
                                    <Button
                                        disabled={index===arrayHelpers.form.values.entries.length-1} variant="success"
                                        onClick={() => arrayHelpers.swap(index+1, index)}
                                    ><Icon icon="arrow-down"/></Button>
                                    <Button
                                        disabled={index===0} variant="success"
                                        onClick={() => arrayHelpers.swap(index-1, index)}
                                    ><Icon icon="arrow-up"/></Button>
                                    <DeleteButton className="ml-1" onClick={() => arrayHelpers.remove(index)}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Form.Error fieldName="entries"/>
                    <BForm.Group className="text-center">
                        <Button variant="success" onClick={() => arrayHelpers.push({
                            days: 0,
                            stage: "",
                            email: "",
                            sms: "",
                        })}>{msgs.gui.buttonAdd}</Button>
                    </BForm.Group>
                    </>)}/>
                <BForm.Group className="text-center">
                    <SaveButton state={saveState}/>
                </BForm.Group>
            </Form>}
        </div>
    </>;
}
MonitoringScenarioScreen.url="/monitoring_scenario/:id";
MonitoringScenarioScreen.link=(id: string) => "/monitoring_scenario/"+id;

