//@flow
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {currentLang, dialogLink, getLangValue, langLink, LangLink, langPath, useMsgs} from "../lib/Language";
import {Accordion, Badge, Button, Dropdown, DropdownButton, Form, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import HomeScreen from "./HomeScreen";
import VindicationsScreen, {AcceptVindicationDialog, EditNipDialog, RejectDialog, VindicationScreen} from "./VindicationsScreen";
import DifficultDebtsScreen, {AddDifficultDebtDialog, EditDifficultDebtDialog} from "./DifficultDebtsScreen";
import OrganizationsScreen, {
    AcceptOrganizationDialog,
    EditInvoiceMonitoringSettingsDialog,
    EditOrganizationDialog,
    ViewOrganizationScreen
} from "./OrganizationsScreen";
import UsersScreen, {EditUserDialog, SimpleUsersScreen} from "./UsersScreen";
import TextPagesScreen, {EditTextPageScreen} from "./TextPagesScreen";
import TechnicalMessageScreen from "./TechnicalMessageScreen";
import BannersScreen, {EditBannerScreen} from "./BannersScreen";
import TemplatesScreen, {EditTemplateScreen} from "./TemplatesScreen";
import LangKeysScreen from "./LangKeysScreen";
import AdminsScreen, {EditAdminDialog} from "./AdminsScreen";
import RolesScreen, {EditRoleDialog} from "./RolesScreen";
import RejectionsScreen, {EditRejectionDialog} from "./RejectionsScreen";
import ServicesScreen, {EditServiceDialog} from "./ServicesScreen";
import PacketsScreen, {PacketEditDialog} from "./PacketsScreen";
import SettingsScreen from "./SettingsScreen";
import UserSettingsDialog from "./UserSettingsDialog";
import {Events, store} from "../application";
import {ActiveSwitch, dialogOpen, ErrorDialog, Footer, Icon, RequestErrorDialog} from "../lib/Components";
import type {OrderTypeInfo, SearchResult, SearchTarget} from "../api";
import OrdersScreen from "./OrdersScreen";
import {usePopper} from "react-popper";
import {useEventState} from "../lib/Events";
import {RegisterServiceDialog, VindicationNew} from "./ClientOrder";
import DictionaryScreen, {EditDictionaryEntryDialog} from "./DictionaryScreen";
import ReportsScreen from "./ReportsScreen";
import MonitoryNotesScreen, {MonitoryNoteScreen} from "./MonitoryNoteScreen";
import MonitoringScenariosScreen, {MonitoringScenarioScreen} from "./MonitoringScenariosScreen";

/**
 * Stała z adresami stron dostępnymi po zalogowaniu, do obsługi przekierowania do panelu logowania
 */
export const loggedPages=[
    ...langPath(VindicationsScreen.url), ...langPath(VindicationScreen.url),
    ...langPath(RejectDialog.url), ...langPath(EditNipDialog.url),
    ...langPath(AcceptVindicationDialog.url),

    ...langPath(OrdersScreen.url),
    ...langPath(OrganizationsScreen.url), ...langPath(ViewOrganizationScreen.url),
    ...langPath(AcceptOrganizationDialog.url),
    ...langPath(UsersScreen.url),
    ...langPath(TextPagesScreen.url), ...langPath(EditTextPageScreen.url),
    ...langPath(TemplatesScreen.url), ...langPath(EditTemplateScreen.url),
    ...langPath(LangKeysScreen.url), ...langPath(TechnicalMessageScreen.url),
    ...langPath(BannersScreen.url),
    ...langPath(AdminsScreen.url), ...langPath(RolesScreen.url),
    ...langPath(RejectionsScreen.url), ...langPath(ServicesScreen.url),
    ...langPath(PacketsScreen.url), ...langPath(SettingsScreen.url),
    ...langPath(DifficultDebtsScreen.url), ...langPath(HomeScreen.url),
    ...langPath(SimpleUsersScreen.url),
    ...langPath(ReportsScreen.url),
]

const SearchClient=({ value }: { value: SearchResult }) => {
    if(!value || !value.clientTax) return null;
    return <div className="search-client">
        <span className="name">{value.client}</span> <span className="tax">({value.clientTax})</span>
    </div>
}

const SearchInfo = ({ type, id, children }: {
    type: string; id?: string;
}) => {
    return <div className="result-label">
        <span className="type">{type}</span>
        {id?<span className="id">{id}</span>:null}
        <span>{children}</span>
    </div>
}

const SearchLink=({ value }: { value: SearchResult }) => {
    const msgs=useMsgs();
    const history=useHistory();
    const location=useLocation();
    switch (value.target) {
        case "Orders":
            const ot=store.getOrder(value.orderType);
            return <LangLink
                className="result-order"
                to={OrdersScreen.link(ot)}
            >
                <SearchClient value={value}/>
                <SearchInfo type={getLangValue(ot.name)} id={value.id}>{value.label}</SearchInfo>
            </LangLink>;
        case "UserOrganizations":
            const href=EditUserDialog.link(value.id);
            return <a
                className="result-uo"
                href={href}
                onClick={(e) => {
                    e.stopPropagation(); e.preventDefault();
                    dialogOpen(history, location, href);
                }}
            >
                <SearchClient value={value}/>
                <SearchInfo type={msgs.admin.searchTypeUserOrganization} id={value.id}>{value.label}</SearchInfo>
            </a>;
        case "Organizations":
            return <LangLink
                className="result-organization"
                to={ViewOrganizationScreen.link(value.id)}
            >
                <SearchClient value={value}/>
                <SearchInfo type={msgs.admin.searchTypeOrganization} id={value.id}>{value.label}</SearchInfo>
            </LangLink>;
        case "Vindications":
            return <LangLink
                className="result-vindication"
                to={VindicationScreen.link(value.id)}
            >
                <SearchClient value={value}/>
                <SearchInfo type={msgs.admin.searchTypeVindication} id={value.id}>{value.label}</SearchInfo>
            </LangLink>
        default:
            return <LangLink
                className="result-unimplemented"
                to={"/unimplemented/"+value.id}
            >{value.target}</LangLink>
    }
}

const SearchViewResults = ({ value, reference, onHide }: {
    value: Array<SearchResult>;
    reference: HTMLInputElement;
    onHide: () => void;
}) => {
    const msgs=useMsgs();
    const location=useLocation();
    const [ popperElement, setPopperElement ] = useState(null);
    const { styles, attributes } = usePopper(reference, popperElement, {
        placement: "auto-start"
    });

    useEffect(() => {
        if(!onHide) return;
        window.addEventListener("click", onHide);
        return () => window.removeEventListener("click", onHide);
    }, [ onHide ]);

    const locationChange=useRef(false);
    useEffect(() => {
        if(!onHide) return;
        if(!locationChange.current) {
            locationChange.current = true;
        } else {
            onHide();
        }
    }, [location.pathname]);

    return <div className="search-results" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {value.length === 0 ? <p className="text-info">{msgs.gui.dataTableEmpty}</p> :
            value.map((r: SearchResult) => <SearchLink key={r.target+"-"+r.id} value={r}/>)}
    </div>
}

const SearchForm = () => {
    const msgs = useMsgs();
    const [queryId, setQueryId] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [mode, setMode] = useState<SearchTarget|null>(null);
    const [results, setResults] = useState<Array<SearchResult | null>>(null);
    const [hidden, setHidden] =useState(false);
    const search = useRef<number>(0)

    const input = useRef(null);
    const pending = useRef<number | null>(null);
    useEffect(() => {
        if (pending.current) {
            window.clearTimeout(pending.current);
            pending.current = null;
        }
        const id = ++search.current;
        if (query === "") {
            if (results !== null) setResults(null);
            return;
        }
        store.userApi.globalSearch(query, mode).then(res => {
            if (id !== search.current) return;
            setResults(res);
        });
    }, [queryId]);

    useEffect(() => {
        if (pending.current) {
            window.clearTimeout(pending.current);
        }
        if (query === "") {
            pending.current = null;
            if(results!==null) setResults(null);
            return;
        }
        pending.current = window.setTimeout(() => {
            pending.current = null;
            setQueryId(queryId + 1);
        }, 800);
    }, [query]);
    useEffect(() => {
        setQueryId(queryId + 1);
    }, [mode]);

    const handleOnHide=useCallback(() => {
        console.log("OnHide");
        setHidden(true);
    }, [] );

    return <Form inline className="admin-search small-hide">
        <InputGroup
            size="lg"
            onClick={(e) => {
                e.stopPropagation();
                setHidden(false);
            }}
        >
            <DropdownButton
                as={InputGroup.Prepend}
                variant="secondary"
                title={mode ===null ? msgs.admin.searchAll : msgs.admin['search' + mode]}
                id="type-select"
            >
                <Dropdown.Item
                    active={mode === null}
                    onClick={() => setMode(null)}
                >{msgs.admin.searchAll}</Dropdown.Item>
                {store.hasRight("VindicationsRead")?<Dropdown.Item
                    active={mode === "Vindications"}
                    onClick={() => setMode("Vindications")}
                >{msgs.admin.searchVindications}</Dropdown.Item>:null}
                {store.hasRight("UsersRead")? <Dropdown.Item
                    active={mode === "UserOrganizations"}
                    onClick={() => setMode("UserOrganizations")}
                >{msgs.admin.searchUserOrganizations}</Dropdown.Item>:null}
                {store.hasRight("OrganizationsRead")?<Dropdown.Item
                    active={mode === "Organizations"}
                    onClick={() => setMode("Organizations")}
                >{msgs.admin.searchOrganizations}</Dropdown.Item>:null}
                {store.hasRight("ServicesRead")? <Dropdown.Item
                    active={mode === "Orders"}
                    onClick={() => setMode("Orders")}
                >{msgs.admin.searchOrders}</Dropdown.Item>:null}
            </DropdownButton>
            <Form.Control
                ref={input}
                type="text"
                placeholder={msgs.admin.searchPlaceholder}
                value={query}
                onChange={e => setQuery(e.target.value)}
            />
            {(results===null || hidden) ? null : <SearchViewResults
                value={results} reference={input.current}
                onHide={handleOnHide}
            />}
            <InputGroup.Append>
                <Button
                    variant="secondary"
                    onClick={() => setQueryId(queryId + 1)}
                ><i className="fa fa-search"/></Button>
            </InputGroup.Append>
        </InputGroup>
    </Form>
}

/** Formularz asysty zdalnej (pomocy zdalnej) */
const SupportForm = () => {
    const [ code, setCode ] = useState<string>("");
    const [ invalidCode, setInvalidCode ] = useState<boolean>(false);
    const msgs=useMsgs();
    return <Form inline className="admin-remote-help small-hide">
        <InputGroup size="lg" >
            <Form.Control
                type="text"
                placeholder={msgs.admin.remoteHelpPlaceholder}
                value={code}
                maxLength={8}
                onChange={e => setCode(e.target.value.replace(/[^0-9]/g, '').substr(0,6))}
            />
            <InputGroup.Append>
                <Button
                    title={msgs.admin.remoteHelpButtonHint}
                    disabled={!code || code.length!==6}
                    variant="secondary"
                    onClick={() => {
                        store.userApi.getRemoteTokenLink(code).then(link => {
                            if(!link) {
                                setInvalidCode(true);
                            } else {
                                setCode("");
                                window.open(link, "_blank").focus();
                            }
                        });
                    }}
                ><Icon.Login/></Button>
            </InputGroup.Append>
        </InputGroup>
        {!invalidCode?null:<ErrorDialog
            title={msgs.admin.remoteHelpInvalidToken}
            onHide={() => setInvalidCode(false)}
        >{msgs.admin.remoteHelpInvalidToken}</ErrorDialog>}
    </Form>;
}

function getCounter(stats: {}, category: string, counter: string): number {
    if(typeof(stats)!=='object' || stats===null) return 0;
    const c=stats[category];
    if(!c) return 0;
    const v=c[counter];
    if(typeof(v)!=='number') return 0;
    return v;
}

const MenuBadge = ({ value }: { value: number }) => {
    if(typeof(value)!=='number' || value<=0) return null;
    return <Badge variant="danger">{value}</Badge>;
}

const CounterBadge = ({ value, category, counter }: { value: {}, category: string, counter: string }) => {
    const val=getCounter(value, category, counter);
    if(val<=0) return null;
    return "("+val+")";
}

const UserApp=() => {
    const [ expanded, setExpanded ] = useState(false);
    const [ menu, setMenu ] = useState(null);
    const msgs=useMsgs();
    const location = useLocation();
    const history=useHistory();
    let background = location.state ? location.state.background : null;
    const handleLogout=useCallback(() => {
        store.logout().then(() => {
            history.push(langLink("/"));
        });
    },[history]);
    const toggleMenu=useCallback(() => {
        if(expanded) document.body.classList.remove("menu-visible");
        else document.body.classList.add("menu-visible");
        setExpanded(!expanded);
    }, [expanded]);
    const handleShowUserSettings=useCallback(() => {
        history.push(dialogLink(location, UserSettingsDialog.url));
    }, [history, location]);
    let stats=useEventState(Events.Stats);
    if(typeof(stats)!=='object' || stats===null) stats={};

    // console.log("Location ", location, "Background", background);
    // console.log("Stats: ", JSON.stringify(stats));
    const totalOrders=getCounter(stats, "vindications", "Ordered") + getCounter(stats, "orders", "Ordered");
    const totalUsers=getCounter(stats, "users", "New") + getCounter(stats, "organizations", "New");
    const ecConnectionError=(stats && typeof (stats.connected)==="boolean")?!stats.connected:null;

    return (<>
        <nav id="main-navigation">
            <div className="overflow-y">
                <div className="logo">
                    <LangLink to={HomeScreen.url}>
                        <img src={`/resources/img/logo-admin-${currentLang.code}.svg`} alt="RozważnaFirma Panel Administracyjny"/>
                    </LangLink>
                </div>
                <Accordion onSelect={(active) => setMenu(active)}>
                    <LangLink to={HomeScreen.url}>
                        <span className="rf-icon rf-icon-start"/> {msgs.admin.menuDesktop}
                    </LangLink>

                    <Accordion.Toggle eventKey="1000" className={menu==="1000"?"expanded":""}>
                        <span className="rf-icon rf-icon-order"/> {msgs.admin.menuOrders} <MenuBadge value={totalOrders}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1000">
                        <div>
                            {store.hasRight("VindicationsRead")?<LangLink to={VindicationsScreen.url}>{msgs.admin.menuVindications} <CounterBadge value={stats} category="vindications" counter="Ordered"/></LangLink>:null}
                            {store.hasRight("OrdersRead")?store.getOtherOrders().map((o: OrderTypeInfo) => (
                                <LangLink key={o.id} to={OrdersScreen.link(o)}>{getLangValue(o.name)}  <CounterBadge value={stats} category={o.id} counter="Ordered"/></LangLink>
                            )):null}
                        </div>
                    </Accordion.Collapse>

                    {store.hasRight("UsersRead") || store.hasRight("OrganizationsRead") ? <>
                    <Accordion.Toggle eventKey="1001" className={menu === "1001" ? "expanded" : ""}>
                            <span className="rf-icon rf-icon-users-admin"/> {msgs.admin.menuClientsAndUsers} <MenuBadge value={totalUsers}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1001">
                        <div>
                            <LangLink to={OrganizationsScreen.url}>{msgs.admin.menuOrganizations} <CounterBadge value={stats} category="organizations" counter="New"/></LangLink>
                            <LangLink to={UsersScreen.url}>{msgs.admin.menuUsersInOrganization} <CounterBadge value={stats} category="users" counter="New"/></LangLink>
                            <LangLink to={SimpleUsersScreen.url}>{msgs.admin.menuUsers}</LangLink>
                        </div>
                    </Accordion.Collapse>
                    </>:null}

                    {store.hasRight("DifficultDebtsRead")?<LangLink to={DifficultDebtsScreen.url}>
                        <span className="rf-icon rf-icon-hard"/> {msgs.admin.menuDifficultDebts}
                    </LangLink>:null}
                    {/*Monitoring faktur */}
                    {store.hasRight("MonitoringEditScenario", "MonitoringEditNotification")? <>
                        <Accordion.Toggle eventKey="10012" className={menu === "10012" ? "expanded" : ""}>
                            <span className="rf-icon  rf-admin-icon-invoice-monitoring"/> {msgs.admin.menuInvoiceMonitoring}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="10012">
                            <div>
                                {store.hasRight("MonitoringEditNotification")?<LangLink to={MonitoryNotesScreen.url}>{msgs.admin.menuNotificationTemplates}</LangLink>:null}
                                {store.hasRight("MonitoringEditScenario")?<LangLink to={MonitoringScenariosScreen.url}>{msgs.admin.menuMonitoringScenarios}</LangLink>:null}
                            </div>
                        </Accordion.Collapse>
                    </>:null}

                    {store.hasRight("Reports")?<LangLink to={ReportsScreen.url}>
                        <span className="rf-icon rf-icon-reports"/> {msgs.admin.menuReports}
                    </LangLink>:null}

                    {store.hasRight("TechnicalMessage") || store.hasRight("BannerRead") || store.hasRight("EmailTemplatesRead")
                    || store.hasRight("LangKeysRead") || store.hasRight("PagesRead")?<>
                    <Accordion.Toggle eventKey="1002" className={menu==="1002"?"expanded":""}>
                        <span className="rf-icon rf-icon-text"/> {msgs.admin.menuContent}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1002">
                        <div>
                            {store.hasRight("PagesRead")?<LangLink to={TextPagesScreen.url}>{msgs.admin.menuTextPages}</LangLink>:null}
                            {store.hasRight("TechnicalMessage")?<LangLink to={TechnicalMessageScreen.url}>{msgs.admin.menuTechnicalMessage}</LangLink>:null}
                            {store.hasRight("BannerRead")?<LangLink to={BannersScreen.url}>{msgs.admin.menuBanners}</LangLink>:null}
                            {store.hasRight("EmailTemplatesRead")?<LangLink to={TemplatesScreen.url}>{msgs.admin.menuSystemNotifications}</LangLink>:null}
                            {store.hasRight("LangKeysRead")?<LangLink to={LangKeysScreen.url}>{msgs.admin.menuLanguageKeys}</LangLink>:null}
                        </div>
                    </Accordion.Collapse>
                    </>:null}

                    {store.hasRight("SystemUserRead", "AccessLevelRead", "RejectionReasonRead", "ServicesRead", "PacketsRead", "ConfigRead", "VindicationStageNames", "VindicationGlobalPrices", "OrganizationOrigin")?<>
                    <Accordion.Toggle eventKey="1003" className={menu==="1003"?"expanded":""}>
                        <span className="rf-icon rf-icon-settings"/> {msgs.admin.menuSettings}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1003">
                        <div>
                            {store.hasRight("SystemUserRead")?<LangLink to={AdminsScreen.url}>{msgs.admin.menuAdministrators}</LangLink>:null}
                            {store.hasRight("AccessLevelRead")?<LangLink to={RolesScreen.url}>{msgs.admin.menuRoles}</LangLink>:null}
                            {store.hasRight("RejectionReasonRead")?<LangLink to={RejectionsScreen.url}>{msgs.admin.menuRejectionReasons}</LangLink>:null}
                            {store.hasRight("VindicationGlobalPrices")?<LangLink to={DictionaryScreen.link("GlobalPrices")}>{msgs.admin.titleDictionaryGlobalPrices}</LangLink>:null}
                            {store.hasRight("OrganizationOrigin")?<LangLink to={DictionaryScreen.link("OrganizationOrigin")}>{msgs.admin.titleDictionaryOrganizationOrigin}</LangLink>:null}
                            {store.hasRight("VindicationStageNames")?<LangLink to={DictionaryScreen.link("StageNames")}>{msgs.admin.titleDictionaryStageNames}</LangLink>:null}
                            {store.hasRight("ServicesRead")?<LangLink to={ServicesScreen.url}>{msgs.admin.menuServicesTypes}</LangLink>:null}
                            {store.hasRight("PacketsRead")?<LangLink to={PacketsScreen.url}>{msgs.admin.menuPackages}</LangLink>:null}
                            {store.hasRight("ConfigRead")?<LangLink to={SettingsScreen.url}>{msgs.admin.menuConfiguration}</LangLink>:null}
                        </div>
                    </Accordion.Collapse>
                    </>:null}
                </Accordion>
            </div>
        </nav>
        <div id="main">
            <nav id="top-navigation">
                <button className={"full-hide hamburger hamburger--collapse"+(expanded?" is-active":"")}
                        type="button" aria-label="Menu" aria-controls="navigation"
                        aria-expanded={expanded?"true":"false"}
                        onClick={() => toggleMenu()}
                >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"/>
                    </span>
                </button>
                <SearchForm/>
                <SupportForm/>
                <div className="flex-grow-1"/>
                {ecConnectionError===null?null:<div className="menu-hint">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={(props) => <Tooltip id={"connection-error-hint"} {...props}>
                                {ecConnectionError?msgs.admin.rfApiNoConnection:msgs.admin.rfApiConnected}
                            </Tooltip>
                        }
                    >
                        <button className={"btn btn-link btn-icon "+(ecConnectionError?"text-danger":"text-success")}>
                            {ecConnectionError?<Icon.Danger/>:<Icon.Connected/>}
                        </button>
                    </OverlayTrigger>
                </div>}
                <div className="user">
                    {store.user.name}
                </div>
                <Button className="ml-2" variant="link" onClick={handleShowUserSettings}><span className="icon-user-circle"/> {msgs.gui.labelMyAccount}</Button>
                <Button className="ml-2" variant="link" onClick={handleLogout}><span className="icon-log-out"/> {msgs.gui.actionLogout}</Button>
            </nav>
            <main id="content">
                {/* Drugi routing dla okien modalnych. Należy używać DialogLink */}
                {background ? <Switch>
                    <Route path={langPath(UserSettingsDialog.url)} exact component={UserSettingsDialog}/>
                    <Route path={langPath(EditAdminDialog.url)} exact component={EditAdminDialog}/>
                    <Route path={langPath(PacketEditDialog.url)} exact component={PacketEditDialog}/>
                    <Route path={langPath(EditServiceDialog.url)} exact component={EditServiceDialog}/>
                    <Route path={langPath(EditRejectionDialog.url)} exact component={EditRejectionDialog}/>
                    <Route path={langPath(EditDictionaryEntryDialog.url)} exact component={EditDictionaryEntryDialog}/>
                    <Route path={langPath(EditRoleDialog.url)} exact component={EditRoleDialog}/>
                    <Route path={langPath(AddDifficultDebtDialog.url)} exact component={AddDifficultDebtDialog}/>
                    <Route path={langPath(EditDifficultDebtDialog.url)} exact component={EditDifficultDebtDialog}/>
                    <Route path={langPath(EditUserDialog.url)} component={EditUserDialog}/>
                    <Route path={langPath(EditUserDialog.sUrl)} component={EditUserDialog}/>
                    <Route path={langPath(EditOrganizationDialog.url)} exact component={EditOrganizationDialog}/>
                    <Route path={langPath(EditInvoiceMonitoringSettingsDialog.url)} exact component={EditInvoiceMonitoringSettingsDialog}/>
                    <Route path={langPath(EditBannerScreen.url)} exact component={EditBannerScreen}/>

                    <Route path={langPath(EditNipDialog.url)} exact component={EditNipDialog}/>
                    <Route path={langPath(AcceptVindicationDialog.url)} exact component={AcceptVindicationDialog}/>
                    <Route path={langPath(RejectDialog.url)} exact component={RejectDialog}/>

                    <Route path={langPath(AcceptOrganizationDialog.url)} exact component={AcceptOrganizationDialog}/>

                    <Route path={langPath(RegisterServiceDialog.url)} exact component={RegisterServiceDialog}/>
                    <Route path={langPath(VindicationNew.url)} exact component={VindicationNew}/>
                </Switch> : null}
                <ActiveSwitch location={background ? background : location} elementId="content">
                    {/* Zlecenia */ }
                    <Route path={langPath(VindicationScreen.url)} exact strict component={VindicationScreen}/>
                    <Route path={langPath(VindicationsScreen.url)} exact strict component={VindicationsScreen}/>
                    <Route path={langPath(OrdersScreen.url)} exact component={OrdersScreen}/>
                    {/* Klienci i użytkownicy */}
                    <Route path={langPath(OrganizationsScreen.url)} exact component={OrganizationsScreen}/>
                    <Route path={langPath(ViewOrganizationScreen.url)} exact component={ViewOrganizationScreen}/>
                    <Route path={langPath(UsersScreen.url)} exact component={UsersScreen}/>
                    <Route path={langPath(SimpleUsersScreen.url)} exact component={SimpleUsersScreen}/>
                    {/* Treści */}
                    <Route path={langPath(TextPagesScreen.url)} exact={true} component={TextPagesScreen}/>
                    <Route path={langPath(EditTextPageScreen.url)} exact={true} component={EditTextPageScreen}/>
                    <Route path={langPath(TemplatesScreen.url)} exact={true} component={TemplatesScreen}/>
                    <Route path={langPath(EditTemplateScreen.url)} component={EditTemplateScreen}/>
                    <Route path={langPath(LangKeysScreen.url)} exact={true} component={LangKeysScreen}/>
                    <Route path={langPath(TechnicalMessageScreen.url)} component={TechnicalMessageScreen}/>
                    <Route path={langPath(BannersScreen.url)} component={BannersScreen}/>
                    {/* Uprawnienia systemowe */}
                    <Route path={langPath(AdminsScreen.url)} exact={true} component={AdminsScreen}/>
                    <Route path={langPath(RolesScreen.url)} exact={true} component={RolesScreen}/>
                    <Route path={langPath(RejectionsScreen.url)} exact={true} component={RejectionsScreen}/>
                    <Route path={langPath(DictionaryScreen.url)} exact={true} component={DictionaryScreen}/>
                    <Route path={langPath(ServicesScreen.url)} exact={true} component={ServicesScreen}/>
                    <Route path={langPath(PacketsScreen.url)} exact={true} component={PacketsScreen}/>
                    <Route path={langPath(SettingsScreen.url)} exact component={SettingsScreen}/>

                    {/* Monitoring faktur */}
                    <Route path={langPath(MonitoryNotesScreen.url)} exact component={MonitoryNotesScreen}/>
                    <Route path={langPath(MonitoryNoteScreen.url)} exact component={MonitoryNoteScreen}/>
                    <Route path={langPath(MonitoringScenariosScreen.url)} exact component={MonitoringScenariosScreen}/>
                    <Route path={langPath(MonitoringScenarioScreen.url)} exact component={MonitoringScenarioScreen}/>

                    {/* Reszta */}
                    <Route path={langPath(DifficultDebtsScreen.url)} exact component={DifficultDebtsScreen}/>
                    <Route path={langPath(HomeScreen.url)} exact={true} component={HomeScreen}/>
                    <Route path={langPath(ReportsScreen.url)} exact={true} component={ReportsScreen}/>

                    <Route path="*" render={({location}) => {
                        console.log("Page out of app: ", location.pathname);
                        return null;
                    }}/>
                </ActiveSwitch>
                <Footer simple/>
            </main>
        </div>
        <RequestErrorDialog/>
    </>)
}

export default UserApp;